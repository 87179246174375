import React from 'react';

function TextField({ children, name, type = 'text', value, setValue, required, error, placeholder, style }) {
    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            { !style ? (
                <input id={"select-" + name} name={name} type={type} placeholder={placeholder} className="bg-gray-400 w-full py-2 px-4 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}/>  
            ) : (
                <input id={"select-" + name} name={name} type={type} placeholder={placeholder} className="bg-gray-400 text-center w-12 py-2 px-4 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}/>  
            )}

            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default TextField;