import React, { useContext, useState } from "react";

import { PatientPrograms, Button } from '../../components';
import { Header, Content } from "../../common";

import { UserContext } from "../../context/UserContext";
import { DOMAIN } from "../../constants/config";
const token = localStorage.getItem("authToken");

function ProgramList() {
    const [ download, setDownload ] = useState(false);
    const headerTitle = "Mes programmes";
    const [state] = useContext(UserContext);   
    
    const downloadPrescription = () => {
        window.open(DOMAIN + '/prescription/' + token+'/'+state.user.id)
    }

    return (
        <>
            <Header title={headerTitle}>
                { download ? (
                    <Button skin='primary-outline' className="mr-2" onPress={() => downloadPrescription()} width="md">
                        Télécharger une ordonnance pour un bilan biologique complet
                    </Button>
                ) : null}
            </Header>
            <Content>
                <PatientPrograms id={state.user.id} onListSet={(flag) => setDownload(flag)}/>
            </Content>
        </>
    );
}

export default ProgramList;