import React from "react";

function Message({ type, children }) {
    if (!children || children === "") {
        return null;
    }

    let wrapperClassName = "message px-4 py-2";
    let textClassName = "italic";

    switch (type) {
        case "success":
            wrapperClassName += " bg-green-200";
            textClassName += " text-green-600";
            break;

        case "error":
            wrapperClassName += " bg-red-200";
            textClassName += " text-red-600";
            break;
        case "notice":
            wrapperClassName += " bg-orange-200";
            textClassName += " text-orange-600";
            break;
        case "info":
            wrapperClassName += " bg-blue-200";
            textClassName += " text-blue-600";
            break;

        default:
            wrapperClassName += " bg-gray-200";
            textClassName += " text-gray-600";
            break;
    }

    return (
        <div className={wrapperClassName}>
            <p className={textClassName}>{children}</p>
        </div>
    );
}

export default Message;
