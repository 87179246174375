import React, {useState} from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Header, Content, Box } from "../../common";
import { Button, FormFields, Editor } from "../../components";
import { post } from "../../server/api";

function NoteCreate() {
    let location = useLocation();
    let history = useHistory();

    const headerTitle = "Créer une nouvelle note";

    const [content, setContent] = useState('');
    const [ title, setTitle ] = useState('');

    const submit = async () => {
        let values = {
            ...title,
            content: content
        }

        let result = await post(location.pathname, JSON.stringify(values), true);
        if(result.success){
            history.push('/notes');
        }
    }

    const fields = {
        title: {
            label: "Titre de la note",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    return (
        <>
            <Header title={headerTitle}>
                <Button onPress={() => submit()}>Sauvegarder</Button>
            </Header>
            <Content>
                <Box className={'p-4'}>
                    <FormFields 
                        fields={fields}
                        handleUpdate={(title) => setTitle(title)}
                    /> 
                    <Editor {...{content, setContent}} noteButton/>
                </Box>
            </Content>
           
        </>
    );
}

export default NoteCreate;