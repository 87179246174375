import React from 'react';

export default function formatState(value) {
    if(value === "") {
        return value;
    }

    let color = '';
    let trad = '';
    switch (value) {
        case "draft":
            color = "gray-400";
            trad = "Brouillon";
            break;
        case "incomplete":
            color = "red-600";
            trad = "Bilan manquant";
            break;
        case "processing":
            color = "blue-400";
            trad = "En cours d'analyse";
            break;
        case "complete":
            color = "green-400";
            trad = "Programme reçu";
            break;
        default:
            break;
    }

    return (
        <span className={"ml-2 py-1 px-2 rounded-full text-white text-xs font-bold bg-" + color}>{trad}</span>
    );
}