import React from "react";

function Cgu() {
    return (
        <>
            <div className="h-full flex flex-col p-4">
                <h1 className="text-4xl font-bold">CONDITIONS GÉNÉRALES D’UTILISATION ET DE VENTE</h1>

                <h2 className="text-2xl font-bold m-2">Champ d’application</h2>
                <p className="my-1">
                    Les présentes conditions sont conclues entre, d'une part, 
                    La société SC Chrono Santé dont le siège social est situé 1 rue Jacques Boutrolle 76130 Mont Saint Aignan - 479.952.764 RCS Rouen - N°SIRET 479 952 764 00013 - Code APE 8690F.
                    Ci-après dénommée Chrono Santé, 
                    Et, d'autre part, 
                    Les personnes utilisant les informations et services présents sur www.la-chrononutrition.com ou celles souhaitant effectuer un achat à titre non professionnel via le site. 
                    Ci-après dénommées « l'utilisateur » ou « le client ». 
                </p>
                <p className="my-1">
                    Les services du site www.la-chrononutrition.com sont disponibles pour tous les pays mais uniquement rédigés en langue française, et, sont réservés à un large public d’adultes non professionnels de santé. Le site est équipé d’un terminal de paiement en ligne sécurisé destiné à payer les services et ouvrage disponibles sur le site. Les visiteurs du site viennent y chercher des conseils en matière de nutrition. Ces conseils ne sauraient en aucun cas se substituer à une consultation médicale. En utilisant les services et informations disponibles sur le site, en achetant les prestations en ligne, l’utilisateur accepte les présentes conditions d’utilisations et limitations de responsabilité. 
                </p>

                <h2 className="text-2xl font-bold m-2">Attribution et datation des informations fournies</h2>
                <p className="my-1">
                    La création du compte avec l’achat d’une fiche personnelle éditée sur le site donne lieu à une édition de pdf uniquement accessible via le compte sécurisé du client.
                    Les documents et informations personnelles sont effacés au bout de 2 ans sans activités et à la condition que le crédit de fiche présent dans le compte personnel soit épuisé.
                    La date d’édition et de validation de la fiche est également précisée. 
                    Le site met tout en œuvre pour diffuser des informations exactes et mises à jour, il ne peut donc être déclaré responsable des erreurs, des inexactitudes, des imprécisions, des omissions ou des données incluses pouvant s’être glissées indépendamment de sa volonté. 
                    Le site peut contenir des liens hypertextes vers d’autres sites. La Société Chrono Santé ne dispose d’aucun moyen pour contrôler ces sites et ces sources, et ne répond pas de l’indisponibilité de tels sites et sources externes. www.la-chrononutrition.com ne s’approprie pas les contenus auxquels ces sites ou sources donnent accès et exclut toute responsabilité et garantie en ce qui concerne ces contenus. En conséquence, l’utilisation d’un lien pointant vers un autre site est faite sous la seule responsabilité de l’utilisateur. www.la-chrononutrition.com 
                </p>

                <h2 className="text-2xl font-bold m-2">Description des produits et services proposés</h2>
                <p className="my-1">
                    www.la-chrononutrition.com propose des prestations payantes par la réalisation d’un programme personnalisé la « fiche conseils » réalisée du docteur Alain Delabos. Les fiches conseils sont traitées par le docteurs Alain Delabos et corrigées par l’IRENS (institut de recherche européen sur la nutrition et la santé) pour une meilleure lisibilité. 
                    Les analyses et conseils réalisés dans le cadre de la ou des fiches conseils ne sont en aucun cas des consultations médicales mais www.la-chrononutrition.com répondra à toutes vos questions concernant la nutrition.
                    Les conseils et programmes déclinés dans le présent site n’entrent pas dans la catégorie des consultations médicales, et ne peuvent donc prétendre à un quelconque remboursement par la Sécurité Sociale. 
                </p>

                <h2 className="text-2xl font-bold m-2">Contenus de l’offre :</h2>
                <p className="my-1 font-bold">La fiche personnelle, comprend : </p>
                <ul>
                    <li>- L’analyse du morphotype, des conseils et la journée type.</li>
                    <li>- vos fiches conseils sont stockées dans un espace personnel sécurisé accessible uniquement par login et mot de passe.</li>
                </ul>
                <p className="my-1">Cette offre est accessible de tous les pays mais uniquement disponible en langue française.</p> 

                <h2 className="text-2xl font-bold m-2">Paiement et sécurité</h2>
                <p className="my-1">
                    Les prestations de consulting (fiche conseils personnalisée)  ne sont disponibles qu'après paiement par l'utilisateur, par compte paypal ou par CB via Paypal. Pour accéder aux services, l’utilisateur devra remplir le formulaire de création de compte, remplir le formulaire de demande de programme, lire et accepter les conditions générales de vente, et procéder au paiement. 
                    Les prix des produits et services sont indiqués et payables en euros, toutes taxes comprises. 
                    Les produits et prestations sont disponibles tant qu’ils sont visibles sur le site. Chrono Santé se réserve le droit de modifier ses prix à tout moment. Les prestations et produits sont facturés au prix en vigueur au jour de l’enregistrement de la commande. 
                    L’achat est effectué dès le moment ou l’utilisateur reçoit le courrier électronique de confirmation du paiement. 
                </p>

                <h2 className="text-2xl font-bold m-2">Droit de rétractation</h2>
                <p className="my-1">
                    Conformément à la législation en vigueur, l’utilisateur dispose d’un délai de 14 jours francs pour exercer son droit de rétractation. Mais en ce qui concerne les prestations (fiches conseils personnalisées), le droit de rétractation ne serait applicable que dans le cas ou l’utilisateur aurait expressément mentionné que les prestations lui soient fournies à l’expiration du délai de 14 jours. 
                    Dans ce cas, le client devra adresser sa demande à Chrono Santé contact@la-chrono-nutrition.com. 
                </p>

                <h2 className="text-2xl font-bold m-2">Exécution des prestations</h2>
                <p className="my-1">
                    Pour les prestations de consulting, à compter du paiement, l’utilisateur reçoit login et mot de passe personnel, lui permettant d’accéder aux services.
                </p>

                <h2 className="text-2xl font-bold m-2">Langue française</h2>
                <p className="my-1">
                    Les prestations de consulting proposées sur le site sont disponibles pour tous les pays mais uniquement en langue française. En conséquence, Chrono Santé ne pourra être tenue pour responsable en cas de mauvaise compréhension des conseils proposés par un utilisateur qui ne maîtriserait pas la langue française.
                </p>


                <h2 className="text-2xl font-bold m-2">Limitation de responsabilité</h2>
                <p className="my-1">
                    Les informations sont fournies sur le site à titre purement indicatif et doivent être utilisées sous l’entière responsabilité du visiteur. Malgré le soin apporté au recensement des ressources contenues sur ce site, celles-ci ne sont ni complètes ni exhaustives et peuvent éventuellement contenir des erreurs, des inexactitudes, des imprécisions, des omissions ou des données incluses indépendamment de notre volonté. 
                    Il appartient donc à l’utilisateur de procéder à toutes les vérifications nécessaires pour s’assurer de la validité des informations recueillies. En aucun cas ces informations ne peuvent se substituer à un avis médical. 
                </p>

                <h2 className="text-2xl font-bold m-2">Traitement des données personnelles</h2>
                <p className="my-1">
                    Le site www.la-chrononutrition.com respecte la vie privée de ses utilisateurs. La collecte des données personnelles via le site et l’utilisation qui est faite de ces données est conforme aux dispositions de la loi française n° 78-17 du 6 janvier 1978 « informatique et libertés ». La société Chrono Santé a déclaré la collecte et le traitement des données personnelles de ses utilisateurs auprès de la Commission Nationale de l’Informatique et des Libertés. 
                    A plusieurs endroits du site, des données à caractère personnel sont rassemblées par le biais de formulaires électroniques que l’utilisateur est invité à compléter lorsqu’il demande des informations ou lorsqu’il utilise un service proposé via le site internet. A chaque endroit du site où un questionnaire est susceptible de recueillir une information personnelle de l'internaute, le site s'engage à l'informer clairement sur l'utilisation de cette donnée, sur l’identité du responsable du traitement, sur les conséquences à son égard du défaut de réponse et sur les droits d’accès et de rectification dont il dispose. 
                    Toutes les informations fournies par les visiteurs et collectées par le site sont confidentielles et indispensables pour assurer toutes les fonctions de conseils et d’expertise du site. 
                    Au moment d’informer les champs des ses coordonnées, l’internaute donne son accord quant à l’utilisation de ses données personnelles recueillies au titre du fichier de www.la-chrononutrition.com, son refus rend impossible l’accès à certaines fonctionnalités comme les analyses, les conseils et donc de l’édition de la fiche conseils.
                    L’ensemble des données transmises par l’utilisateur ne sont jamais confiées à des tiers. Elles ne sont traitées que par le Dct. Alain Delabos dans le cadre de l’élaboration de statistiques globales et l’Institut de Recherche européen en Nutrition et Santé (IRENS). Les données collectées le sont également dans un but de recherches et de statistiques scientifiques. L'internaute qui aura communiqué son adresse e-mail et/ou ses coordonnées pourra à tout moment se faire radier de tous fichiers et de tous traitements auxquels ces informations ont donné lieu. L'internaute souhaitant exercer son droit de suppression de toute ou partie des informations le concernant peut le faire à tout moment par courrier/mail, à l’adresse suivante contact@irens.fr. L’utilisateur est informé que lors de ses visites sur le site, aucun cookie ne sera installé automatiquement sur son logiciel de navigation. 
                    * Extrait du site : 
                    « Votre/vos fiches personnelles comprenant toutes les informations de santé vous concernant (actuelles et archivées) sont uniquement consultable via le site avec vos codes d’accès sécurisé. Les expéditions transitant sur ce site étant traitées par une plate forme de logistique, pour une totale sécurité des informations vous concernant, nous ne pouvons vous faire parvenir votre dossier personnel en version « papier ». Il vous appartient donc, si vous le souhaitez, de l’imprimer via l’espace personnel qui vous est réservé dans le site . 
                </p>

                <h2 className="text-2xl font-bold m-2">Comité éditorial</h2>
                <p className="my-1">
                    Les textes du site www.la-chrononutrition.com ont été rédigés par les docteurs Alain Delabos et Maya Bellahsene (IRENS). 
                </p>

                <h2 className="text-2xl font-bold m-2">Droits d’auteur</h2>
                <p className="my-1">
                    Tous les textes, ouvrages, commentaires, reproduits sur ce site sont protégés par des droits de propriété intellectuelle détenus par leurs propriétaires. Par conséquent, aucune de ces informations ne peut être reproduite, modifiée, transmise, rediffusée, traduite, vendue, exploitée commercialement ou réutilisée de quelque manière que ce soit sans l’accord préalable exprès écrit de ceux-ci. 
                </p>
            </div>
        </>
    );
}

export default Cgu;