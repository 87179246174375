import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Button, Form } from "../";
import { LoadingArea, Box } from "../../common";

import { Popup, PaypalButton } from "../../components";

import formatDate from "../../utilities/date";
import getUnitPrice from "../../utilities/product-price";
import formatPrice from "../../utilities/price";
import formatState from "../../utilities/state-badge";

import { DOMAIN } from "../../constants/config";

import {
    Timeline,
    FormMeasure,
    FormFoodProfile,
    FormActivities,
    FormLifeOther,
    FormWomen,
    FormHealthProblems,
    FormMedicalOther,
    FormAllergies,
    FormMedicalInfos,
    FormPicture,
    FormEvaluation,
} from "./";

import { Row, Header, Content, Column } from "../../common";
import { get, post } from "../../server/api";

import { UserContext } from "../../context/UserContext";

function MonitoringSheetForm({ saveUrl, submitUrl, loadUrl, patientId }) {
    const [step, setStep] = useState("morphotype");
    const [index, setIndex] = useState(0);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState(null);
    const [first, setFirst] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [paypalVisible, setPaypalVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState("Soumission de la fiche");
    const [status, setStatus] = useState('');
    const [mState, setMState] = useState('');
    const [seeMorphotype, setSeeMorphotype] = useState(false);

    const [state, setState] = useContext(UserContext);
    const token = localStorage.getItem("authToken");

    const history = useHistory();

    const [data, setData] = useState({
        chestSize: 0,
        handleWidth: 0,
        height: 0,
        hipCircumference: 0,
        waistSize: 0,
        weight: 0,
        allergies: [],
        consumptions: [],
        questions: [],
        women: [],
        drugs: "",
        diseases: [],
        job: "",
        physicalActivity: "",
        foodProfile: [],
        familySituation: "single",
        observations: "",
        childrenNumber: 1,
    });

    const [documents, setDocuments] = useState({
        photo: null,
        evaluation: null,
        prescriptions: [],
    });

    let popupRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            const result = await get(loadUrl);
            if (result.success) {
                setData(result.data.data.data);
                setStatus(result.data.status);
                setMState(result.data.state);
                setDocuments(result.data.documents);
                setPatient(result.data.patient);
                setLastUpdate(result.data.updated_at);
                setFirst(result.data.first);
            } else {
                console.log(result);
            }

            setInitialLoading(false);
        };
        fetchData();

    }, [loadUrl]);

    const updateData = (updatedData) => {
        const newData = { ...data, ...updatedData };
        setData(newData);
    };

    const updateDocuments = (updatedData) => {
        const newData = { ...documents, ...updatedData };

        setDocuments(newData);
    };

    const steps = ["morphotype", "life", "medical"];

    const previous = () => {
        if (index) {
            setIndex(index - 1);
            setStep(steps[index - 1]);
        }
    };

    const next = () => {
        if (index < 2) {
            setIndex(index + 1);
            setStep(steps[index + 1]);
        }
    };

    const saveDraft = async (e) => {
        setLoading(true);
        const result = await post(saveUrl, JSON.stringify(data), true);
        if (result && result.success) {
            setLastUpdate(result.data.updated_at);
        }
        setLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        saveDraft();
    };

    const sendMonitoringSheet = async () => {
        setLoading(loading);
        popupRef.current.toggle(false);

        const result = await post(submitUrl, JSON.stringify(data), true);
        if (result && result.success) {
            setStatus(result.data.status);
            setMState(result.data.state);
            setLastUpdate(result.data.updated_at);

            if(result.data.status === 'submitted' || result.data.status === 'pending'){
                let newUserState = {
                    ...state.user,
                    credits: result.credits
                }
    
                let newState = {
                    ...state,
                    user: newUserState
                }
    
                setState(newState);

                setPopupTitle("Votre fiche nous a été transmise");
                popupRef.current.toggle(true);
            }
        }
        setLoading(false);
    };

    const renderPopupContent = () => {

        if (status && status !== 'complete' && status !== 'incomplete' && status !== 'draft') {
            return (
                <div>
                    <span className="block text-center">Fiche en cours d'étude</span>
                </div>
            );
        } else if (state.user.credits <= 0) {
            if (paypalVisible) {
                const reference_id = state.user.id + "-1-" + Date.now();

                return (
                    <PaypalButton
                        cart={{
                            qty: 1,
                            unitPrice: getUnitPrice(state.user),
                            totalPrice: getUnitPrice(state.user),
                            name: "Crédit Chronosanté",
                            reference: "credit_unit",
                            reference_id: reference_id,
                            creditTotal: 1,
                        }}
                        //handleSuccess={() => sendMonitoringSheet()}
                    />
                );
            }
            return (
                <div>
                    <span className="block text-center">Solde insuffisant</span>
                    <p>
                        L'envoi de votre fiche de suivi au docteur nécessite
                        l'achat d'une unité
                    </p>

                    <Button onPress={() => setPaypalVisible(true)}>
                        Acheter une unité pour{" "}
                        {formatPrice(getUnitPrice(state.user))}{" "}
                    </Button>
                </div>
            );
        } else if (status === 'draft') {
            return (
                <div>
                    {!documents.evaluation && first ? (
                        <p>
                            Vous n'avez pas joint de bilan de santé au
                            formulaire, il sera nécessaire de compléter votre
                            fiche ultérieurement avec votre bilan de santé.
                        </p>
                    ) : null}

                    <p>
                        Vous êtes sur le point d'envoyer votre fiche de suivi à
                        notre équipe.
                    </p>

                    <p>
                        En cliquant sur "Je confirme l'envoi", une unité sera
                        retiré de votre solde.
                    </p>

                    <Button onPress={() => sendMonitoringSheet()}>
                        Je confirme l'envoi
                    </Button>
                </div>
            );
        } else {
            return (
                <div>
                    {(!documents.evaluation && first) || (status === 'incomplete' && !documents.evaluation)? (
                        <p>
                            Nous allons vous mettre à disposition une ordonnance
                            sous de brefs délais pour que vous puissiez faire un
                            bilan de santé auprès d'un médecin
                        </p>
                    ) : (
                        <div>
                            <p>
                                Félicitations ! Votre demande de programme est complète,
                                êtes-vous sur de vouloir envoyer ces informations ?
                            </p>
                            <Button onPress={() => sendMonitoringSheet()}>
                                Je confirme l'envoi
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
    };

    const openPopup = (e) => {
        e.preventDefault();
        e.stopPropagation();

        popupRef.current.toggle(true);
    };

    if (initialLoading) {
        return <LoadingArea isLoading={true}>Chargement ...</LoadingArea>;
    }

    const see = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSeeMorphotype(true)
    };

    let morphotype = '';

    if(patient && patient.hasOwnProperty('id') && seeMorphotype){
        morphotype = DOMAIN + '/morphotype/'+token+'/'+patient.id+'?chest_size='+data.chestSize+'&handle_width='+data.handleWidth+'&height='+data.height+'&hip_circumference='+data.hipCircumference+'&waist_size='+data.waistSize+'&weight='+data.weight;
    }

    return (
        <>
            <Popup
                ref={popupRef}
                title={popupTitle}
                closeButton="Fermer"
                className="flex flex-col items-center"
            >
                {renderPopupContent()}
            </Popup>

            <LoadingArea isLoading={loading}>
                <Form {...{ handleSubmit }}>
                    <Header
                        title=""
                        renderButtons={() => (
                            <div className="flex flex-col p-4">
                                {patient ? (
                                    <div>
                                        <strong>
                                            {patient.gender === 'femme' || patient.gender === 'fille' ? 'Mme' : 'M'} {patient.firstname} {patient.lastname} 
                                        </strong>
                                        { mState ? formatState(mState) : null }
                                    </div>
                                   
                                ) : null}
                                <em className="text-sm">
                                    Dernière édition le :{" "} 
                                    {formatDate(lastUpdate, true)}
                                </em>
                            </div>
                        )}
                    >
                        <div className="flex items-center">
                            { state.role === 'Coach'  || state.role === 'Manager' ? (
                                <>
                                    <Button
                                        className="ml-2"
                                        width="32"
                                        skin="back"
                                        onPress={() => history.goBack()}
                                    >
                                        Retour
                                    </Button>
                                </>
                            ): null}
                            { !status || status === 'draft' || status === 'incomplete' ? (
                                <>
                                    <Button
                                        className="ml-2"
                                        value="save"
                                        width="32"
                                        type="submit"
                                        skin='primary-outline'
                                        onPress={(e) => saveDraft(e)}
                                    >
                                    Sauvegarder
                                    </Button>
                                    { state.role === 'Coach'  || state.role === 'Manager' || state.role === 'Patient' ? (
                                        <Button
                                            className="ml-2"
                                            value="submit"
                                            width="32"
                                            type="submit"
                                            onPress={(e) => openPopup(e)}
                                        >
                                            Envoyer
                                        </Button>
                                    ) : null}
                                </>
                            ): null}
                          
                            
                        </div>
                    </Header>
                    <Content>
                        <Timeline {...{ step, setStep, index, setIndex, next, previous }} />
                        {step === "morphotype" && (
                            <>
                                <Row>
                                    <FormMeasure
                                        data={data}
                                        setData={(data) => updateData(data)}
                                    >
                                        <div  className="flex justify-end px-8 pb-8 pt-2">
                                            <Button
                                                onPress={(e) => see(e)}
                                            >
                                                Générer mon morphotype
                                            </Button>
                                        </div>
                                    </FormMeasure>
                                    <div>
                                        <FormPicture
                                            setDocuments={(data) =>
                                                updateDocuments(data)
                                            }
                                            {...{status, patientId, documents}}
                                        />
                                        { seeMorphotype ? (
                                            <div className="w-full p-3">
                                                <Box className='flex justify-center'>
                                                    <img src={morphotype} alt='morphotype'/>
                                                </Box>
                                            </div>
                                        ) : null}
                                    </div>
                                </Row>
                                
                            </>
                        )}
                        {step === "life" && (
                            <Row>
                                <Column>
                                    <FormActivities
                                        data={data}
                                        setData={(data) => updateData(data)}
                                    />
                                </Column>
                                <Column>
                                    <FormFoodProfile
                                        data={data}
                                        setData={(data) => updateData(data)}
                                    />
                                </Column>
                                <Column>
                                    <FormLifeOther
                                        data={data}
                                        setData={(data) => updateData(data)}
                                    />
                                </Column>
                            </Row>
                        )}
                        {step === "medical" && (
                            <>
                                <Row>
                                    <FormEvaluation
                                        {...{mState, status, patientId, documents}}
                                        setDocuments={(data) =>
                                            updateDocuments(data)
                                        }
                                    />
                                </Row>
                                <Row>
                                    <Column>
                                        <FormWomen
                                            data={data}
                                            setData={(data) => updateData(data)}
                                        />
                                        <FormAllergies
                                            data={data}
                                            setData={(data) => updateData(data)}
                                        />
                                        <FormMedicalInfos
                                            {...{data, patientId, documents, status}}
                                            setData={(data) => updateData(data)}
                                            setDocuments={(data) =>
                                                updateDocuments(data)
                                            }
                                        />
                                    </Column>
                                    <Column>
                                        <FormHealthProblems
                                            data={data}
                                            setData={(data) => updateData(data)}
                                        />
                                        <FormMedicalOther
                                            data={data}
                                            setData={(data) => updateData(data)}
                                        />
                                    </Column>
                                </Row>
                            </>
                        )}
                    </Content>
                </Form>
            </LoadingArea>
        </>
    );
}

export default MonitoringSheetForm;
