import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { Box } from "../../common";
import { Button } from "../../components";

import { UserContext } from "../../context/UserContext";

function Credits() {
    const history = useHistory();

    const [state] = useContext(UserContext);

    return (
        <Box className="w-full">
            <div className="p-6 text-center flex flex-row items-center justify-center">
                <div className="mr-auto">
                    <strong className="text-3xl inline-block mr-6">{state.user.credits}</strong>
                    <i className="icon-wallet text-6xl"></i>
                </div>
                <Button onPress={() => history.push('/checkout')} skin="primary-outline">Recharger mes unités</Button>
            </div>
        </Box>
    );
}

export default Credits;
