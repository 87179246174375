import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom"

import { ProgramData, Button, Editor, FormFields, Popup, Messages } from '../../components';
import { Header, Content, Box, LoadingArea } from '../../common';
import {morphotypes} from '../../constants/morphotypes';
import formatDate from "../../utilities/date";
import { DOMAIN } from "../../constants/config";
import getFieldLabel from "../../utilities/field-label";

import { PatientContext } from '../../context/PatientContext';
import { UserContext } from '../../context/UserContext';

import { get, post } from "../../server/api";

function MonitoringSheetForm() {
    let location = useLocation();
    let history = useHistory();

    const prescriptionRef = useRef();
    const prescriptionConfirmRef = useRef();
    const prescriptionErrorRef = useRef();
    const confirmeRef = useRef();

    const [ patientState ] = useContext(PatientContext);
    const [ state ] = useContext(UserContext);

    const [content, setContent] = useState('');
    const [program, setProgram] = useState(null);
    const [lastUpdate, setLastUpdate] = useState('');
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    let patientId = location.pathname.split('/')[3]

    useEffect(() => {
        const fetchData = async () => {
            const result = await get('/monitoring-sheet/'+patientId);
            if (result.success) {
                setProgram(result.data);
                setLastUpdate(result.data.updated_at);
                setContent(result.data.data.content);
            } else {
                console.log(result);
            }

            setInitialLoading(false);
        };

        fetchData();
    }, [patientId])

    const save = async() => {
        setLoading(true);

        let values = {
            content
        }
        
        const result = await post('/monitoring-sheet/'+patientId, JSON.stringify(values), true);
        if (result.success) {
            setLastUpdate(result.data.updated_at);
        } else {
            console.log(result);
        }
        setLoading(false);
    }

    const submit = async (pass = false, validate = false) => {
        if(content || pass){
            setLoading(true);
            let values = {
                content
            }

            const result = await post(!validate ? '/monitoring-sheet/submit/'+patientId : '/monitoring-sheet/submit/'+patientId + '?validate=1', JSON.stringify(values), true);
            if (result.success) {
                setLoading(false);
                history.push('/monitoring-sheets');
            } else {
                console.log(result);
            }
        }
      
    }

    if (initialLoading) {
        return <LoadingArea isLoading={true}>Chargement ...</LoadingArea>;
    }

    const passToCorrect = async () => {
        setLoading(true);

        const result = await get("/program/" + patientState.id +"/draft/"+program.id);
        if (result.success) {
            history.push("/patient/"+ patientState.id +"/monitoring-sheet");
        }

        setLoading(false);
    }

    const renderButton = ( ) => {

        if(program && program.hasOwnProperty('status')){
            let status = program.status;
            switch (status) {
                case 'submitted':
                    return (
                        <Button onPress={() => submit(true)}>Valider les informations du patient</Button>
                    )
                    break;
                case 'processing':
                case 'pending':
                    return (
                        <>
                            {/*!program.prescription ? (
                                <Button className="mr-2" onPress={() => prescriptionRef.current.toggle(true)}>Prescrire une ordonnance</Button>
                            ) : null*/}
                            <Button skin='primary-outline' className="mr-2" onPress={() => prescriptionRef.current.toggle(true)}>Prescrire une ordonnance</Button>
                            <Button skin='primary-outline' className="mr-2" onPress={() => save()}>Sauvegarder</Button>
                            <Button onPress={() => confirmeRef.current.toggle(true)} className="mr-2">Envoyer sans validation</Button>
                            <Button onPress={() => submit()}>Envoyer en validation</Button>
                        </>
                    )
                    break;
              
                case 'incomplete':
                        return ( <Button skin='back' className="mr-2" onPress={() => history.push('/monitoring-sheets')}>Retour</Button>)
                    break;

                case 'draft':
                    return null
                break;
                default:
                    return (
                        <>
                            <Button skin='primary-outline' className="mr-2" onPress={() => save()}>Appliquer les corrections</Button>
                            <Button skin='primary-outline' className="mr-2" onPress={() => passToCorrect()}>Corriger les données du formulaire</Button>
                            <Button onPress={() => submit()}>Valider / Envoyer</Button>
                        </>
                    )
                    break;
            }
        }
    }

    const setPrescription = async (type) => {
        const result = await post("/prescription/" + patientId, JSON.stringify({type}), true);
        prescriptionRef.current.toggle(false)
        if(result.success){
            prescriptionConfirmRef.current.toggle(true)
        } else {prescriptionRef.current.toggle(false)
            prescriptionErrorRef.current.toggle(false)
        }
    }

    const setCustomPrescription = () => {
        prescriptionRef.current.toggle(false)
        history.push('/prescription/'+patientId);
    }

    const token = localStorage.getItem("authToken");

    let photoUrl = '';
    if(program && program.hasOwnProperty('documents')){
        photoUrl = program.documents.photo
        ? DOMAIN + "/document/" + token + "/" + program.documents.photo.id
        : null;
    }

    let birthdate = new Date(patientState.birthdate).toLocaleDateString('fr-FR');
    
    return (
        <>
            <Popup ref={prescriptionConfirmRef} title="Ordonnance envoyé" closeButton="Fermer" className="flex flex-col">
                <span>Ordonnance Envoyé avec succès !</span>
            </Popup>
            <Popup ref={prescriptionErrorRef} title="Erreur" closeButton="Fermer" className="flex flex-col">
                <span>Une erreur c'est produite lors de l'envoi de l'ordonnance !</span>
            </Popup>
            <Popup ref={prescriptionRef} title="Choisissez une ordonnance" closeButton="Fermer" className="flex flex-col">
                <Button className="mb-2" onPress={() => setPrescription('base')}>Ordonnance bilan biologique</Button>
                <Button className="mb-2" onPress={() => setPrescription('lipid_abnormality')}>Ordonnance anomalie lipidique</Button>
                <Button className="mb-2" onPress={() => setPrescription('blood_sugar')}>Ordonnance glycémie</Button>
                <Button onPress={() => setCustomPrescription()}>Ordonnance pérsonnalisée</Button>
            </Popup>
            <Popup ref={confirmeRef} title="Envoyer sans validation" closeButton="Annuler" className="flex flex-col">
                <span>Êtes vous sur de vouloir envoyer ce programme sans passer par l'étape de validation ?</span>
                <Button className="mb-2" onPress={() => submit(false, true)}>Confirmer</Button>
            </Popup>
            <Header 
                title=""
                renderButtons={() => (
                    <div className="flex flex-col p-4">
                        <strong>
                        {patientState.gender === 'femme' || patientState.gender === 'fille' ? 'Mme' : 'M'} {patientState.firstname} {patientState.lastname} ( {birthdate} )
                        </strong>
                        <em className="text-sm">
                            Dernière édition le :{" "}
                            {formatDate(lastUpdate, true)}
                        </em>
                    </div>
            )}
            >
                {renderButton()}
            </Header>
            <LoadingArea isLoading={loading}>
                <Content>
                    <Box className={'p-4 mb-4'}>
                        <ProgramData {...{program, userId: state.user.id}} PrescriptionButton={() =>  state.role === "Nutritionniste" ? <Button skin='primary-outline' className="mr-2" onPress={() => prescriptionRef.current.toggle(true)}>Prescrire une ordonnance</Button> : null}/>
                    </Box>
                    {program && program.hasOwnProperty('status') && program.status !== 'submitted' && program.status !== 'incomplete' && (
                        <>
                            {photoUrl ? ( 
                                <Box className={'p-4 mb-4 flex justify-center'}>
                                    <img src={photoUrl} alt="patient" width="500px"/>
                                </Box>
                            ) : null}
                            
                            {(state.role === "Nutritionniste" || state.role === "Administrateur" ) && !patientState.coach ? 
                                program.data.data.observations && program.data.data.observations !== "" ? (
                                    <Box className={'p-4 mb-4 flex flex-col'}>
                                        <div className="flex pt-1 pb-1 mb-1 justify-between">
                                            <strong>{getFieldLabel("observations")} </strong>{" "}
                                        </div>
                                        <span>
                                            {program.data.data.observations}
                                        </span>
                                    </Box>
                                ) : null
                            : null}
                            {(state.role === "Nutritionniste" || state.role === "Coach" || state.role === "Manager" || state.role === "Administrateur" ) && patientState.coach ? (
                                <Box className={'p-4 mb-4 flex justify-center'}>
                                    <Messages patient={patientState}/>
                                </Box>
                            ) : null}
                            <Box className={'p-4'}>
                                <Editor {...{content, setContent, patientId}} gender={patientState.gender} noteButton sheetButton/>
                            </Box>
                        </>
                    )}
                </Content>
            </LoadingArea>
        </>
    );
}

export default MonitoringSheetForm;