import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { PatientPrograms, DashboardActivities, Button, Messages, Notes } from "../../components";
import { PatientContext } from "../../context/PatientContext";
import { UserContext } from "../../context/UserContext";
import { Header, Content } from "../../common";
import { get } from "../../server/api";
import { DOMAIN } from "../../constants/config";

function View() {
    let { id } = useParams();
    const [ patientState, setPatientState ] = useContext(PatientContext);
    const [ state ] = useContext(UserContext);
    const [download, setDownload] = useState(false);
    const token = localStorage.getItem("authToken");

    const downloadPrescription = () => {
        window.open(DOMAIN + '/prescription/' + token + "/" + patientState.id)
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await get('/user/'+id);

            if (result.success) {
                setPatientState(result.data);
            }
        };

        if(!patientState || patientState.id !== id){
            fetchData();
        }
        
    }, [id]);

    let history = useHistory();

    let headerTitle = ''

    if( patientState ){
        const civility = patientState.gender === 'femme' || patientState.gender === 'fille' ? 'Mme' : 'M';

        headerTitle = "Patient ( "+civility+" "+ patientState.firstname + " " + patientState.lastname +" )";
        if(state.role === 'Coach' || state.role === 'Manager'){
            headerTitle = "Client ( "+civility+" "+ patientState.firstname + " " + patientState.lastname +" )";
        }

        return (
            <>
                <Header title={headerTitle} >
                    {state.role === "Coach" || state.role === "Manager" || state.role === "Administrateur" ? (
                        <>
                            {state.role === "Coach" || state.role === "Manager" ? (
                                <>
                                    { download ? (
                                        <Button skin='primary-outline' className="mr-2" onPress={() => downloadPrescription()} width="md">
                                            Télécharger une ordonnance pour un bilan biologique complet
                                        </Button>
                                    ) : null}
                                    <Button skin='primary-outline' className="mr-2" onPress={() => history.push("/patient/"+id+"/monitoring-sheet")} width="md">
                                        Commander une fiche conseils
                                    </Button>
                                </>
                            ): null}
                            <Button onPress={() => history.push("/patient/"+id+"/edit")} width="md">
                                Editer le profil
                            </Button>
                        </>
                    ):null}
                    {state.user.roles.some(element => element === 'ROLE_SUPER_ADMIN') ? (
                        <>
                            <Button onPress={() => history.push("/patient/"+id+"/login")} width="md">
                                Prendre le controle
                            </Button>
                        </>
                    ):null}
                </Header>
                <Content>
                    <div className="mb-6 flex">
                        <div className="w-2/3 p-4">
                            <PatientPrograms id={id} onListSet={(flag) => setDownload(flag)}/>
                        </div>
                        <div className="w-1/3 p-4">
                            {(state.role === "Coach" || state.role === "Manager" ) && patientState.institut  ? (
                                <div className="py-4">
                                    <Notes id={id}/>
                                </div>
                            ) : null}
                            {(state.role === "Nutritionniste" || state.role === "Coach" || state.role === "Manager" ) && patientState.institut ? (
                                <div className="py-4">
                                    <Messages patient={patientState}/>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Content>
            </>
        );
    }
    
    return (<></>)
}

export default View;
