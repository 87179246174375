import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import formatDate from "../utilities/date";

import { FunctionalForm } from "../components";
import {
    LayoutCenter,
    Header,
    Content,
    HeaderMenu,
    HeaderMenuItem,
} from "../common";
import { get, post } from "../server/api";
import { getCountries } from "../constants/countries";

import { UserContext } from '../context/UserContext';
    
function Profile() {
        const [initialLoading, setInitialLoading] = useState(true);
        const [defaultValues, setDefaultValues] = useState({
            lastname: "",
            firstname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            postcode: "",
            country: "",
            birthdate: "",
            gender: ""
        });
        
    const [state, setState] = useContext(UserContext);

    let location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const result = await get(location.pathname);

            if (result.success) {
                const {
                    lastname,
                    firstname,
                    email,
                    phone,
                    address,
                    city,
                    postcode,
                    country,
                    birthdate,
                    gender
                } = result.data;

                setDefaultValues({
                    lastname,
                    firstname,
                    email,
                    phone,
                    address,
                    city,
                    postcode,
                    country,
                    birthdate: formatDate(birthdate),
                    gender
                });

                setInitialLoading(false);
            }
        };

        fetchData();
    }, []);

    const saveUrl = location.pathname;
    const handleSubmit = async (values) => {
        const result = await post(saveUrl, values);

        if(result.success) {
            
            const user = state.user;
            const newUser = {...user, ...result.data};


            setState((state) => ({
                ...state,
                user: newUser
            }));
        }

        return result;
    }

    const redirectUrl = false;

    const fields = {
        gender: {
            label: "Physionomie",
            type: "select",
            choices: [
                {label: "Femme", value: "femme"},
                {label: "Homme", value: "homme"},
                {label: "Fille (-18ans)", value: "fille"},
                {label: "Garçon (-18ans)", value: "garcon"},
            ],
            required: true,
            validations: [{ rule: "required" }]
        },
        firstname: {
            label: "Prénom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        lastname: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        birthdate: {
            label: "Date de naissance",
            type: "date",
            required: false,
            validations: [{ rule: "birthdate" }],
            placeholder: "01/12/1990"
        },
        email: {
            label: "Email",
            type: "email",
            required: true,
            validations: [{ rule: "required" }, { rule: "email" }],
        },
        phone: {
            label: "Téléphone",
            type: "text",
            required: false,
            validations: [{ rule: "phone" }],
        },
        address: {
            label: "Adresse",
            type: "textarea",
            required: true,
            validations: [{ rule: "required" }],
        },
        city: {
            label: "Ville",
            type: "text",
            required: true,
            validations: [{ rule: "required" }],
        },
        postcode: {
            label: "Code postal",
            type: "text",
            required: true,
            validations: [{ rule: "required" }],
        },
        country: {
            label: "Pays",
            type: "select",
            required: true,
            choices: getCountries()
        },
    };

    const messages = {
        success: "Profil sauvegardé !",
        error:
            "Une erreur est survenue lors de la sauvegarde de vos informations.",
        button: "Mettre à jour",
    };

    return (
        <>
            <Header
                title="Mon profil"
                renderButtons={() => (
                    <HeaderMenu>
                        <Link to="/profile">
                            <HeaderMenuItem focus={true}>
                                Mes informations personnelles
                            </HeaderMenuItem>
                        </Link>
                        <Link to="/password">
                            <HeaderMenuItem focus={false}>
                                Changer mon mot de passe
                            </HeaderMenuItem>
                        </Link>
                    </HeaderMenu>
                )}
            />

            <Content>
                <LayoutCenter>
                    {initialLoading ? (
                        <div>Chargement...</div>
                    ) : (
                        <FunctionalForm
                            width="full"
                            maxWidth="3xl"
                            saveUrl={saveUrl}
                            redirectUrl={redirectUrl}
                            fields={fields}
                            defaultValues={defaultValues}
                            messages={messages}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </LayoutCenter>
            </Content>
        </>
    );
}

export default Profile;
