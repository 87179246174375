import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button } from '.';
import Draggable from 'react-draggable'; 

const PopupMorphotypes = forwardRef(({ children, title, closeButton, className }, ref) => {

    const[ toggleState, setToggleState ] = useState(false);

    useImperativeHandle(ref, () => ({
        toggle(toggle){
            setToggleState(toggle);
        }
    }));

    if(!className){
        className = "flex justify-between";
    } 

    if(toggleState){
        return (
            <div className="fixed h-full bottom-0 left-0 flex z-50">
                <Draggable>
                    <div className="bg-white rounded-md min-w-screen-md max-w-screen-md p-4 editor-pop overflow-y-auto border-4 border-solid border-red-500"> 
                        <div className="flex justify-between items-end mb-4">
                            <span className="text-2xl mr-4">{title}</span>  
                            <Button onPress={() => setToggleState(false)} skin="primary-outline">{closeButton}</Button> 
                        </div> 
                        <div className={className}>
                            {children}
                        </div>
                    </div>  
                </Draggable>
                
            </div>
        )
    }

    return null;

   
});

export default PopupMorphotypes;