import React from 'react';

function Box({ children, className }) {
    let styles = "rounded-lg overflow-hidden bg-white";

    if(className) {
        styles += " " + className;
    }

    return (
        <div className={styles}>
            {children}
        </div>        
    )
}

export default Box;