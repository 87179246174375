import React, { useState } from "react";

import { Tab, FormFields } from '../../components';
import { Header, Content, Box} from "../../common";

function InstitutList() {
    const headerTitle = 'Instituts';

    const [search, setSearch] = useState('');

    const renderManager = (coaches) => {
        let name = 'Aucun';

        coaches.forEach(element => {
            if(element.roles.some(value => value === 'ROLE_INSTITUT_MANAGER')){
                name = element.firstname + ' ' + element.lastname
            }
        });

        return name;
    }

    const renderPhone = (coaches) => {
        let name = 'Aucun';

        coaches.forEach(element => {
            if(element.roles.some(value => value === 'ROLE_INSTITUT_MANAGER')){
                name = element.phone
            }
        });

        return name;
    }

    const config = {
        headers:[
            {
                name: "name",
                label : "Nom"
            },
            {
                name: "address",
                label : "Adresse"
            },
            {
                name: "city",
                label : "Ville"
            },
            {
                name: "postcode",
                label : "Code Postal"
            },
            {
                name: "coaches",
                label: "Manager",
                render: renderManager
            },
            {
                name: "coaches",
                label: "Tél",
                render: renderPhone
            },
            {
                name: "siret_number",
                label: "Numéro de Siret",
            },
        ],
        actions: [
            {
                label: "Editer",
                name: "/institut/:id/edit",
                active: true
            }            
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des instituts.",
        no_results: "Aucun institut trouvé", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/instituts?search=' + search
    }

    const fields = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <>
            <Header title={headerTitle} />
            <Content>
                <Box className="p-4 mb-2">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setSearch(data['search'])}
                    />
                </Box>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default InstitutList;