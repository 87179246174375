import React from 'react';

function Toggle({ children, name, value, required, error, setValue }) {
    

    return (
        <div className="mb-4">
            <input id={"toggle-" + name} className="toggle" name={name} type="checkbox" defaultChecked={value} value={value} onChange={event => setValue(event.target.checked)} />
            <label htmlFor={"toggle-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default Toggle;