import React from "react";

import { FunctionalForm } from "../../components";
import { LayoutCenter, Header, Content } from "../../common";
import { genders } from "../../constants/genders";

function ProblemCreate({headerTitle, redirectUrl, messages}) {    

    let saveUrl = '/metabolic-problem/create';

    let baseFields = {
        name: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        gender: {
            label: "Afficher pour",
            type: "multiselect",
            required: false,
            validations: [],
            choices: genders 
        },
    }

    return (
        <>
            <Header title={headerTitle} />
            <Content>
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="3xl" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={baseFields}
                        messages={messages}
                    />
                </LayoutCenter>
            </Content>
        </>
    );
}

export default ProblemCreate;