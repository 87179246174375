import React, { useContext, useEffect, useState } from "react";

import { FunctionalForm } from "../../components";
import { LayoutCenter, Header, Content } from "../../common";
import { UserContext } from '../../context/UserContext';
import { get, post } from '../../server/api';
import { getCountries } from '../../constants/countries';

function UserCreate({headerTitle, redirectUrl, role, messages, defaultValues}) {
    const [ state ] = useContext(UserContext);
    const [instituts, setInstituts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [websites, setWebsites] = useState([]);

    useEffect(()=>{
        setLoading(true);
        const fetchData = async () => {
            const result = await get('/instituts?all=1');
            if (result.success) {
                let institutChoices = [];
                result.items.forEach(institut => {
                    institutChoices.push({
                        value: institut.id, 
                        label: institut.name,
                    })
                });
                setInstituts(institutChoices);
            } else {
                console.log(result);
            }
        };

        const fetchWebsite = async () => {
            const result = await get('/websites?all=1');
            if (result.success) {
                let websiteChoices = [];
                result.items.forEach(website => {
                    websiteChoices.push({
                        value: website.code, 
                        label: website.name,
                    })
                });
                setWebsites(websiteChoices);
            } else {
                console.log(result);
            }
        };

        console.log(state.role);
        if(state.role === 'Administrateur' || role === 'coach'){
            fetchData();
            fetchWebsite();
        }
        setLoading(false);
    }, [])

    let saveUrl = '/user/create';

    let baseFields = {
        lastname: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        firstname: {
            label: "Prénom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        email: {
            label: "Email",
            type: "email",
            required: true,
            validations: [{ rule: "required" }, { rule: "email" }]
        },
        phone: {
            label: "Téléphone",
            type: "text",
            required: false,
            validations: [{ rule: "phone" }]
        },
    }

    let passwordFields = {
        new_password: {
            label: "Mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "password" }, {rule: "required"}],
        },
        confirm_password: {
            label: "Confirmer le mot de passe",
            type: "password",
            required: true,
            validations: [
                { rule: "password" },
                { rule: "confirm_password" },
                { rule: "required" }
            ],
        },
    }

    switch (role) {
        case 'nutritionist':
            saveUrl = saveUrl + '?role=ROLE_NUTRITIONIST';
            baseFields = { 
                ...baseFields, 
                gender: {
                    label: "Civilité",
                    type: "select",
                    choices: [
                        {label: "Femme", value: "femme"},
                        {label: "Homme", value: "homme"},
                    ],
                    required: true,
                    validations: [{ rule: "required" }]
                }
                //...passwordFields
            }
            break;
        
        case 'coach':
            saveUrl = saveUrl + '?role=ROLE_COACH';
            baseFields = {
                ...baseFields,
                address: {
                    label: "Adresse",
                    type: "textarea",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                city: {
                    label: "Ville",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                postcode: {
                    label: "Code postal",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                country: {
                    label: "Pays",
                    type: "select",
                    required: true,
                    choices: getCountries()
                },
                gender: {
                    label: "Civilité",
                    type: "select",
                    choices: [
                        {label: "Femme", value: "femme"},
                        {label: "Homme", value: "homme"},
                    ],
                    required: true,
                    validations: [{ rule: "required" }]
                },
                //...passwordFields
            }

            if(state.role === 'Administrateur'){
                let institutField = {};
                if(instituts && instituts.length){
                    institutField = {
                        institut: {
                            label: "Institut",
                            type: "select",
                            choices: instituts,
                            required: false,
                            validations: []
                        }
                    };
                }

                baseFields = {
                    ...baseFields,
                    ...institutField,
                    siret: {
                        label: "Numéro de Siret",
                        type: "text",
                        required: false,
                        validations: []
                    },
                    website_code: {
                        label: "Site",
                        type: "select",
                        choices: websites,
                        required: true,
                        validations: [{rule: "required"}]
                    },
                }
            }
            break;

        case 'patient':
            saveUrl = saveUrl + '?role=ROLE_PATIENT';
            baseFields = {
                gender: {
                    label: "Physionomie",
                    type: "select",
                    choices: [
                        {label: "Femme", value: "femme"},
                        {label: "Homme", value: "homme"},
                        {label: "Fille (-18ans)", value: "fille"},
                        {label: "Garçon (-18ans)", value: "garcon"},
                    ],
                    required: true,
                    validations: [{ rule: "required" }]
                },
                birthdate: {
                    label: "Date de naissance",
                    type: "date",
                    required: false,
                    validations: [{ rule: "birthdate" }],
                    placeholder: "01/12/1990"
                },
                ...baseFields,
                address: {
                    label: "Adresse",
                    type: "textarea",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                city: {
                    label: "Ville",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                postcode: {
                    label: "Code postal",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                country: {
                    label: "Pays",
                    type: "select",
                    required: true,
                    choices: getCountries()
                },
                //...passwordFields
            }

            if(state.role === 'Administrateur'){
                baseFields = {
                    ...baseFields,
                    website_code: {
                        label: "Site",
                        type: "select",
                        choices: websites,
                        required: true,
                        validations: [{rule: "required"}]
                    },
                }
            }
            break;
    
        default:
            baseFields = { 
                ...baseFields, 
                //...passwordFields
            }
            break;
    }

    const handleSubmit = async (values) =>
    {
        if(!(values.hasOwnProperty('institut') && values.institut)){
            if(state.user.hasOwnProperty('institut')){
                values = {
                    ...values,
                    institut: state.user.institut.id
                }
            }
        }

        return await post(saveUrl, values);
    }

    return (
        <>
            <Header title={headerTitle} />
            <Content>
                <LayoutCenter>
                    {!loading ? (
                        <FunctionalForm 
                            width="full" 
                            maxWidth="3xl" 
                            saveUrl={saveUrl}
                            redirectUrl={redirectUrl}
                            fields={baseFields}
                            defaultValues={defaultValues}
                            messages={messages}
                            handleSubmit={handleSubmit}
                        />
                    ) : null}
                </LayoutCenter>
            </Content>
        </>
    );
}

export default UserCreate;