import React from 'react';

function Button({ children, title, icon, color }) {


    return (
        <div className="flex items-center px-8 pb-2 pt-8">
            <div className={"rounded-full w-12 h-12 flex justify-center items-center border-"+color+" border-2 mr-2"}>
                <i className={"icon-"+ icon +" text-"+color+" text-2xl"} />
            </div>
            <span className={"text-2xl"}>{title}</span>
        </div>
    )
}

export default Button;