import React from "react";
import { Link } from "react-router-dom";

import { Menu, MenuItem, MenuSeparator } from "../common";

function CoachNav({ user }) {
    const output = [];

    output.push(
        <Menu>
            <MenuSeparator>Menu</MenuSeparator>
            <Link to={"/monitoring-sheets"}>
                <MenuItem>Programmes en attentes</MenuItem>
            </Link>
            <Link to={"/patients"}>
                <MenuItem>Mes clients</MenuItem>
            </Link>
            <Link to={"/order-history"}>
                <MenuItem>Historique de mes commandes</MenuItem>
            </Link>
        </Menu>
    );
    
    if (user.institut) {
        output.push(
            <Menu>
                <MenuSeparator>Société ( {user.institut.name} )</MenuSeparator>
                <Link to={"/institut/" + user.institut.id + "/edit"}>
                    <MenuItem>Informations</MenuItem>
                </Link>
                <Link to={"/institut/" + user.institut.id + "/order-history"}>
                    <MenuItem>Commandes</MenuItem>
                </Link>
                {/* <Link to={"/institut/" + user.institut.id + "/patients"}>
                    <MenuItem>Clients</MenuItem>
                </Link> */}
                <Link to={"/coaches"}>
                    <MenuItem>Coachs</MenuItem>
                </Link>
            </Menu>
        );
    }

    return output;
}

export default CoachNav;
