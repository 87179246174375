import React from "react";
import { Link, useParams } from 'react-router-dom'

import { FunctionalForm, Button } from "../components";
import { LayoutCenter, LayoutBetween, Content } from "../common";
import { post } from "../server/api";

function PasswordReset() {
    let { token } = useParams();

    const saveUrl = "/password/reset";
    const redirectUrl = "/login";

    const fields = {
        new_password: {
            label: "Mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "required" }, { rule: "password" }]
        },
        confirm_password: {
            label: "Confirmation nouveau mot de passe",
            type: "password",
            required: true,
            validations: [
                { rule: "required" },
                { rule: "password" },
                { rule: "confirm_password" }
            ]
        },
    }

    const messages = {
        success: 'Mot de passe modifié !',
        error: 'Une erreur est survenue lors de la modification du mot de passe.',
        button: 'Envoyer'
    }   

    const onSubmit = async (values) =>{
        let data = {
            token,
            new_password: values.new_password
        }

        const test = await post(saveUrl, data);
        return test;
    }

    return (
        <>
            <Content>
                <LayoutBetween>
                    <Link to="/login">
                        <Button width='auto' skin='back'>Retour</Button>
                    </Link>
                </LayoutBetween>
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="md" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={null}
                        messages={messages}
                        handleSubmit={(values) => onSubmit(values)}
                    />
                </LayoutCenter>
            </Content>
        </>
    );
}

export default PasswordReset;