import React from "react";

import { UserEdit } from "../../components";

function CoachEdit() {
    const redirectUrl = "/coaches";
    const headerTitle = "Edition d'un coach";

    const messages = {
        success: "Coach mis à jour !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Mettre à jour"
    }

    const defaultValues = {
        active: true,
        activities: ['cinema']
    }

    return (
        <UserEdit {...{redirectUrl, headerTitle, messages, defaultValues}} role="coach"/>
    );
}

export default CoachEdit;
