import React from "react";

function MultiCheckbox({
    children,
    choices,
    name,
    value,
    required,
    error,
    setValue,
}) {
    
    if(!value){
        value = [];
    }

    const updateValue = (newValue) => {

        var index = value.indexOf(newValue);
        if (index !== -1) {
            value.splice(index, 1);
        } else {
            value.push(newValue);
        }

        setValue(value);
    };

    const isChecked = (optionValue) => {
        if(value && value.length){
            if (value.find((v) => v === optionValue)) {
                return true;
            }
        }
        
        return false;
    };

    return (
        <div className="mb-4">
            <label className="mb-2 block font-medium">
                {children}{" "}
                {required ? <sup className="text-red-600">*</sup> : null}
            </label>
            {choices.map((choice) => (
                <div key={"multichoice-" + choice.value} className="mb-1">
                    <input
                        id={"multicheckbox-" + choice.value}
                        className="checkbox"
                        name={name}
                        type="checkbox"
                        value={choice.value}
                        defaultChecked={isChecked(choice.value)}
                        onChange={(event) => updateValue(event.target.value)}
                    />
                    <label
                        htmlFor={"multicheckbox-" + choice.value}
                        className="block font-normal"
                    >
                        {choice.label}
                    </label>
                </div>
            ))}

            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>
                {error}
            </p>
        </div>
    );
}

export default MultiCheckbox;
