import React from "react";

import { Button } from "../components";

function NotFound() {
    return (
        <>
            <div className="h-full flex justify-center items-center flex-col text-center">
                <h1 className="text-4xl font-bold">Page introuvable</h1>
                <p className="mb-4">La page que vous recherchez n'existe pas.</p>
                <Button width="md" onPress={() => window.history.back()}>Revenir</Button>

            </div>
        </>
    );
}

export default NotFound;
