import React, { useState, useEffect } from 'react';

const PatientContext = React.createContext([{}, () => {}]);

const PatientProvider = ({children}) => {
    const [patientState, setPatientState] = useState(JSON.parse(localStorage.getItem("patient")));

    useEffect(() => {
        localStorage.setItem("patient", JSON.stringify(patientState));
    }, [patientState])

    return (
        <PatientContext.Provider value={[patientState, setPatientState]}>
            {children}
        </PatientContext.Provider>
    );
}

export { PatientContext, PatientProvider };