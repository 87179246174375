import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { FunctionalForm, Button, Popup } from "../../components";
import { LayoutCenter, Header, Content } from "../../common";
import { genders } from "../../constants/genders";

import { get } from "../../server/api";

function ProblemEdit({redirectUrl, headerTitle, messages}) {
    let { id } = useParams();
    let history = useHistory();

    const deleteRef = useRef();

    const saveUrl = '/metabolic-problem/' + id;

    const [initialLoading, setInitialLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        name: "",
        alias: "",
        gender: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await get(saveUrl);

            if (result.success) {
                const {
                    name,
                    alias,
                    gender,
                } = result.data;

                setDefaultValues({
                    name,
                    alias,
                    gender,
                });

                setInitialLoading(false);
            }
        };

        fetchData();

    }, [saveUrl]);

    let baseFields = {
        name: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        gender: {
            label: "Afficher pour",
            type: "multiselect",
            required: false,
            validations: [],
            choices: genders
        },
    }

   
    const removeProblem = async () => {
        const result = await get(saveUrl+'/delete');
        if(result.success){
            deleteRef.current.toggle(false)
            history.push('/metabolic-problems');
        }
    }

    return (
        <>
            <Popup ref={deleteRef} title="Suppression d'un problème métabolique'" closeButton="Annuler" className="flex flex-col justify-center">
                <span>Êtes-vous sûr de vouloir supprimer ce problème métabolique ?</span>
                <Button onPress={() => removeProblem()}>Confirmer</Button>
            </Popup>
            <Header title={headerTitle} >
                <Button skin='back' onPress={() => deleteRef.current.toggle(true)}>Supprimer</Button>
            </Header>
            <Content>
                <LayoutCenter>
                    {initialLoading ? (
                        <div>Chargement...</div>
                    ) : (
                        <FunctionalForm
                            width="full"
                            maxWidth="3xl"
                            saveUrl={saveUrl}
                            redirectUrl={redirectUrl}
                            fields={baseFields}
                            defaultValues={defaultValues}
                            messages={messages}
                        />
                    )}
                </LayoutCenter>
            </Content>
        </>
    );
}

export default ProblemEdit;
