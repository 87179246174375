import React, { useState } from "react";

import { FormFields, FormTitle } from "../";

import { Box, LoadingArea } from "../../common";
import { Button, FileInput } from "../../components";

import { post } from "../../server/api";
import getFieldLabel from "../../utilities/field-label";
import { DOMAIN } from "../../constants/config";

function FormMedicalInfos({
    patientId,
    data,
    setData,
    documents,
    setDocuments,
    status
}) {
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("authToken");

    let url = "/monitoring-sheet/upload";
    if (patientId) {
        url += "/" + patientId;
    }

    const deleteDocument = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const documentId = e.target.value;
        setLoading(true);
        const result = await post(
            "/monitoring-sheet/document/delete/" + documentId
        );

        if (result && result.success) {
            const newMedical = documents.medical.filter(
                (p) => parseInt(p.id) !== parseInt(documentId)
            );

            setDocuments({ medical: newMedical });
        }

        setLoading(false);
    };

    const onChange = async (files) => {
        const body = new FormData();
        body.append("file", files[0]);
        body.append("type", "medical");
        setLoading(true);
        const result = await post(url, body, true);

        if (result && result.success) {
            const newMedical = documents.medical;
            newMedical.push(result.data);
            setDocuments({ medical: newMedical });
        }
        setLoading(false);
    };

    const renderDocuments = () => {
        if (!documents.medical && !documents.medical.length) {
            return null;
        }
        
        return (
            <div className="relative flex flex-col mb-4">
                {documents.medical.map((m) => (
                    <div
                        key={"p" + m.id}
                        className="flex justify-between items-center w-full"
                    >
                        <Button skin="link" className="ml-0 mr-auto" onPress={() => window.open(DOMAIN + "/document/" + token + "/" + m.id)}>
                            <strong>{m.name}</strong>
                        </Button>
                        {status !== 'submitted' && status !== 'pending' && status !== 'processing'? (
                            <Button
                                skin='back'
                                value={m.id}
                                onPress={(e) => deleteDocument(e)}
                                className="mr-0 ml-auto mt-4"
                            >
                                Supprimer
                            </Button>
                        ) : null}
                    </div>
                ))}
            </div>
        );
    };

    const fields = {
        drugs: {
            label: /*getFieldLabel('drugs')*/'',
            type: "textarea",
            required: true,
            validations: [{rule: "required"}],
        },
    };

    return (
        <LoadingArea isLoading={loading}>
            <Box>
                <FormTitle title="Médicaments et compléments alimentaires" icon="pills" color="black"/>
                <div className="px-8 pb-4 pt-2">
                    <FormFields
                        fields={fields}
                        defaultValues={data}
                        handleUpdate={(data) => setData(data)}
                    />
                </div>
                <div className="px-8 pb-8">
                { status !== 'submitted' && status !== 'pending' && status !== 'processing' ? (
                    <p className="italic mb-4">
                        Si vous avez une ou plusieurs ordonnance(s), merci de
                        les joindre
                    </p>
                ):null}
                    {documents.medical.length ? renderDocuments() : null}
                    {status !== 'submitted' && status !== 'pending' && status !== 'processing'? (
                        <FileInput
                            onChange={onChange}
                            accept="application/pdf, image/png, image/jpg"
                            multiple={false}
                            label="Cliquez ici pour ajouter un document..."
                        />
                    ):null}
                </div>
            </Box>
        </LoadingArea>
    );
}

export default FormMedicalInfos;
