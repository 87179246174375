import React from "react";

import { 
    MonitoringSheetForm
} from '../../components';


function MonitoringSheet() {
    return (
        <MonitoringSheetForm
            loadUrl={"/monitoring-sheet"}
            saveUrl={"/monitoring-sheet"}
            submitUrl={"/monitoring-sheet/submit"}
            patientId={null}
         />
    );
}

export default MonitoringSheet;