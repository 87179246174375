import React from "react";
import { ProblemCreate } from "../../components";

function MetabolicProblemCreate() {
    const headerTitle = "Nouveau problème métabolique";
    const redirectUrl = "/metabolic-problems";

    const messages = {
        success: "Problème métabolique créé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Créer"
    }

    return (
        <ProblemCreate {...{headerTitle, redirectUrl, messages }}/>
    );
}

export default MetabolicProblemCreate;
