import React from 'react';

function Button({ children, width = 'auto', onPress, type = 'button' , isLoading, className, value, skin }) {

    let finalClassName = "";
    switch (skin) {
        case "primary-outline":
            finalClassName = "border-blue-600 border-2 text-blue-600 px-4 py-2 hover:bg-blue-600 hover:text-white rounded-full w-" + width;            
            break;
        case "transparent":
            finalClassName = "px-4 py-2  border-2 border-transparent hover:bg-gray-300 rounded-full w-" + width;            
            break;
        case "gray":
            finalClassName = "px-4 py-2 bg-gray-300 border-2 border-gray-300 hover:bg-gray-400 hover:border-gray-400 rounded-full w-" + width;            
            break;
        case "link":
            finalClassName = "px-2 text-gray-400 hover:text-gray-500 w-" ;    
            break;
        case "back":
            finalClassName = "bg-red-300 border-2 border-red-300 px-4 py-2 hover:bg-red-400 hover:border-red-400 rounded-full w-" + width;
            break;
        default:
            finalClassName = "bg-blue-300 border-2 border-blue-300 px-4 py-2 hover:bg-blue-400 hover:border-blue-400 rounded-full w-" + width;
            break;
    }


    if(isLoading) {
        finalClassName += " opacity-75";
    }

    if(className && className !== "") {
        finalClassName += " " +className;
    }

    return (
        <button className={finalClassName} value={value} onClick={onPress} type={type} disabled={isLoading}>
            {children}
        </button>
    )
}

export default Button;