import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Paging, Button } from "../components";
import { get } from "../server/api";
import { Message, LoadingArea } from "../common";

function Tab({ config, setStatePage, urlPage }) {
    const [dataTab, setDataTab] = useState([]);
    const [page, setPage] = useState(urlPage ? urlPage : 1);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const result = await get(config.fetchUrl, { page });
            if (result.success) {
                setDataTab(result);
            } else {
                setErrorMsg(config.error);
            }
        };

        if(urlPage){
            fetchData();
        } else {
            setPage(1);
            fetchData();
        }

        setLoading(false);
    }, [config]);

    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            const result = await get(config.fetchUrl, { page });
            if (result.success) {
                setDataTab(result);
            } else {
                setErrorMsg(config.error);
            }
        };

        if(urlPage){
            setStatePage(page);
        } else {
            fetchData();
        }
       
        setLoading(false);
    }, [page]);

    /*useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const result = await get(config.fetchUrl, { page });
            if (result.success) {
                setDataTab(result);
            } else {
                setErrorMsg(config.error);
            }
            setLoading(false);
        };

        fetchData();
    }, [page, config]);*/

    const { items, totalPage, total } = dataTab;

    const localHandleAction = async (action, element) => {
        if (typeof config.handleAction !== "undefined") {
            let result = await config.handleAction(action, element);
            if(result && result.hasOwnProperty('success') && result.success){
                const newtab = await get(config.fetchUrl, { page });
                if (newtab.success) {
                    setDataTab({...newtab});
                } else {
                    setErrorMsg(config.error);
                }
            }
        } else {
            history.push(action.replace(":id", element.id));
        }
    };

    const renderActions = (actions, element) => {
        return actions.map((action,i) => 
            action.hasOwnProperty('active') && action.active 
            && !(action.name === 'validate' && element.hasOwnProperty('status') && element.status === 'order_approved')
            && !(action.name === 'invoice' && element.hasOwnProperty('status') && element.status === 'order_placed')
            ? (
                <Button key={action.name+i} onPress={() => localHandleAction(action.name, element)}>
                    {action.label}
                </Button>
            ) : null
           
        );
    };

    const renderField = (header, value) => {
        if (typeof header.render !== "undefined") {
            return header.render(value);
        }

        return value;
    };

    let currentPage = null;
    let maxPage = null;
    let currentItems = null;
    let maxItems = null;

    if (items && items.length) {
        currentPage = page;
        maxPage = totalPage;
        currentItems = items.length;
        maxItems = total;
    }

    if(errorMsg) {
        return (
        <div className="bg-white overflow-hidden rounded-lg w-full">
            <Message type="error">{errorMsg}</Message>
        </div>)
    }

    return (
        <div className="bg-white overflow-hidden rounded-lg w-full">
            <LoadingArea isLoading={loading}>
            {items && items.length ? (
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-gray-400">
                                {config.headers.map((header, index) => (
                                    <th
                                        key={index}
                                        className="px-4 py-2 text-left text-gray-500"
                                    >
                                        {header.label}
                                    </th>
                                ))}
                                {config.customActions ? config.customActions.map((action, i) => (
                                    <th key={i} className="px-4 py-2 text-left text-gray-500">{action.label}</th>
                                )) : null}
                                {config.actions ? (
                                    <th key="actions">&nbsp;</th>
                                ) : null}
                            </tr>
                        </thead>
                            <tbody>
                                {items.map((element, i) => (
                                    <tr
                                        key={element.id}
                                        className={
                                            i === items.length - 1
                                                ? null
                                                : "border-b border-gray-400"
                                        }
                                    >
                                        {config.headers.map((header, j) => (
                                            <td
                                                key={i + "" + j}
                                                className="px-4 py-2 text-left"
                                            >
                                                {renderField(
                                                    header,
                                                    element[header.name]
                                                )}
                                            </td>
                                        ))}
                                        {config.customActions ? config.customActions.map((action, i ) => (
                                            <td key={i} className="px-4 py-2 text-right">
                                                {action.render(element)}
                                            </td>
                                        )) : null}
                                        {config.actions ? (
                                            <td className="px-4 py-2 text-right">
                                                {renderActions(
                                                    config.actions,
                                                    element
                                                )}
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                    </table>
                    {maxPage > 1 ? (
                        <Paging
                            {...{
                                setPage,
                                currentPage,
                                maxPage,
                                currentItems,
                                maxItems,
                            }}
                        />
                    ) : null}
                </div>
            ) : <Message type="info">{config.no_results}</Message>}
            </LoadingArea>
        </div>
    );
}

export default Tab;
