import React from 'react';

function Sidebar({ children }) {
    return (
        <div className="flex flex-col bg-black  h-screen w-1/5 justify-between">
            {children}
        </div>        
    )
}

export default Sidebar;