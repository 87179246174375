import React from 'react';

function LayoutCenter({ children }) {
    return (
        <div className="w-full flex justify-center pt-4">
            {children}
        </div>        
    )
}

export default LayoutCenter;