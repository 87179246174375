export default function getIdealMeasure(data, gender) {

    const men = {
        height: 170,
        handleWidth: 7,
        chestSize: 100,
        waistSize: 80,
        hipCircumference: 100,
    }

    const women = {
        height: 170,
        handleWidth: 6,
        chestSize: 100,
        waistSize: 70,
        hipCircumference: 100,
    }

    let iHandle = ((parseFloat(data.height) - 100) / 10);

    let iHipCircumference = null;
    let iChestSize = null;
    let iWaistSize = null;
    let iWeightMax = null;
    let iWeightMin = null;

    if(gender === "femme"){
        iHandle = iHandle - 1;

        let delta = Math.round((parseFloat(data.handleWidth) - iHandle ) * 4);

        let relativeHeight = parseFloat(data.height) + delta;
        let idealMeasure = relativeHeight - women.height;

        iHipCircumference = women.hipCircumference + idealMeasure;
        iChestSize = women.chestSize + idealMeasure;
        iWaistSize = women.waistSize + idealMeasure;

        iWeightMax = parseFloat(data.height) - 100 - delta;
        iWeightMin = iWeightMax - 10;

        console.log('data', data);
        console.log('iHandle', iHandle);
        console.log('delta', delta);
        console.log('relativeHeight', relativeHeight, parseFloat(data.height), delta);
        console.log('idealMeasure', idealMeasure);
        console.log('iHipCircumference', iHipCircumference);
        console.log('iChestSize', iChestSize);
        console.log('iWaistSize', iWaistSize);
        console.log('iWeightMax', iWeightMax);
        console.log('iWeightMin', iWeightMin);
    } else {
        let delta = Math.round((parseFloat(data.handleWidth) - iHandle) * 4);
        let relativeHeight = parseFloat(data.height) + delta ;
        let idealMeasure = relativeHeight - men.height;

        iHipCircumference = men.hipCircumference + idealMeasure;
        iChestSize = men.chestSize + idealMeasure;
        iWaistSize = men.waistSize + idealMeasure;

        iWeightMax = parseFloat(data.height) - 100 - delta ;
        iWeightMin = iWeightMax - 10;

        console.log('data', data);
        console.log('iHandle', iHandle);
        console.log('delta', delta);
        console.log('relativeHeight', relativeHeight, parseFloat(data.height), delta);
        console.log('idealMeasure', idealMeasure);
        console.log('iHipCircumference', iHipCircumference);
        console.log('iChestSize', iChestSize);
        console.log('iWaistSize', iWaistSize);
        console.log('iWeightMax', iWeightMax);
        console.log('iWeightMin', iWeightMin);
    }

    

    let result = {
        ideal:{
            height : parseFloat(data.height),
            handleWidth: Math.round(iHandle),
            hipCircumference: Math.round(iHipCircumference),
            chestSize: Math.round(iChestSize),
            waistSize: Math.round(iWaistSize),
            weightMax: Math.round(iWeightMax),
            weightMin: Math.round(iWeightMin)
        },
        difference : {
            height : parseFloat(data.height),
            handleWidth: Math.round((parseFloat(data.handleWidth) - iHandle)),
            hipCircumference: Math.round((parseFloat(data.hipCircumference) - iHipCircumference )),
            chestSize: Math.round((parseFloat(data.chestSize) - iChestSize)),
            waistSize: Math.round((parseFloat(data.waistSize) - iWaistSize)),
            weightMax: Math.round((parseFloat(data.weight) - iWeightMax)),
            weightMin: Math.round((parseFloat(data.weight) - iWeightMin))
        }
    }

    /*let result = {
        ideal:{
            height : parseFloat(data.height),
            handleWidth: Math.round(iHandle * 10) / 10,
            hipCircumference: Math.round(iHipCircumference * 10) / 10,
            chestSize: Math.round(iChestSize * 10) / 10,
            waistSize: Math.round(iWaistSize * 10) / 10,
            weightMax: Math.round(iWeightMax * 10) / 10,
            weightMin: Math.round(iWeightMin * 10) / 10
        },
        difference : {
            height : parseFloat(data.height),
            handleWidth: Math.round((parseFloat(data.handleWidth) - iHandle) * 10) / 10,
            hipCircumference: Math.round((parseFloat(data.hipCircumference) - iHipCircumference )* 10) / 10,
            chestSize: Math.round((parseFloat(data.chestSize) - iChestSize) * 10) / 10,
            waistSize: Math.round((parseFloat(data.waistSize) - iWaistSize) * 10) / 10,
            weightMax: Math.round((parseFloat(data.weight) - iWeightMax) * 10) / 10,
            weightMin: Math.round((parseFloat(data.weight) - iWeightMin) * 10) / 10
        }
    }*/

    return result;
}