import React, {useContext, useState, useEffect}from "react";
import { Link, useHistory } from "react-router-dom";

import { Tab, Button, FormFields } from '../../components';
import { Header, Content, Box } from "../../common";
import { UserContext } from "../../context/UserContext";
import { PatientContext } from "../../context/PatientContext";
import formatDate from "../../utilities/date";
import { get, post } from "../../server/api";

function PatientList() {
    let headerTitle = "Patients";
    let history = useHistory();

    const [ state ] = useContext(UserContext);
    const [ patientState, setPatientState ] = useContext(PatientContext);
    const [ coachs, setCoachs ] = useState([]);
    const [search, setSearch] = useState('');
    const [coachPatient, setCoachPatient] = useState([]);

    if(state.role === 'Coach' || state.role === 'Manager'){
        headerTitle = "Mes Clients";
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await get('/users?role=ROLE_COACH&all=1');
            if (result.success) {
                let newCoaches = [];
                result.items.forEach((coach) => {
                    newCoaches.push({
                        value: coach.id,
                        label: coach.firstname+' '+coach.lastname
                    })
                })
                setCoachs(newCoaches);
            } else {
                console.log(result);
            }
        };

        fetchData();
    }, [coachPatient]);

    const transferCoach = async (newCoachId, patientId) => {
        let params = { coach: newCoachId };
        const result = await post('/user/'+patientId+'/assign', params);

        if(result.success){
            setCoachPatient([newCoachId, patientId])
        }
    }

    const updateUnit = async (unit, userId) => {
        let params = { unit };
        const result = await post('/unit/'+userId, params);

        if(result.success){
            console.log(result);
        }
    }

    const renderCustomAction = (element) => {
        let value = '';
        if(state.role === 'Administrateur' && element.coach){
            let coach = element.coach

            const fields = {
                coach: {
                    label: false,
                    type: "select",
                    choices: coachs,
                    required: false,
                    validations: [],
                },
            }

            if(coach){
                value = (
                    <FormFields
                        fields={fields}
                        handleUpdate={(data) => transferCoach(data.coach, element.id)}
                    />
                );
            }
        }

        return value;
    }

    const renderUnit = (element) => {
        let value = ''

        if(state.role === 'Administrateur'){

            const fields = {
                unit: {
                    label: false,
                    type: "number",
                    required: false,
                    validations: [],
                    isInt: true,
                },
            }

            value = (
                <FormFields
                    fields={fields}
                    handleUpdate={(data) => updateUnit(data.unit, element.id)}
                    defaultValues={{unit: element.credits}}
                />
            );
        }

        return value;
    }

    const renderCoach = (coach) => {
        let value = 'Aucun';
        if(coach){
            value = coach.firstname + ' ' + coach.lastname;
        }

        return value;
    }

    const renderNutritionist = (nutritionist) => {
        let name = 'Aucun';
        if(nutritionist){
            name = nutritionist.firstname + ' ' + nutritionist.lastname;
        }
        return name;
    }

    const see = (action, element) => {
        setPatientState((state) => ({
            ...state,
            ...element,
        }));
        history.push(action.replace(":id", element.id));
    }

    let config = {
        headers:[
            {
                name: "firstname",
                label : "Prénom"
            },
            {
                name: "lastname",
                label : "Nom"
            },
            {
                name: "email",
                label : "Email"
            },
            /*{
                name: "nutritionist",
                label : "Nutristionniste",
                render: renderNutritionist
            },*/
        ],
        actions: [
            {
                label: "Voir le patient",
                name: "/patient/:id",
                active: true
            }            
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des patients.",
        no_results: "Aucun patient trouvé", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/users?search=' + search + '&role=ROLE_PATIENT',
        handleAction: see,
    }

    if(state.role === 'Coach' || state.role === 'Manager'){
        headerTitle = "Mes Clients";

        config = {
            ...config,
            error: "Une erreur est survenue lors de la récupération de la liste des cients.",
            no_results: "Aucun client trouvé", 
        }
    } else {
        config.headers.push({
            name: "coach",
            label : "Coach",
            render: renderCoach 
        })

        if(state.role === 'Administrateur'){
            config = {
                ...config,
                customActions: [
                    {
                        label: "Transférer ?",
                        render: renderCustomAction,
                    },
                    {
                        label: "Crédits",
                        render: renderUnit
                    }            
                ],
            }
        }
    }

    config.headers.push({
        name: "lastOrder",
        label : "Dernière commande",
        render: formatDate,
    })

    const fields = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <>
            <Header title={headerTitle} >
                <Link to="/patient/create">
                    { state.role !== 'Nutritionniste' ? (
                        <Button skin='primary-outline'>
                            {state.role === 'Coach' || state.role === 'Manager' ? 'Ajouter un client' : 'Ajouter un patient'}
                        </Button>
                    ) : null}
                </Link>
            </Header>
            <Content>
                <Box className="p-4 mb-2">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setSearch(data['search'])}
                    />
                </Box>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default PatientList;