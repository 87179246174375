import React from 'react';

function TextArea({ children, name, required, value, setValue, error }) {
    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <textarea id={"select-" + name} name={name} rows="5" className="bg-gray-400 w-full py-2 px-4 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}></textarea>
            
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
     
    )
}

export default TextArea;