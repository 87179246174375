import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { get } from "../../server/api";

import { LoadingArea, Box, Message } from "../../common";
import { Button } from "../../components";

import { UserContext } from "../../context/UserContext";

import formatDate from "../../utilities/date";

import { post } from '../../server/api';

function Activities({ id }) {
    const history = useHistory();

    const [state, setState] = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (typeof id === "undefined") {
            setUser(state.user);
        } else {
            setLoading(true);
            const fetchData = async () => {
                const result = await get("/user/" + id);
                if (result.success) {
                    setUser(result.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [id, state.user]);

    if (!loading && !user) {
        return (
            <Box className="py-8 px-8">
                <p className="text-center">Impossible de charger les données</p>
            </Box>
        );
    }

    const clickOnActivity = (e, activityId) => {
        e.preventDefault();

        const activity = user.activities.find((a) => parseInt(a.id) === parseInt(activityId));
        let link = "";
        if(activity) {
            if (activity.subject_link && activity.subject_link !== "") {
                link = activity.subject_link;
                if (activity.subject_id && activity.subject_id !== "") {
                    link = link.replace(":id", activity.subject_id);
                }
            }
    
            if(activity.active) {
                post('/activity/'+activityId, { active: 0 });
            }
    
            if(link !== "") {
                history.push(link);
            }
        }

        return false;
    }

    const renderActivity = (activity) => {
        let icon = "info-circle";
        switch (activity.alias) {
            case "welcome":
                icon = "happy";
                break;
            case "message":
                icon = "user-bubble";
                break;
            case "evaluation-missing":
                icon = "clipboard-warning";
                break;
            case "program-complete":
                icon = "clipboard-check";
                break;
            case "program-processing":
                icon = "clipboard-user";
                break;

            default:
                break;
        }
        

        let style = "flex items-start text-left p-2 border-b";

        if(activity.active) {
            style += " border-green-400 bg-green-200 hover:bg-green-300";
        }
        else {
            style += " border-gray-200 hover:bg-gray-100";
        }

        return (
            <button
                value={activity.id}
                onClick={(e) => clickOnActivity(e, activity.id)}
                className={style}
            >
                <div className="pr-3">
                    <i className={"text-xl icon-" + icon}></i>
                </div>
                <div className="flex flex-col">
                    <span>{activity.title}</span>
                    <em className="text-sm">
                        Le {formatDate(activity.created_at, true)}
                    </em>
                </div>
            </button>
        );
    };

    return (
        <Box className="w-full">
            <LoadingArea isLoading={loading}>
                <div className="p-8">
                    <div className="flex justify-between mb-4">
                        <h3 className="text-2xl">Dernières activités</h3>
                    </div>
                    <div className="flex flex-col">
                        {user.activities.length ? (
                            user.activities.map((activity) =>
                                renderActivity(activity)
                            )
                        ) : (
                            <Message className="default">
                                Aucune activité enregistrée
                            </Message>
                        )}
                    </div>
                </div>
            </LoadingArea>
        </Box>
    );
}

export default Activities;
