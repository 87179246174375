import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button } from './';

const Popup = forwardRef(({ children, title, closeButton, className }, ref) => {

    const[ toggleState, setToggleState ] = useState(false);

    useImperativeHandle(ref, () => ({
        toggle(toggle){
            setToggleState(toggle);
        }
    }));

    if(!className){
        className = "flex justify-between";
    } 

    if(toggleState){
        return (
            <div className="fixed w-full h-full inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                <div className="bg-white rounded-md min-w-screen-md max-w-screen-md p-4 editor-pop overflow-y-auto"> 
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-2xl mr-4">{title}</span>  
                        <Button onPress={() => setToggleState(false)} skin="primary-outline">{closeButton}</Button> 
                    </div> 
                    <div className={className}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }

    return null;

   
});

export default Popup;