import React from "react";
import { useLocation, Link } from "react-router-dom";

import { FunctionalForm } from "../components";
import {
    LayoutCenter,
    Header,
    Content,
    HeaderMenu,
    HeaderMenuItem,
} from "../common";

function Password() {
    let location = useLocation();

    const saveUrl = location.pathname;
    const redirectUrl = false;

    const fields = {
        current_password: {
            label: "Mot de passe actuel",
            type: "password",
            required: true,
            validations: [{ rule: "required" }, { rule: "password" }],
        },
        new_password: {
            label: "Nouveau mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "required" }, { rule: "password" }],
        },
        confirm_password: {
            label: "Confirmation nouveau mot de passe",
            type: "password",
            required: true,
            validations: [
                { rule: "required" },
                { rule: "password" },
                { rule: "confirm_password" },
            ],
        },
    };

    const messages = {
        success: "Votre mot de passe a bien été mis à jour !",
        error:
            "Une erreur est survenue, veuillez réessayer ou contactez un administrateur",
        button: "Mettre à jour",
    };

    return (
        <>
            <Header
                title="Mon profil"
                renderButtons={() => (
                    <HeaderMenu>
                        <Link to="/profile">
                            <HeaderMenuItem focus={false}>
                                Mes informations personnelles
                            </HeaderMenuItem>
                        </Link>
                        <Link to="/password">
                            <HeaderMenuItem focus={true}>
                                Changer mon mot de passe
                            </HeaderMenuItem>
                        </Link>
                    </HeaderMenu>
                )}
            />
            <Content>
                <LayoutCenter>
                    <FunctionalForm
                        width="full"
                        maxWidth="md"
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={null}
                        messages={messages}
                    />
                </LayoutCenter>
            </Content>
        </>
    );
}

export default Password;
