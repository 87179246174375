import React, { useState, useContext } from "react";

import { Box } from "../../common";
import { Button, FormFields, Form } from "../../components";

import { PatientContext } from "../../context/PatientContext";
import { post } from '../../server/api';

function Notes({ id }) {
    const [patientState, setPatientState] = useContext(PatientContext);
    const [content, setContent] = useState(patientState.notes);
    const [lastContent, setLastContent] = useState(patientState.notes);
    const [save, setSave] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let params = {
            notes: content
        }

        const result = await post("/user/" + id, params);
        if(result.success){

            let newPatientState = {
                ...patientState,
                notes: content
            }
            
            setSave(true);
            setLastContent(content);
            setPatientState(newPatientState)
        }
    }

    const fields = {
        notes: {
            label: "",
            type: "textarea",
            required: false,
            validations: []
        },
    }

    const textChange = (newContent) => {
        if(lastContent !== newContent){
            setSave(false);
        } else {
            setSave(true);
        }
        setContent(newContent);
    }

    return (
        <Box className="w-full">
            <div className="flex flex-col p-8">
                <div className="flex justify-between mb-4">
                    <h3 className="text-2xl">Notes {save ? null : "*"}</h3>
                </div>
                <Form {...{ handleSubmit }} className="flex flex-col pt-4 w-full">
                    <FormFields 
                        defaultValues={{notes: patientState.notes}} 
                        fields={fields}
                        handleUpdate={(data) => textChange(data['notes'])}
                    />
                    <Button type={'button'} onPress={(e) => handleSubmit(e)}>Sauvegarder</Button>
                </Form>
            </div>
        </Box>
    );
}

export default Notes;
