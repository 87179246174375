import React from 'react';

function Form({ children, width = 'full', maxWidth, handleSubmit, box }) {

    let styles = [];

    if(box){
        styles.push('box');
    }

    if(maxWidth) {
        styles.push('max-w-' + maxWidth);
    }

    if(width) {
        styles.push('w-' +width);
    }

    return (
        <div className={styles.join(' ')}>
            <form onSubmit={(e) => handleSubmit(e)}>
                {children}
            </form>
        </div>        
    )
}

export default Form;