import React, { useEffect, useState } from "react";
import { LayoutCenter, Header, Content } from "../../common";

function CoachView() {

    return (
        <>
            <Header title="" />

            <Content>
                <LayoutCenter>                    
                </LayoutCenter>
            </Content>
        </>
    );
}

export default CoachView;