import React, { useState } from 'react';

function MultiSelect({ children, name, choices, required, error, value, setValue }) {

    const [items, setItems] = useState(value ? value.split(',') : []);

    function handleChange(eValue, target) {
        let newItems = [...items];
        if(!newItems.some(value => value === eValue)){
            newItems.push(eValue)
        }
        newItems.sort((a,b) => a.localeCompare(b))
        setItems(newItems);

        let finalValue = newItems.join();

        setValue(finalValue);
        target.value = "";
    }

    const getLabel = (item) => {
        let element = choices.find(element => element.value === item)
        if(element){
            return element.label;
        }
        return false
    }

    const removeItem = (item) => {
        let newItems = [...items];
        let index = items.findIndex(element => element === item);
        if(index !== -1){
            newItems.splice(index, 1);
            newItems.sort((a,b) => a.localeCompare(b))
            setItems(newItems);

            let finalValue = newItems.join();
            setValue(finalValue);
        }
    }

    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <select defaultValue="" id={"select-" + name} name={name} className="bg-gray-400 w-full py-2 px-4 rounded-lg" onChange={event => handleChange(event.target.value, event.target)}>
                <option key={'default'} value="" selected>Sélection</option>
                {choices && choices.length ? choices.map((option, index) => <option key={index} value={option.value}>{option.label}</option>) : null}
            </select>
            <div className="flex flex-wrap">
                {items.map((item, i) =>
                    getLabel(item) ? (
                        <div className="pt-2 pr-2 cursor-pointer" key={i} onClick={() => removeItem(item)}>
                            <span className="ml-2 py-1 px-2 rounded-full text-white text-xs font-bold bg-blue-400" >{getLabel(item)} X</span>
                        </div>
                    ) : null
                )}
            </div>
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default MultiSelect;