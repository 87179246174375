import React from 'react';

function LayoutBetween({ children }) {
    return (
        <div className="w-full flex justify-between pt-4">
            {children}
        </div>        
    )
}

export default LayoutBetween;