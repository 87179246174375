import * as c from '../constants/config';
import serialize from './serialize';

export async function getToken(username, password){
    let { client_id, client_secret} = window.api_config;

    let grant_type = c.GRANT_TYPE;

    if(!client_id || !client_secret){
        window.alert('Error client_id client_secret');
    } 

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: serialize({
            client_id,
            client_secret,
            grant_type,
            username: username,
            password: password
        })
    };
    
    const response = await fetch(c.OAUTH_URL + "/token", requestOptions);
    const data = response.json()
    return data;
}

export async function refreshToken(){
    let { client_id, client_secret} = window.api_config;

    let grant_type = c.GRANT_TYPE_REFRESH;

    if(!client_id || !client_secret){
        window.alert('Error client_id client_secret');
    } 

    const refreshToken = localStorage.getItem("refreshToken");

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: serialize({
            client_id,
            client_secret,
            grant_type,
            refresh_token: refreshToken
        })
    };
    
    const response = await fetch(c.OAUTH_URL + "/token", requestOptions);
    const data = response.json()
    return data;
}

export async function get(path, params, domain = false){
    let token = localStorage.getItem('authToken');

    const requestOptions = {
        method: "GET",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": 'Bearer ' + token,
        }

    };

    let url = c.API_EXT_URL + path;

    if(domain){
        url = c.DOMAIN + path;
    }
    
    if(params){
        if(!url.includes('?')) {
            url = url + '?'
        }
        else {
            url = url + '&';
        }
        
        url = url + serialize(params)
    }
    
    const response = await fetch(url, requestOptions);
    const data = response.json()
    return data;
}

export async function post(path, params, rawBody = false){
    let token = localStorage.getItem('authToken');
    var query = "";

    if(rawBody) {
        query = params;
    }
    else {
        query = new FormData();
    
        if(params && typeof params !== "undefined") {
            let keys = Object.keys(params);
            keys.forEach(key => {
                query.append(key, params[key])   
            });            
        }
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": 'Bearer ' + token,
        },
        body: query
    };

    const response = await fetch(c.API_EXT_URL + path, requestOptions);
    const data = response.json()
    return data;
}
