import React, {useState, useEffect} from 'react';
import NumberField from './NumberField';

function Paging({ maxPage, currentPage, setPage, maxItems, currentItems }) {
    const itemStyle="p-2 hover:bg-blue-400 hover:text-white cursor-pointer";
    const [ newPage, setNewPage ] = useState(currentPage);

    useEffect(() => {
        setNewPage(currentPage);
    }, [currentPage])

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let page = newPage;
        if(!page){
            page = 1
        } 

        setPage(page);
    }
    
    return (
        <div className="flex justify-between items-center pb-4 px-4">
            <span></span>
            <ul className="flex justify-center items-center bg-white rounded-lg">
                { currentPage > 1 && (<> 
                    <li className={itemStyle + " rounded-l-lg"} onClick={() => setPage(1)}>{"<<"}</li>
                    <li className={itemStyle} onClick={() => setPage(currentPage - 1)}>{"<"}</li>
                    { currentPage > 5 ? (
                         <li className={itemStyle} onClick={() => setPage(currentPage - 5)}>{currentPage - 5}</li>
                    ) : null}
                   
                </>)}
                <li className={"p-2"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <NumberField max={maxPage} className={"bg-gray-400 w-16 text-center py-2 px-4 rounded-lg"} value={newPage} setValue={(value) => setNewPage(value)} isInt/>
                    </form>
                </li>
                { currentPage < maxPage && (<> 
                    { currentPage + 5 < maxPage ? (
                         <li className={itemStyle} onClick={() => setPage(currentPage + 5)}>{currentPage + 5}</li>
                    ) : null}
                    <li className={itemStyle} onClick={() => setPage(currentPage + 1)}>{">"}</li>
                    <li className={itemStyle + " rounded-r-lg"} onClick={() => setPage(maxPage)}>{">>"}</li>
                </>)}
            </ul> 
            <span className="flex justify-center bg-white rounded-lg">{maxItems} résultats</span>  
        </div>
         
    )
}

export default Paging;