export const morphotypes = [
    {
        label: 'Vide',
        value: ''
    },
    {
        label: 'Galilée',
        value: 'galilee'
    },
    {
        label: 'Ascétique',
        value: 'ascetique'
    },
    {
        label: 'Athlétique',
        value: 'athletique'
    },
    {
        label: 'Cachectique',
        value: 'cachectique'
    },
    {
        label: 'Monastique',
        value: 'monastique'
    },
    {
        label: 'Don Camillo',
        value: 'doncamillo'
    },
    {
        label: 'Tronc d’arbre',
        value: 'troncdarbre'
    },
    {
        label: 'Schwartzy',
        value: 'schwartzy'
    },
    {
        label: 'Rubens',
        value: 'rubens'
    },
    {
        label: 'Maya',
        value: 'maya'
    },
    {
        label: 'Cheops',
        value: 'cheops'
    },
    {
        label: 'Végétarien',
        value: 'vegetarian'
    },
    {
        label: 'Sablier',
        value: 'sablier'
    },
]