export default function getStateColor(state) {
    let color = null;
    switch (state) {
        case "incomplete":
            color = "red-700";
            break;
        case "processing":
            color = "blue-400";
            break;
        case "complete":
            color = "green-400";
            break;
        default:
            break;
    }

    return color;
}
