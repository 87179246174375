import React from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

function FormFoodProfile({ children, data, setData }) {
    const fields = {
        foodProfile: {
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("carnivorous"),
                value: 'carnivorous'
            },{
                label: getFieldLabel("vegetarian"),
                value: 'vegetarian'
            },{
                label: getFieldLabel("without_pork"),
                value: 'without_pork'
            },{
                label: getFieldLabel("without_seafood"),
                value: 'without_seafood'
            },{
                label: getFieldLabel("alcohol_free"),
                value: 'alcohol_free'
            }],
        },
    }

    return (
        <Box>
            <FormTitle title={getFieldLabel("foodProfile")} icon="fork-knife" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields 
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                />
            </div>
        </Box>
    )
}

export default FormFoodProfile;