import React from 'react';

function Timeline({ step, setStep, setIndex, next, previous }) {
    let spanStyle = "w-1/3 p-4 text-center hover:bg-blue-400 cursor-pointer";
    let buttonStyle = "w-1/6 p-4 text-center bg-gray-400 text-gray-400 cursor-not-allowed";
    let buttonStyleHover = "w-1/6 p-4 text-center bg-gray-400 hover:bg-gray-500 cursor-pointer";
    let spanStyleFocus = spanStyle + " bg-blue-300 hover:bg-blue-300";

    const onPress = (newStep, index) => {
        setStep(newStep);
        setIndex(index);
    }

    return (
        <div className="w-full bg-white rounded-lg flex justify-around relative overflow-hidden">
            <span className={ step === 'morphotype' ? buttonStyle : buttonStyleHover } onClick={() => previous()}>
                Précédent
            </span>
            <span className={step === 'morphotype' ? spanStyleFocus : spanStyle} onClick={() => onPress('morphotype', 1)}>
                Vos Données morphotype
            </span>
            <span className={step === 'life' ? spanStyleFocus : spanStyle} onClick={() => onPress('life', 2)}>
                Paramètres de Vie
            </span>
            <span className={step === 'medical' ? spanStyleFocus : spanStyle} onClick={() => onPress('medical', 3)}>
                Paramètres Médicaux
            </span>
            <span className={ step === 'medical' ? buttonStyle : buttonStyleHover } onClick={() => next()}>
                Suivant
            </span>
        </div>
    )
}

export default Timeline;