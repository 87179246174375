import React from 'react';
import { useLocation } from 'react-router-dom';

function Screen({ children }) {
    //const location = useLocation();

    let style = "w-full flex flex-col box-border h-screen bg-gray-300 overflow-auto";

    /*if(location.pathname === "/cgu"){
        style += "overflow-auto"
    }*/

    return (
        <div className={style}>
            {children}
        </div>        
    )
}

export default Screen;