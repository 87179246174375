import React from "react";

function FormatContent({content}) {
    const max = 100;
    const renderContent = content.substring(0, max) + ' ...';

    return (
        <div>
            {renderContent}
        </div>
    );
}

export default FormatContent;