import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../context/UserContext';

function UserCredits({ children }) {
    const [state] = useContext(UserContext)

    const { credits } = state.user;

    return (
        <div className="mt-4">
            <ul>
                <Link to={"/checkout"}>
                    <li className="mb-1 py-1 px-4 justify-between flex cursor-pointer">
                        
                        <div>
                            <span className="text-gray-500 block">
                                <strong className="text-white">{credits ? credits : 0}</strong> unité{credits > 1 ? "s" : ""} 
                            </span>
                            <span className=" block text-blue-600 hover:text-blue-700">Acheter des unités</span>
                        </div>
                        <i className="icon-wallet text-2xl text-white"></i>
                    </li>
                </Link>
            </ul>
        </div>        
    )
}

export default UserCredits;