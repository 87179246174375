import React, { useContext } from "react";

import { Button } from "../../components"
import getFieldLabel from "../../utilities/field-label";
import getIdealMeasure from "../../utilities/ideal-measurements";
import { DOMAIN } from "../../constants/config";
import { UserContext } from '../../context/UserContext';
import { PrescriptionEdit } from "../../screens";

function ProgramData({ program, userId, PrescriptionButton }) {
    const [ state ] = useContext(UserContext);
    const token = localStorage.getItem("authToken");

    let data = null;
    let photoUrl = null;
    let imgMorphotype = '';
    let idealMeasurements= null;

    console.log(program);

    if(program){
        data = program.data.data;

        photoUrl = program.documents.photo
        ? DOMAIN + "/document/" + token + "/" + program.documents.photo.id
        : null;

        if(program && program.hasOwnProperty('status') && program.status !== 'submitted'){
            imgMorphotype = DOMAIN + '/morphotype/'+token+'/'+program.patient.id+'?chest_size='+data.chestSize+'&handle_width='+data.handleWidth+'&height='+data.height+'&hip_circumference='+data.hipCircumference+'&waist_size='+data.waistSize+'&weight='+data.weight;
        }
    
        idealMeasurements = getIdealMeasure(data, program.patient.gender);
    }

    /*data = {
        ...data,
        height : 170,
        handleWidth: 6,
        chestSize: 100,        
        waistSize: 70,
        hipCircumference: 100,
        weight: 62.1,
    }*/
  
    if(program){
        return (
            <>
                <div className="flex justify-between mb-4">                
                    <div className="flex p-4 flex-col w-full">
                        <div className="flex items-center mb-2">
                            <i className="icon-height text-2xl  mr-2"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("measures")}
                            </span>
                        </div>
                        <div className="flex w-full justify-between">
                            <div className="flex flex-col pt-6 flex-1">
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("height")} </strong>{" "}
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("handleWidth")} </strong>{" "}
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("weight")} </strong>{" "}
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("chestSize")} </strong>{" "}
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("waistSize")} </strong>{" "}
                                <strong className="mb-1 pb-1 border-b border-gray-200">{getFieldLabel("hipCircumference")} </strong>{" "}
                            </div>
                            <div className="flex flex-col flex-1">
                                <strong>{getFieldLabel("real")} </strong>{" "}
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.height} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.handleWidth} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.weight} kg
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.chestSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.waistSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {data.hipCircumference} cm
                                </span>
                            </div>
                            <div className="flex flex-col flex-1">
                                <strong>{getFieldLabel("ideal")} </strong>{" "}
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    -
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    -
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.ideal.weightMax} / {idealMeasurements.ideal.weightMin} kg
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.ideal.chestSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.ideal.waistSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.ideal.hipCircumference} cm
                                </span>
                            </div>
                            <div className="flex flex-col flex-1">
                                <strong>{getFieldLabel("difference")} </strong>{" "}
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    -
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    -
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                {idealMeasurements.difference.weightMax} / {idealMeasurements.difference.weightMin} kg
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.difference.chestSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.difference.waistSize} cm
                                </span>
                                <span className="inline-block mb-1 pb-1 border-b border-gray-200">
                                    {idealMeasurements.difference.hipCircumference} cm
                                </span>
                            </div>
                        </div>
                    </div>
    
                    {program.documents.photo && state.role !== 'Nutritionniste' && state.role !== 'Administrateur' ? (
                        <div className="p-4">
                            <img src={photoUrl} alt="" className="photo-patient" />
                        </div>
                    ) : null}
                </div>
                {imgMorphotype ? (
                    <div className="flex w-full">
                        <div className="flex justify-between mb-4">
                            <div className="flex p-4 flex-col w-full">
                                <div className="flex items-center mb-2">
                                    <i className="icon-victory text-2xl mr-2"></i>{" "}
                                    <span className="text-bold text-2xl">Morphotype</span>
                                </div>
                                <div>
                                    <img src={imgMorphotype} alt='morphotype'/>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
    
                <div className="flex w-full">
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-running text-2xl mr-2"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("activities")}
                            </span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                            <strong>{getFieldLabel("job")} </strong>{" "}
                            <span className="inline-block ml-4">{data.job}</span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                            <strong>{getFieldLabel("physicalActivity")} </strong>{" "}
                            <span className="inline-block ml-4">
                                {data.physicalActivity}
                            </span>
                        </div>
                    </div>
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-fork-knife text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("foodProfile")}
                            </span>
                        </div>
                        <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                            {data.foodProfile.length ? data.foodProfile.map((profile) => (
                                <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                    {getFieldLabel(profile)}
                                </span>
                            )) : "Pas de spécificités"}
                        </div>
                    </div>
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-group-circle text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("others")}
                            </span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                            <strong>{getFieldLabel("familySituation")} </strong>{" "}
                            <span className="inline-block ml-4">
                                {getFieldLabel(data.familySituation)}
                            </span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                            <strong>{getFieldLabel("childrenNumber")} </strong>{" "}
                            <span className="inline-block ml-4">
                                {data.childrenNumber}
                            </span>
                        </div>
                        {data.observations && data.observations !== "" ? (
                            <div className="flex pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                                <strong>{getFieldLabel("observations")} </strong>{" "}
                                <span className="inline-block ml-4">
                                    {data.observations}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="flex w-full">
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-cigar text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                Allergies et Consommations
                            </span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 flex-col border-b border-gray-200">
                            <strong className="block">
                                {getFieldLabel("allergies")}{" "}
                            </strong>
                            <div className="flex flex-col">
                                {data.allergies.length ? data.allergies.map((profile) => (
                                    <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                        {getFieldLabel(profile)}
                                    </span>
                                )) : "Aucune"}
                            </div>
                        </div>
                        {data.foodsAllergies ? (
                            <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                                <>
                                    <strong className="block">
                                        {getFieldLabel("foodsAllergies")}{" "}
                                    </strong>
                                    <div className="flex">
                                        <span className="m-1 rounded block py-1 px-2">
                                            {data.foodsAllergies}
                                        </span>
                                    </div>
                                </>
                            </div>
                        ): null}
                        {data.drugsAllergies ? (
                            <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                                <>
                                    <strong className="block">
                                        {getFieldLabel("drugsAllergies")}{" "}
                                    </strong>
                                    <div className="flex">
                                        <span className="m-1 rounded  block py-1 px-2">
                                            {data.drugsAllergies}
                                        </span>
                                    </div>
                                </>
                            </div>
                        ): null}
                        {data.othersAllergies ? (
                            <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                                <>
                                    <strong className="block">
                                        {getFieldLabel("othersAllergies")}{" "}
                                    </strong>
                                    <div className="flex">
                                        <span className="m-1 rounded block py-1 px-2">
                                            {data.othersAllergies}
                                        </span>
                                    </div>
                                </>
                            </div>
                        ): null}
                        <div className="flex pt-1 pb-1 mb-1 flex-col border-b border-gray-200">
                            <strong className="block">
                                {getFieldLabel("consumptions")}{" "}
                            </strong>
                            <div className="flex flex-col">
                                {data.consumptions.length ? data.consumptions.map((profile) => (
                                    <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                        {getFieldLabel(profile)}
                                    </span>
                                )) : "Aucune"}
                            </div>
                        </div>
                    </div>
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-baby text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("motherhood")}
                            </span>
                        </div>
                        <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                            {data.women.length ? data.women.map((profile) => (
                                <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                    {getFieldLabel(profile)}
                                </span>
                            )) : "Pas de spécificités"}
                        </div>
                    </div>
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-heart-pulse text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                {getFieldLabel("healthProblems")}
                            </span>
                        </div>
                        <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                            {data.diseases.length ? data.diseases.map((profile) => (
                                <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                    {getFieldLabel(profile)}
                                </span>
                            )): "Pas de spécificités"}
                        </div>
                        <div className="flex flex-col pt-1 pb-1 mb-1 border-b border-gray-200">
                            {data.organs ? (
                                <>
                                    <strong className="block">
                                        {getFieldLabel("organs")}{" "}
                                    </strong>
                                    <div className="flex">
                                        <span className="m-1 rounded block py-1 px-2">
                                            {data.organs}
                                        </span>
                                    </div>
                                </>
                            ): null}
                        </div>
                    </div>
                    {data.questions.length ? (
                        <div className="flex p-4 w-full flex-col  mb-4">
                            <div className="flex items-center mb-2">
                                <i className="icon-heart-plus text-2xl  mr-4"></i>{" "}
                                <span className="text-bold text-2xl">Autre</span>
                            </div>
                            <div className="flex flex-col pt-1 pb-1 mb-1 justify-between border-b border-gray-200">
                                <span className="inline-block">
                                    {data.questions.length ? data.questions.map((profile) => (
                                        <span key={profile} className="m-1 rounded bg-gray-300 block py-1 px-2">
                                            {getFieldLabel(profile)}
                                        </span>
                                    )) : "Aucune"}
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex p-4 w-full flex-col  mb-4">
                        <div className="flex items-center mb-2">
                            <i className="icon-pills text-2xl  mr-4"></i>{" "}
                            <span className="text-bold text-2xl">
                                Traitements médicaux
                            </span>
                        </div>
                        <div className="flex pt-1 pb-1 mb-1 flex-col border-b border-gray-200">
                            <strong className="block">
                                {getFieldLabel("drugs")}{" "}
                            </strong>
                            <div className="flex flex-col">
                                {data.drugs ? 
                                    <span className="m-1 rounded bg-gray-300 block py-1 px-2">
                                        {data.drugs}
                                    </span>
                                : "Aucune"}
                            </div>
                        </div>
                    </div>
                </div>
                    
                {program.documents.medical && program.documents.medical.length? (
                    <div className="mt-4 ">
                        <div className="flex p-4 w-full flex-col  mb-4">
                            <div className="flex items-center mb-2">
                                <i className="icon-pills text-2xl  mr-4"></i>{" "}
                                <span className="text-bold text-2xl">Documents médicaux</span>
                            </div>
                            <div className="flex flex-col pt-1 pb-1 mb-1 justify-between">
                                {program.documents.medical.map((document) => (
                                    <Button skin='link' className="mr-auto ml-0 mb-2" key={document.id} onPress={() => window.open(DOMAIN + "/document/" + token + "/" + document.id)}>
                                        {document.name}
                                    </Button>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                ) : null}
                {program.documents.evaluation ? (
                    <div className="mt-4 ">
                        <div className="flex p-4 w-full flex-col  mb-4">
                            <div className="flex items-center mb-2">
                                <i className="icon-clipboard-list text-2xl  mr-4"></i>{" "}
                                <span className="text-bold text-2xl">Bilan biologique</span>
                            </div>
                            <div className="flex pt-1 pb-1 mb-1 items-center">
                                <Button className="mr-2" onPress={() => window.open(DOMAIN + "/document/" + token + "/" + program.documents.evaluation.id)}>
                                    Voir
                                </Button>
                                {program.documents.evaluation.password ? (
                                    <span className="mr-2">Mot de passe : {program.documents.evaluation.password ? program.documents.evaluation.password : "Aucun" }</span>
                                ) : null}
                                { PrescriptionButton ? (
                                    <PrescriptionButton />
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
                {program.data.content && program.status === 'complete' ? (
                    <div className="mt-4 program">
                        <div className="flex p-4 w-full flex-col  mb-4">
                            <div className="flex items-center mb-2">
                                <i className="icon-clipboard-list text-2xl  mr-4"></i>{" "}
                                <span className="text-bold text-2xl">Programme</span>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: program.data.content}}/>      
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    return null;
}

export default ProgramData;
