import React from "react";
import { Link } from 'react-router-dom'

import { FunctionalForm, Button } from "../components";
import { LayoutCenter, LayoutBetween, Content } from "../common";

function PasswordForgot() {

    const saveUrl = "/password/forgot";
    const redirectUrl = "/login";

    const fields = {
        email: {
            label: "Email",
            type: "email",
            required: true,
            validations: [{ rule: "required" }, { rule: 'email'}]
        },
    }

    const messages = {
        success: 'Lien de récupération du mot de passe envoyé !',
        error: 'Votre email est incorrect',
        button: 'Envoyer'
    }   

    return (
        <>
            <Content>
                <LayoutBetween>
                    <Link to="/login">
                        <Button width='auto' skin='back'>Retour</Button>
                    </Link>
                </LayoutBetween>
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="md" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={null}
                        messages={messages}
                    />
                </LayoutCenter>
            </Content>
        </>
    );
}

export default PasswordForgot;