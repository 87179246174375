import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import logo from '../assets/images/logo.png';

function UserCard({ children }) {
    const [state, setState] = useContext(UserContext)

    const { firstname, lastname, gender } = state.user;
    const role = state.role;   

    const civility = gender === 'femme' || gender === 'fille' ? 'Mme' : 'M';

    const renderAvatar = () => {

        let icon = "icon-head";
        /*switch (gender) {
            case 'homme':
            case 'garcon':
                icon = "icon-portrait-male";
                break;
        
            default:
                icon = "icon-portrait-female";
                break;
        }*/

        return (
            <div className="rounded-full w-12 h-12 flex justify-center items-center border-white border-2">
                <i className={icon+" text-white text-2xl"}></i>
            </div>
        )
    }

    return (
        <Link to="/dashboard">
            <div className="bg-white flex items-center justify-center">
                <img src={logo} alt="logo" width={"176"}/>
            </div>
            <div className="bg-gray-900 px-4 py-2 flex items-center">
                {renderAvatar()}
                <div className="flex flex-col justify-center px-3">
                    { role !== 'Administrateur' ? <span className="text-white font-bold">{civility} {firstname} {lastname}</span> : null}
                    <span className="text-gray-600 text-sm">{role}</span>
                </div>
                {children}
            </div>  
        </Link>
              
    )
}

export default UserCard;