import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';

import { Tab, Button, FormFields } from '../../components';
import { Header, Content, Box} from "../../common";
import { UserContext } from "../../context/UserContext"
import { post } from "../../server/api";

function CoachList() {
    const headerTitle = 'Coachs';

    const [search, setSearch] = useState('');

    const [ state ] = useContext(UserContext);

    const renderInstitut = (institut) => {
        let name = 'Aucun';
        if(institut && institut.hasOwnProperty('name')){
            name = institut.name;
        }
        return name
    }

    let config = {
        headers:[
            {
                name: "firstname",
                label : "Prénom"
            },
            {
                name: "lastname",
                label : "Nom"
            },
            {
                name: "email",
                label : "Email"
            },
            {
                name: "phone",
                label : "Tél"
            },
            {
                name: "institut",
                label : "Institut",
                render: renderInstitut
            },
        ],
        actions: [
            {
                label: "Editer",
                name: "/coach/:id/edit",
                active: true
            }            
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des coachs.",
        no_results: "Aucun coach trouvé", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/users?search=' + search + '&role=ROLE_COACH'
    }

    const updateUnit = async (unit, userId) => {
        let params = { unit };
        const result = await post('/unit/'+userId, params);

        if(result.success){
            console.log(result);
        }
    }

    const renderUnit = (element) => {
        let value = ''

        if(state.role === 'Administrateur'){

            const customFields = {
                unit: {
                    label: false,
                    type: "number",
                    required: false,
                    validations: [],
                    isInt: true,
                },
            }

            value = (
                <FormFields
                    fields={customFields}
                    handleUpdate={(data) => updateUnit(data.unit, element.id)}
                    defaultValues={{unit: element.credits}}
                />
            );
        }

        return value;
    }


    if(state.role === 'Administrateur'){
        config = {
            ...config,
            customActions: [
                {
                    label: "Crédits",
                    render: renderUnit
                }            
            ],
        }
    }

    const fields = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

   
    return (
        <>
            <Header title={headerTitle}>
                <Link to="/coach/create">
                    <Button>Ajouter un coach</Button>
                </Link>
            </Header>
            <Content>
                <Box className="p-4 mb-2">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setSearch(data['search'])}
                    />
                </Box>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default CoachList;