import React, { useContext, useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { post, get } from "../../server/api";

import getRole from "../../utilities/roles";

function PatientAdminLogin() {
    const [state, setState] = useContext(UserContext);
    let { id } = useParams();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const login = async () => {
            const result = await post('/user/login/'+id);

            if (result.hasOwnProperty("error")) {
                localStorage.clear();
                return { success: false };
            }
    
            localStorage.setItem("authToken", result.access_token);
            localStorage.setItem("refreshToken", result.refresh_token);
            localStorage.setItem("loggedIn", true);
    
            const profileResult = await get("/profile");
    
            if (profileResult.success) {
                let user = profileResult.data;
                localStorage.setItem("user", JSON.stringify(user));
                let role = getRole(user.roles);
                localStorage.setItem('role', role);
                setState((state) => ({
                    ...state,
                    loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
                    user,
                    role
                }));
    
                setIsLoggedIn(true);
            } else {
                localStorage.clear();
                setState((state) => ({
                    ...state,
                    loggedIn: false,
                    user: false,
                }));
            }
        };

        if(state.user.roles.some(element => element === 'ROLE_SUPER_ADMIN')) {
            login();
        }
        
    }, [id]);

    
    if(isLoggedIn) {
        return <Redirect to={"/dashboard"}/>
    }

    return null;
}

export default PatientAdminLogin;
