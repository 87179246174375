import React from "react";
import { UserCreate} from "../../components";

function NutritionistCreate() {
    const headerTitle = "Nouveau nutritionniste";
    const redirectUrl = "/nutritionists";

    const messages = {
        success: "Nutritionniste créé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Créer"
    }

    const defaultValues = {
        active: true,
        activities: ['cinema']
    }

    return (
        <UserCreate {...{headerTitle, redirectUrl, messages, defaultValues}} role="nutritionist"/>
    );
}

export default NutritionistCreate;
