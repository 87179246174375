import React, {useState, useEffect} from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { Tab, Button, FormFields, FormatContent } from '../../components';
import { Header, Content, Box} from "../../common";
import formatDate from "../../utilities/date";
import {morphotypes} from '../../constants/morphotypes';

import { get } from "../../server/api";

function SheetList() {
    //const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [metabolicProblems, setMetabolicProblems] = useState([]);
    const { page } = useParams();
    const [statePage, setStatePage] = useState(page ? page : 1);
    
    const headerTitle = "Morphotypes";
    const history = useHistory();

    useEffect(() => {
        if(statePage !== 1){
            history.push('/sheets/'+statePage);
        } else {
            history.push('/sheets');   
        }
    }, [statePage]);

    useEffect(() => {
            //setLoading(true);
            const fetchData = async () => {
                const result = await get("/metabolic-problems?all=1");
                if (result.success) {
                    let newProblems = [];
                    result.items.forEach(element => {
                        newProblems.push({
                            label: element.name,
                            value: element.alias
                        })
                    });
                    setMetabolicProblems(newProblems);
                    //setLoading(false);
                } else {
                    //setLoading(false);
                }
            };

            fetchData();
    }, []);

    const renderContent = (content) => {
        return <FormatContent {...{content}} />
    }

    const setFetchUrl = () => {
        let url = '/sheets';
        let search = false;
        
        if(data.hasOwnProperty('metabolicProblem') && data['metabolicProblem']){
            if(!search){
                url += '?';
                search = true;
            }
            url += 'metabolic_problem='+data['metabolicProblem'];
        }

        if(data.hasOwnProperty('morphotype') && data['morphotype']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'morphotype='+data['morphotype'];
        }

        if(data.hasOwnProperty('trend') && data['trend']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'trend='+data['trend'];
        }

        if(data.hasOwnProperty('subTrend') && data['subTrend']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'sub_trend='+data['subTrend'];
        }

        if(data.hasOwnProperty('height') && data['height']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'height='+data['height'];
        }

        return url;
    }

    const config = {
        headers:[
            {
                name: "title",
                label : "Titre"
            },
            /*{
                name: "metabolic_problem",
                label : "Problème metabolique"
            },
            {
                name: "morphotype",
                label : "Morphotype"
            },
            {
                name: "trend",
                label : "Tendance"
            },
            {
                name: "sub_trend",
                label : "Sous-tendance"
            },*/
            {
                name: "height",
                label : "Taille"
            },
            {
                name: "short_content",
                label : "Contenu", 
                render: renderContent
            },
            {
                name: "updated_at",
                label : "Date de mise à jour",
                render: formatDate
            },
        ],
        actions:[
            {
                label: "Voir",
                name: "/sheet/:id",
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des fiches.",
        no_results: "Vous n'avez pas encore de fiches", 
        redirect: "",
        redirectButton: "",
        fetchUrl: setFetchUrl()
    };

    const fields = {
        /*search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },*/
        metabolicProblem: {
            label: "Problème métabolique",
            type: "multiselect",
            required: false,
            validations: [],
            choices: metabolicProblems,
        },
        morphotype: {
            label: "Morphotype",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        trend: {
            label: "Tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        subTrend: {
            label: "Sous-tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        height: {
            label: "Taille",
            type: "number",
            required: false,
            validations: []
        },
    }

    return (
        <>
            <Header title={headerTitle}>
                <Link to="/sheet/create">
                    <Button>Créer une nouvelle fiche</Button>
                </Link>
            </Header>
            <Content>
                <Box className="p-4 mb-2 flex justify-between">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setData(data)}
                    />
                </Box>
                <Tab {...{config, setStatePage}} urlPage={statePage}/>
            </Content>
        </>
    );
}

export default SheetList;