import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { FunctionalForm, FormFields } from "../../components";
import { LayoutCenter, Header, Content, Box } from "../../common";
import { UserContext } from "../../context/UserContext";
import { get } from "../../server/api";

function InstitutEdit() {
    let { id } = useParams();
    let saveUrl = '/institut/'+id;
    let [state] = useContext(UserContext);
    
    const [initialLoading, setInitialLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        name: "",
        address: "",
        city: "",
        postcode: ""
    });
    const [coaches, setCoaches] = useState([]);
    const [manager, setManager] = useState({manager: null});

    useEffect(() => {
        const fetchData = async () => {
            const result = await get('/institut/'+id);

            if (result.success) {
                const {
                    id,
                    name,
                    address,
                    city,
                    postcode,
                    siret,
                    manager
                } = result.data;

                setDefaultValues({
                    name,
                    address,
                    city,
                    postcode,
                    siret_number: siret
                });

                setManager({
                    manager: manager.id
                })

                setInitialLoading(false);

                const coachesList = await get('/users?role=ROLE_COACH&institut_id='+ id);
                if(coachesList.success){
                    let managerChoices = [];
                    coachesList.items.forEach(coach => {
                        managerChoices.push({value: coach.id, label: coach.firstname + ' ' + coach.lastname})
                    });
                    setCoaches(managerChoices);
                }
            }
        };

        fetchData();
    }, []);

    if(manager.hasOwnProperty('manager') && manager.manager){
        saveUrl = saveUrl + '?manager=' + manager.manager
    }

    const redirectUrl = '/instituts';

    const fields = {
        name: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        address: {
            label: "Adresse",
            type: "textarea",
            required: true,
            validations: [{ rule: "required" }]
        },
        city: {
            label: "Ville",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        postcode: {
            label: "Code postal",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        siret_number: {
            label: "Siret",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    const managerField = {
        manager: {
            label: "Manager",
            type: "select",
            required: true,
            validations: [{ rule: "required" }],
            choices: coaches
        },
    }

    const messages = {
        success: "Informations Institut sauvegardé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Mettre à jour"
    }

    return (
        <>
            <Header title="Institut" />
            <Content>
                <LayoutCenter>
                    {initialLoading ? ( 
                        <div>Chargement...</div>
                    ) : (
                        <FunctionalForm 
                            width="full" 
                            maxWidth="3xl" 
                            saveUrl={saveUrl}
                            redirectUrl={redirectUrl}
                            fields={fields}
                            defaultValues={defaultValues}
                            messages={messages}
                        >
                            {state.role === "Administrateur" ? (
                                <FormFields defaultValues={manager} fields={managerField} handleUpdate={(values) => setManager(values)}/>
                            ) : null}
                        </FunctionalForm>
                    )}    
                </LayoutCenter>
            </Content>
        </>
    );
}

export default InstitutEdit;
