import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
    TextField,
    TextArea,
    EmailField,
    PasswordField,
    Select,
    MultiSelect,
    Toggle,
    MultiCheckbox,
    DateField,
    ImageUpload,
    FileUpload,
    NumberField
} from "./";

import getFieldError from "../utilities/form-validator";

const FormFields = forwardRef(({ fields, defaultValues, handleUpdate }, ref) => {

    const defaultErrors = {};
    const defaultFormValues = {};
    const validations = {};

    Object.keys(fields).forEach((fieldName) => {
        defaultErrors[fieldName] = "";
        defaultFormValues[fieldName] =
            defaultValues && defaultValues.hasOwnProperty(fieldName)
                ? defaultValues[fieldName]
                : "";

        if (fields[fieldName].hasOwnProperty("validations")) {
            validations[fieldName] = fields[fieldName].validations;
        }
    });
    
    const [values, setValues] = useState(defaultFormValues);
    const [errors, setErrors] = useState(defaultErrors);

    useImperativeHandle(ref, () => ({
        checkErrors() {
            const newErrors = { ...errors };
            let hasErrors = false;
            Object.keys(values).forEach((key) => {
                newErrors[key] = getFieldError(key, values, validations);
                if (newErrors[key] !== "") {
                    hasErrors = true;
                }
            });
            setErrors(newErrors);

            return hasErrors;
        },
        resetValue(field){
            const newValues = { ...values };
            newValues[field] = defaultFormValues[field];
            setValues(newValues);
    
            if(typeof handleUpdate !== "undefined"){
                handleUpdate(newValues);
            }
        }
    }));

    const updateValue = (field, value) => {
        const newValues = { ...values };
        newValues[field] = value;
        setValues(newValues);

        const newErrors = { ...errors };
        newErrors[field] = getFieldError(field, newValues, validations);
        setErrors(newErrors);

        if(typeof handleUpdate !== "undefined"){
            handleUpdate(newValues, newErrors);
        }
    };

    const renderFields = () =>{
        const result = [];

        const fieldTypes = {
            email: EmailField,
            text: TextField,
            password: PasswordField,
            select: Select,
            multiselect: MultiSelect,
            textarea: TextArea,
            toggle: Toggle,
            multicheckbox: MultiCheckbox,
            date: DateField,
            image: ImageUpload,
            file: FileUpload,
            number: NumberField
        };

        Object.keys(fields).forEach((fieldName) => {
            const Field = fieldTypes[fields[fieldName].type];
            result.push(
                <Field
                    key={fieldName}
                    name={fieldName}
                    required={fields[fieldName].required}
                    placeholder={fields[fieldName].hasOwnProperty('placeholder') ? fields[fieldName].placeholder : ''}
                    max={fields[fieldName].hasOwnProperty('max') ? fields[fieldName].max : null}
                    value={values[fieldName]}
                    error={errors[fieldName]}
                    setValue={(value) => updateValue(fieldName, value)}
                    choices={
                        fields[fieldName].hasOwnProperty("choices")
                            ? fields[fieldName].choices
                            : []
                    }
                    isInt={fields[fieldName].isInt}
                >
                    {fields[fieldName].label}
                </Field>
            );
        });

        return result;
    }

    return renderFields();
    
});

export default FormFields;