import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import { Link } from 'react-router-dom';

import { Box } from "../../common";

import formatPrice from "../../utilities/price";
import { UserContext } from "../../context/UserContext";

import { post } from "../../server/api";

const CLIENT =  window.api_paypal;

const CLIENT_ID =  CLIENT.production;

let PayPalButton = null;

function PaypalButton({ isScriptLoaded, isScriptLoadSucceed, cart, handleSuccess }) {
    const [showButtons, setShowButtons] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paid, setPaid] = useState(false);
    window.React = React;
    window.ReactDOM = ReactDOM;

    const [state, setState] = useContext(UserContext);
    const { user } = state;

    useEffect(() => {
        if (isScriptLoaded && isScriptLoadSucceed && !showButtons && !paid) {
            PayPalButton = window.paypal.Buttons.driver("react", {
                React,
                ReactDOM,
            });

            setLoading(false);
            setShowButtons(true);
        }
    }, [isScriptLoaded, isScriptLoadSucceed, showButtons, paid]);

    const createOrder = (data, actions) => {
        const { name, totalPrice, reference, quantity, unitPrice, creditTotal, reference_id } = cart;
        const { user } = state;

        
        return actions.order.create({
            purchase_units: [
                {
                    reference_id: reference_id,
                    description: name,
                    amount: {
                        currency_code: "EUR",
                        value: totalPrice,
                    },
                    // items: [{
                    //     sku: reference,
                    //     name: name,
                    //     quantity: quantity,
                    //     price: unitPrice,
                    //     currency: 'EUR'
                    // }]
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        });
    };

    const onApprove = (data, actions) => {
        actions.order.capture().then((details) => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID,
            };

            const { name, totalPrice, reference_id, creditTotal } = cart;

            post('/order-confirmation', {
                description: name,
                total: totalPrice,
                creditQuantity: creditTotal,
                reference: reference_id 
            });

            if(typeof handleSuccess !== "undefined") {
                handleSuccess(creditTotal);
            }

            user.credits = parseInt(user.credits) + parseInt(cart.creditTotal);
            
            setState((state) => ({
                ...state,
                user,
            }));
            
            setShowButtons(false);
            setPaid(true);
        });
    };

    return (
        <Box className="w-full p-8">
            {loading && <div>Chargement...</div>}

            {showButtons && !paid && (
                <div>
                    <div>
                        <h2>{user.firstname}</h2>
                        <h2>{cart.name}</h2>
                        <h2>Total : {formatPrice(cart.totalPrice)}</h2>
                    </div>

                    <PayPalButton
                        createOrder={(data, actions) =>
                            createOrder(data, actions)
                        }
                        onApprove={(data, actions) => onApprove(data, actions)}
                    />
                </div>
            )}                

            {paid && (
                <div className="text-center">
                    <h2 className="text-3xl font-light mb-4 text-green-500">
                        Votre paiement a bien été validé !
                    </h2>
                    <h3 className="text-4xl font-bold mb-6">Solde de crédits : {user.credits}</h3>
                    <p className="text-lg">Un email récapitulatif va vous être envoyé</p>
                    <p>Vous pouvez retrouver votre historique de commandes dans <Link to={"/order-history"} className="underline">cette section</Link></p>
                </div>
            )}
        </Box>
    );
}

export default scriptLoader(
    `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR`
)(PaypalButton);
