import React, { useState, useEffect, useContext, useRef } from "react";

import { get } from "../../server/api";

import { LoadingArea, Box } from "../../common";
import { Button, FormFields, Form } from "../../components";

import { UserContext } from "../../context/UserContext";
import { post } from '../../server/api';
import formatDate from "../../utilities/date";

function Messages({ patient }) {
    const sendRef = useRef();
    const messageBox = useRef();

    const [state] = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [page, setPage] = useState(2);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const result = await get("/messages/" + patient.id, {page: 1});
            if (result.success) {
                setTotalItems(result.total);
                setMessages(messages => messages.concat(clearMessages(result.items, messages)));
                setLoading(false);
            } else {
                setLoading(false);
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 30000);
        return () => clearInterval(interval);       
    }, [patient.id]);   
    
    const renderMessages = () => {
        let jsx = [];

        if(messages && messages.length){
            messages.forEach((message) => {
                if(state.user.id === message.user.id || (state.role === 'Administrateur' && message.user.id === patient.nutritionist.id)){
                    jsx.push(
                        <div className="flex flex-row py-2 justify-end" key={message.id}>
                            <div className="block flex-col bg-green-200 p-2 rounded-t-lg rounded-bl-lg max-w-3/4">
                                <span className="block">{message.content}</span>
                                <span className="text-xs text-gray-600 font-bold">{formatDate(message.created_at, true)}</span>
                            </div>
                        </div>
                    )
                } else {
                    jsx.push(
                        <div className="flex flex-row py-2 justify-start" key={message.id}>
                            <div className="block flex-col bg-blue-200 p-2 rounded-t-lg rounded-br-lg max-w-3/4">
                                <span className="block">{message.content}</span>
                                <span className="text-xs text-gray-600 font-bold">De : {message.user.firstname} {message.user.lastname} ( {formatDate(message.created_at, true)} )</span>
                            </div>
                        </div>
                    )
                }
            })
        }

        return jsx;
    }

    const fields = {
        message: {
            label: "",
            type: "textarea",
            placeholder: "Envoyer un message",
            required: false,
            validations: [],
        },
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let params = {
            content: newMessage
        };

        sendRef.current.resetValue('message');
        const result = await post("/message/" + patient.id, params);

        if(result.success){
            messages.push(result.newMessage);
            let clearedMessages = clearMessages([result.newMessage], messages);
            let newMessages = messages.concat(clearedMessages);
            setPageNumber(newMessages);
            setMessages(newMessages);
        }
    }

    const handleScroll = async (e) => {
        if(e.target.scrollTop < 5 && totalItems > messages.length){
            const result = await get("/messages/" + patient.id, {page});
            if (result.success) {
                let clearedMessages = clearMessages(result.items, messages);
                let loadMessages = clearedMessages.concat(messages);
                setPageNumber(loadMessages);
                setMessages(loadMessages);
            }
        }
    }

    const setPageNumber = (newMessages) => {
        if(((newMessages.length / 10) * 10) % 30 === 0 ){
            setPage(Math.floor((newMessages.length / 10) * 10 ) / 30 + 1);
        }
    }

    const clearMessages = (newMessages, lastMessages) => {
        let deleteIndex = [];

        newMessages.forEach((message) => {
            if(lastMessages.some((m) => m.id === message.id)){
                deleteIndex.push(message.id); 
            }
        });

        deleteIndex.forEach(id => {
            let index = newMessages.findIndex(element => element.id === id);
            newMessages.splice(index, 1);
        })

        return newMessages.slice();
    }

    return (
        <Box className="w-full">
            <LoadingArea isLoading={loading}>
                <div className="flex flex-col p-8 h-chat-box">
                    <div className="flex justify-between mb-4">
                        <h3 className="text-2xl">Journal</h3>
                    </div>
                    <div ref={messageBox} onScroll={(e) => handleScroll(e)} className="block bg-gray-400 p-4 mb-4 justify-end overflow-y-auto overflow-x-hidden" style={{height: '300px'}}>
                        {renderMessages()}
                    </div>

                    {(state.role === "Nutritionniste" || state.role === "Coach" || state.role === "Manager")? (
                        <Form {...{ handleSubmit }} className="flex flex-col pt-4 w-full">
                            <FormFields 
                                fields={fields}
                                handleUpdate={(data) => setNewMessage(data['message'])}
                                defaultValues={[{message: newMessage}]}
                                ref={sendRef}
                            />
                            <Button type={'submit'} onPress={(e) => handleSubmit(e)}>Envoyer</Button>
                        </Form> 
                    ) : null}
                </div>
            </LoadingArea>
        </Box>
    );
}

export default Messages;
