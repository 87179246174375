export default function formatDate(value, withHours = false) {
    if(!value){
        return "";
    }
    
    var date = new Date(value);
    var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
    var MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    var h = (date.getHours() + 1 < 10 ? "0" : "") + (date.getHours() + 1);
    var min = (date.getMinutes() + 1 < 10 ? "0" : "") + (date.getMinutes() + 1);
    var yyyy = date.getFullYear();

    // create the format you want
    var result = dd + "/" + MM + "/" + yyyy;

    if(withHours) {
        result += " à " + h + "h"+ min;
    }

    return result;
}
