import React, {useContext} from "react";

import { UserCreate } from "../../components";
import { UserContext } from '../../context/UserContext';

function PatientCreate() {
    const [ state ] = useContext(UserContext)
    let headerTitle = "Nouveau patient";

    let messages = {
        success: "Patient créé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Créer"
    }

    if(state.role === 'Coach' || state.role === 'Manager'){
        headerTitle = "Nouveau client";

        messages = {
            ...messages,
            success: "Client créé !",
        }
    }
    const redirectUrl = "/patients";

    

    return (
        <UserCreate {...{headerTitle, redirectUrl, messages}} role="patient"/>
    );
}

export default PatientCreate;
