import React from 'react';

function MenuItemIndicator({ color = "red" }) {
    const styles = ['rounded-full w-3 h-3 block shadow'];

    styles.push('bg-' + color + '-700');

    return (
        <span className={styles.join(' ')}></span>    
    )
}

export default MenuItemIndicator;