import React, {useState, useEffect} from 'react';

import { FormFields } from './';
import {morphotypes} from '../constants/morphotypes';
import getFieldLabel from "../utilities/field-label";
import { get } from "../server/api";


function RightSidebar({ children, data, setData }) {
    const [metabolicProblems, setMetabolicProblems] = useState([]);

    useEffect(() => {
        //setLoading(true);
        const fetchData = async () => {
            const result = await get("/metabolic-problems?all=1");
            if (result.success) {
                let newProblems = [];
                result.items.forEach(element => {
                    newProblems.push({
                        label: element.name,
                        value: element.alias
                    })
                });
                setMetabolicProblems(newProblems);
                //setLoading(false);
            } else {
                //setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fields = {
        metabolicProblem: {
            label: "Problème métabolique",
            type: "multiselect",
            required: false,
            validations: [],
            choices: metabolicProblems,
        },
        morphotype: {
            label: "Morphotype",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        trend: {
            label: "Tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        subTrend: {
            label: "Sous-tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        height: {
            label: "Taille",
            type: "number",
            required: false,
            validations: []
        },
        /*womenChoice: {
            type: "multicheckbox",
            validations: [],
            choices:[{
                label: getFieldLabel("pregnant"),
                value: 'pregnant'
            }],
        },
        food: {
            label: "Profil alimentaire :",
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("carnivorous"),
                value: 'carnivorous'
            },{
                label: getFieldLabel("vegetarian"),
                value: 'vegetarian'
            }],
        },
        problems: {
            label: "Problèmes de santé :",
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("insulin_dependent_diabetes"),
                value: 'insulin_dependent_diabetes'
            },{
                label: getFieldLabel("non_insulinodependant_diabetes"),
                value: 'non_insulinodependant_diabetes'
            },{
                label: getFieldLabel("cardiac"),
                value: 'cardiac'
            },{
                label: getFieldLabel("hypertension"),
                value: 'hypertension'
            },{
                label: getFieldLabel("cholesterol"),
                value: 'cholesterol'
            },{
                label: getFieldLabel("gout"),
                value: 'gout'
            },{
                label: getFieldLabel("kidney_failure"),
                value: 'kidney_failure'
            },{
                label: getFieldLabel("sleep_apnea"),
                value: 'sleep_apnea'
            },{
                label: getFieldLabel("asthma"),
                value: 'asthma'
            },{
                label: getFieldLabel("gynecological"),
                value: 'gynecological'
            },{
                label: getFieldLabel("prostatic_problem"),
                value: 'prostatic_problem'
            },{
                label: getFieldLabel("infectious"),
                value: 'infectious'
            },{
                label: getFieldLabel("circulatory"),
                value: 'circulatory'
            },{
                label: getFieldLabel("sleep"),
                value: 'sleep'
            },{
                label: getFieldLabel("sleep_problem"),
                value: 'sleep_problem'
            },{
                label: getFieldLabel("nocturnal_awakening"),
                value: 'nocturnal_awakening'
            },{
                label: getFieldLabel("waking_up"),
                value: 'waking_up'
            },{
                label: getFieldLabel("after_meals"),
                value: 'after_meals'
            },{
                label: getFieldLabel("tired"),
                value: 'tired'
            }],
        },*/
    }

    return (
        <div className="flex flex-col bg-white h-full w-1/3 justify-between p-2">
            {children}
            <FormFields 
                fields={fields}
                defaultValues={data}
                handleUpdate={(data) => setData(data)}
            />
        </div>        
    )
}

export default RightSidebar;