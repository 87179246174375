import React from 'react';

export default function formatStatus(value) {
    if(value === "") {
        return value;
    }

    let color = '';
    let trad = '';
    switch (value) {
        case "incomplete":
            color = "red-600";
            trad = "BM";//"Bilan manquant";
            break;
        case "submitted":
            color = "red-600";
            trad = "A";//"Assignation et validation";
            break;
        case "pending":
            color = "red-600";
            trad = "DP";//"Demande de programme";
            break;
        case "processing":
            color = "orange-400";
            trad = "ECR";//"En cours de rédaction (Brouillon)";
            break;
        case "review":
            color = "yellow-400";
            trad = "EAV";//"En attente de Validation";
            break;
        case "complete":
            color = "green-400";
            trad = "PE";//"Programme envoyé";
            break;
        default:
            break;
    }

    return (
        <div className={"p-2 my-2 inline-block rounded-full bg-" + color}>
            <span className={"text-white"}>{trad}</span>
        </div>
        
    );
}