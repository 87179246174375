import React, {useState} from 'react';


function FileUpload({ children, name, type = 'file', value, setValue, required, error, placeholder }) {
    if(!value){
        value = []
    }

    let [ files, setFiles ] = useState(value);

    const onChangeHandler = (e) => {
        e.preventDefault()

        let reader = new FileReader();
        let newFile = e.target.files[0];

        reader.onloadend = () => {
            let addFiles =  [...files]
            addFiles.push(newFile);
            setFiles(addFiles);
            setValue(addFiles);
        }

        reader.readAsDataURL(newFile)
    }

    const deleteFile = (index) => {
        files.splice(index, 1);
        setFiles(files);
        setValue(files);
    }

    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <input id={"select-" + name} name={name} type={type} placeholder={placeholder} value={!files.length ? "" : files[files.length - 1].name} className="bg-gray-400 w-full py-2 px-4 rounded-lg" onChange={(e) => onChangeHandler(e)}/>  
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
            <div className="flex flex-col">
                {files.map((element, i) => 
                    <div>
                        <span key={i}>{element.name}</span>
                        <span onClick={() => deleteFile(i)}>X</span>
                    </div>
                    
                )}
            </div>
        </div>
    )
    
    
}

export default FileUpload;