import React, { useState } from "react";

import { Box, LoadingArea } from "../../common";
import { Button, FileInput } from "../../components";
import { FormTitle } from '../';

import { DOMAIN } from "../../constants/config";

import { post } from "../../server/api";
import getFieldLabel from "../../utilities/field-label";

function FormPicture({ patientId, documents, setDocuments, status }) {
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem("authToken");

    let url = "/monitoring-sheet/upload";
    if (patientId) {
        url += "/" + patientId;
    }

    const deleteDocument = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        const result = await post(
            "/monitoring-sheet/document/delete/" + documents.photo.id
        );
        
        if (result && result.success) {
            setDocuments({ photo: null });
        }

        setLoading(false);
    };

    const onChange = async (files) => {
        const body = new FormData();
        body.append("file", files[0]);
        body.append("type", "photo");
        setLoading(true);
        const result = await post(url, body, true);

        if (result && result.success) {
            setDocuments({ photo: result.data });
        }
        setLoading(false);
    };

    const renderPhoto = () => {
        if (!documents.photo) {
            return null;
        }
        let url = DOMAIN + "/document/" + token + "/" + documents.photo.id;
        return (
            <div className="relative flex flex-col">
                <img src={url} alt="" width="500"/>
                {status !== 'submitted' && status !== 'pending' && status !== 'processing'? (
                    <Button
                        skin='back'
                        onPress={(e) => deleteDocument(e)}
                        className="mr-0 ml-auto mt-4"
                    >
                        Supprimer
                    </Button>
                ) : null}
            </div>
        );
    };

    
    return (
        <div className="w-full p-3">
            <LoadingArea isLoading={loading}>
                <Box>
                    <FormTitle title="Photo" icon="portrait" color="black"/>
                    <div className="px-8 pb-8">
                    { status !== 'submitted' && status !== 'pending' && status !== 'processing' ? (
                        <p className="italic mb-4">
                            Vous pouvez joindre à votre fiche une photo de vous
                        </p>
                    ) : null}
                        {documents.photo ? (
                            renderPhoto()
                        ) : status !== 'submitted' && status !== 'pending' && status !== 'processing' ? (
                            <FileInput
                                onChange={onChange}
                                accept="image/*"
                                multiple={false}
                                label="Cliquez ici pour selectionner une image (png et jpg).."
                            />
                        ) : null}
                    </div>
                </Box>
            </LoadingArea>
        </div>
    );
}
export default FormPicture;
