import React from 'react';

function MenuSeparator({ children }) {
    return (
        <li className="mb-1">
            <span className="py-2 px-4 text-gray-400 uppercase text-sm block">
                {children}
            </span>
        </li>        
    )
}

export default MenuSeparator;