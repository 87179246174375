import React, {useState, useRef, useEffect} from "react";
import JoditEditor from "jodit-react";

import { PopupMorphotypes, FormFields, Tab, FormatContent } from "./";
import { Box } from "../common";
import {morphotypes} from '../constants/morphotypes';
import { get } from "../server/api";

function Editor({content, setContent, sheetButton, noteButton, patientId, gender}) {

    const editor = useRef();
    const sheetRef = useRef();
    const noteRef = useRef();

    const [ searchNote, setSearchNote ] = useState('');
    const [ data, setSearchSheet] = useState('');
    const [metabolicProblems, setMetabolicProblems] = useState([]);

    useEffect(() => {
        //setLoading(true);
        const fetchData = async () => {

            let url  = '/metabolic-problems?all=1';
            if(gender){
                url = url + '&gender=' + gender;
            }

            const result = await get(url);
            if (result.success) {
                let newProblems = [];
                result.items.forEach(element => {
                    newProblems.push({
                        label: element.name,
                        value: element.alias
                    })
                });
                setMetabolicProblems(newProblems);
                //setLoading(false);
            } else {
                //setLoading(false);
            }
        };

        fetchData();
    }, []);

    const config = {
        cleanHtml: {
            "timeout": 300,
            "removeEmptyElements": true,
            "fillEmptyParagraph": true,
            "replaceNBSP": true,
            "replaceOldTags": {
                "i": "em",
                "b": "strong"
            },
            "allowTags": false,
            "denyTags": false
        },
        i18n: 'fr',
        readonly: false,
        toolbarAdaptive : false,
        buttons: [
            'bold',
            'underline',
            'brush',
            'paragraph',
            'italic', '|',
            'ul',
            'ol', '|',
            'fontsize','|',
            'paste', '|',
            'source',
        ],
        placeholder: '',
        events:{},
        height: '600',
        askBeforePasteFromWord: false, 
        askBeforePasteHTML : false, 
        showCharsCounter: false,
        showWordsCounter: false,
        showPlaceholder : false,
        showXPathInStatusbar: false,
        defaultActionOnPaste: "insert_as_html",
        autofocus: false,
    }

    if(sheetButton){
        config.buttons.push(
            {
                name: 'Morphotypes',
                exec: function () {
                    sheetRef.current.toggle(true);
                }
            }
        )
    }

    if(noteButton){
        config.buttons.push(
            {
                name: 'Notes',
                exec: function () {
                    noteRef.current.toggle(true);
                }
            }
        )
    }

    const copy = (action, element) =>{
        let textField = document.createElement('textarea') ;
        textField.innerText = element.content ;
        document.body.appendChild(textField) ;
        textField.select() ;
        document.execCommand('copy') ;
        textField.remove();

        sheetRef.current.toggle(false);
        noteRef.current.toggle(false);
    }

    const setFetchUrl = () => {
        let url = '/sheets';
        let search = false;
        
        if(data.hasOwnProperty('metabolicProblem') && data['metabolicProblem']){
            if(!search){
                url += '?';
                search = true;
            }
            url += 'metabolic_problem='+data['metabolicProblem'];
        }

        if(data.hasOwnProperty('morphotype') && data['morphotype']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'morphotype='+data['morphotype'];
        }

        if(data.hasOwnProperty('trend') && data['trend']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'trend='+data['trend'];
        }

        if(data.hasOwnProperty('subTrend') && data['subTrend']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'sub_trend='+data['subTrend'];
        }

        if(data.hasOwnProperty('height') && data['height']){
            if(!search){
                url += '?';
                search = true;
            } else {
                url += '&';
            }

            url += 'height='+data['height'];
        }

        return url;
    }

    const onBlur = (newContent) => {
        setContent(newContent)
    }

    const renderContent = (content) => {
        return <FormatContent {...{content}} />
    }

    //const suggest = patientId ? true : false;
    let fetchUrl = setFetchUrl();//'/sheets?suggest='+suggest+'&search=' + searchSheet;
    /*if(suggest){
        fetchUrl += +'&patient_id='+patientId;
    }*/
    
    const sheet = {
        headers:[
            {
                name: "title",
                label : "Titre"
            },
            {
                name: "height",
                label : "Taille"
            },
        ],
        actions:[
            {
                label: "Copier",
                name: "sheet",
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des fiches.",
        no_results: "Vous n'avez pas encore de fiches", 
        redirect: "",
        redirectButton: "",
        fetchUrl,
        handleAction: copy
    };

    const fieldsSheet = {
        /*search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },*/
        metabolicProblem: {
            label: "Problème métabolique",
            type: "multiselect",
            required: false,
            validations: [],
            choices: metabolicProblems,
        },
        morphotype: {
            label: "Morphotype",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        trend: {
            label: "Tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        subTrend: {
            label: "Sous-tendance",
            type: "select",
            required: false,
            validations: [],
            choices: morphotypes,
        },
        height: {
            label: "Taille",
            type: "number",
            required: false,
            validations: []
        },
    }

    const note = {
        headers:[
            {
                name: "title",
                label : "Titre"
            },
            {
                name: "short_content",
                label : "Contenu",
                render: renderContent

            },
        ],
        actions:[
            {
                label: "Copier",
                name: "note",
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des notes.",
        no_results: "Vous n'avez pas encore de notes", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/notes?search=' + searchNote,
        handleAction: copy
    };

    const fieldsNote = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <>
            
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                onBlur={newContent => onBlur(newContent)}
            />
            <PopupMorphotypes ref={sheetRef} title="Morphotypes" closeButton="Fermer" className="w-full box-border p-4">
                    <Box className="p-4 mb-2 flex justify-around">
                        <FormFields 
                            fields={fieldsSheet}
                            handleUpdate={(data) => setSearchSheet(data)}
                        />
                    </Box>
                    <Tab config={sheet}/>
            </PopupMorphotypes>
            <PopupMorphotypes ref={noteRef} title="Notes" closeButton="Fermer" className="flex flex-col"> 
                <FormFields 
                    fields={fieldsNote}
                    handleUpdate={(data) => setSearchNote(data['search'])}
                />
                <Tab config={note}/>
            </PopupMorphotypes>
        </>
    );
}

export default Editor;