import React from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';

import getFieldLabel from "../../utilities/field-label";

function FormMeasure({ children, data, setData }) {
    const fields = {
        height: {
            label: getFieldLabel("height"),
            type: "number",
            required: true,
            validations: [{ rule: "required" }]
        },
        weight: {
            label: getFieldLabel("weight"),
            type: "number",
            required: true,
            validations: [{ rule: "required" }]
        },
        handleWidth: {
            label: getFieldLabel("handleWidth"),
            type: "number",
            max: 9,
            required: true,
            validations: [{ rule: "required" }, { rule: "handle_width" }]
        },
        chestSize: {
            label: getFieldLabel("chestSize"),
            type: "number",
            required: true,
            validations: [{ rule: "required" }]
        },
        waistSize: {
            label: getFieldLabel("waistSize"),
            type: "number",
            required: true,
            validations: [{ rule: "required" }]
        },
        hipCircumference: {
            label: getFieldLabel("hipCircumference"),
            type: "number",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    return (
        <div className="w-2/3 p-3">
            <Box>
                <FormTitle title="Mesures" icon="height" color="black"/>
                <div className="px-8 pt-2">
                    <FormFields
                        fields={fields}
                        defaultValues={data}
                        handleUpdate={(data) => setData(data)}
                    />
                </div>
                {children}
            </Box>
        </div> 
    )
}

export default FormMeasure;