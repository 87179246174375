import React, { useEffect, useState, useContext } from "react";
import { LoadingArea, Box } from "../../common";
import { UserContext } from "../../context/UserContext";

import ProgramCard from "./ProgramCard";

import { get } from "../../server/api";

import getStateColor from "../../utilities/state-color";

function Programs({ id, hasPrograms, onListSet }) {
    const [state] = useContext(UserContext);

    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const result = await get("/programs/" + id);
            if (result.success) {
                setLoading(false);
                setHistories(result.data);
                if(result.data.length){
                    if(result.data.length === 1){
                        if(result.data[0].state === 'draft' || result.data[0].state === 'incomplete'){
                            if(onListSet){
                                onListSet(true);
                            }
                        }
                    } else {
                        let created_date = new Date(result.data[0].created_at);
                        let six_months_date = new Date();
                        six_months_date.setMonth(created_date.getMonth()+6);
                        let now = new Date();
                        if(now >= six_months_date){
                            if(result.data[0].state === 'draft' || result.data[0].state === 'incomplete'){
                                if(onListSet){
                                    onListSet(true);
                                }
                            }
                        }
                    }
                    
                    if(hasPrograms){
                        hasPrograms(result.data.length);
                    } 
                } else {
                    if(onListSet){
                        onListSet(false);
                    }
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (!loading && !histories.length) {
        
        return (
            <Box className="py-8 px-8">
                <p className="text-center">
                    Aucun programme
                </p>
            </Box>
        );
    }

    const passReview = async ( historyId ) => {
        setLoading(true);

        const result = await get("/program/" + id +"/review/"+historyId);
        if (result.success) {
            setHistories(result.data);
            if(hasPrograms){
                hasPrograms(result.data.length);
            }
        }

        setLoading(false);
    }

    const passToCorrect = async ( historyId ) => {
        setLoading(true);

        const result = await get("/program/" + id +"/draft/"+historyId);
        if (result.success) {
            setHistories(result.data);
            if(hasPrograms){
                hasPrograms(result.data.length);
            }
        }

        setLoading(false);
    }

    return (
        <LoadingArea isLoading={loading}>
            <div className="py-8 px-8">
                {histories.length ? histories.map((history, i) => {
                    const styles = ["border-r-2 w-1/2 h-full"];
                    const stateColor = getStateColor(history.state);

                    styles.push("border-" + stateColor);
                    return (
                        <div key={history.id} className="flex mb-4">
                            <div>
                                {i !== 0 ? (
                                    <div className="mb-2 h-6">
                                        <div className={styles.join(" ")}></div>
                                        <div className="w-1/2"></div>
                                    </div>
                                ) : null}

                                <div
                                    className={
                                        "rounded-full w-12 h-12 bg-" +
                                        stateColor
                                    }
                                ></div>
                            </div>
                            <div className={"h-6 w-8 border-b-4 border-" + stateColor}></div>
                            <ProgramCard program={history} role={state.role} isLast={i === 0 || (i === 1 && histories[0].state === 'draft')} passReview={(id) => passReview(id)} passToCorrect={(id) => passToCorrect(id)}/>
                        </div>
                    );
                }): null}
            </div>
        </LoadingArea>
    );
}

export default Programs;
