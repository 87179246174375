import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Box, Message } from "../../common";

import { Button, ProgramData } from "../";

import formatDate from "../../utilities/date";
import { DOMAIN } from "../../constants/config";

function ProgramCard({ program, role, passReview, passToCorrect, isLast = false }) {
    let history = useHistory();
    const { id } = useParams();

    const [displayDetails, setDisplayDetails] = useState(false);
    const token = localStorage.getItem("authToken");

    let monitoringSheetUrl = '/monitoring-sheet';

    if(role === 'Manager' || role === 'Coach' || role === 'Administrateur'){
        monitoringSheetUrl = '/patient/'+id+'/monitoring-sheet'; 
    }

    const displayBool = role !== 'Nutritionniste' && role !== 'Administrateur';
    
    if (displayDetails) {
        return (
            <div className="flex flex-col">
                <Box className="w-full">
                    <div className="p-4">
                        <div className="mb-4 flex justify-end">
                            <Button
                                skin="gray"
                                onPress={() => setDisplayDetails(false)}
                            >
                                Fermer le détail
                            </Button>
                        </div>
                        <ProgramData program={program} />
                    </div>
                </Box>
                <span className="text-xs block mt-2">
                    Envoyée le {formatDate(program.submitted_at, true)}
                </span>
            </div>
        );
    }

    switch (program.state) {
        case "draft":
            return (
                <div className="flex flex-col">
                    <Box>
                        <div className="p-4">
                            <span className="text-xs block mb-2">
                                Envoyé le{" "}
                                {formatDate(program.submitted_at, true)}
                            </span>
                            <Message>
                                Formulaire en cours.
                            </Message>

                            <div className="mt-4 flex justify-end">
                            {displayBool ? (
                                <Button
                                    skin="gray"
                                    onPress={() => history.push(monitoringSheetUrl)}
                                >
                                    Reprendre le formulaire en cours
                                </Button>
                            ):null}
                            {role === 'Administrateur' ? (
                                <Button
                                    skin="gray"
                                    onPress={() => history.push(monitoringSheetUrl)}
                                >
                                    Effectuer une correction
                                </Button>
                            ):null}
                            </div>
                        </div>
                    </Box>
                </div>
            );
            break;
        
        case "incomplete":
            return (
                <div className="flex flex-col">
                    <Box>
                        <div className="p-4">
                            <span className="text-xs block mb-2">
                                Envoyé le{" "}
                                {formatDate(program.submitted_at, true)}
                            </span>
                            {displayBool ? (
                                <Message type="error">
                                    Bilan de santé manquant, il est nécessaire
                                    d'ajouter un bilan de santé à votre fiche pour
                                    qu'un nutritionniste traite votre dossier.
                                    { program.documents.prescription_base && displayBool ? (
                                    <Button
                                        skin="primary-outline"
                                        className="ml-2 text-black"
                                        onPress={() => window.open(DOMAIN + '/document/' + token + "/" + program.documents.prescription_base.id)}
                                    >
                                        Télécharger mon ordonnance pour un bilan biologique complet
                                    </Button>
                                ) : null}
                                </Message>
                            ) : (
                                <Message type="error">
                                    Bilan de santé en attente.
                                </Message>  
                            )}
                         

                            <div className="mt-4 flex justify-end">
                            {displayBool ? (
                                <Button
                                    skin="gray"
                                    className="mr-2"
                                    onPress={() => history.push(monitoringSheetUrl)}
                                >
                                    Reprendre le formulaire en cours
                                </Button>
                            ): null}

                                <Button
                                    skin="gray"
                                    onPress={() => setDisplayDetails(true)}
                                >
                                    Afficher la fiche
                                </Button>
                            </div>
                        </div>
                    </Box>
                </div>
            );
            break;
        
        case "processing":
            return (
                <div className="flex flex-col">
                    <Box>
                        <div className="p-4">
                            {program.nutritionist ? (
                                <p className="block mb-2">
                                    Nutritionniste :
                                    <span className="text-bold">
                                        {program.nutritionist.firstname}{" "}
                                        {program.nutritionist.lastname}
                                    </span>
                                </p>
                            ) : null}
                            {displayBool ? (
                                <Message type="info">
                                    Votre fiche est en cours d'étude
                                </Message>
                            ) : (
                                <Message type="info">
                                    Programme en cours de rédaction
                                </Message>
                            )}                            

                            <div className="mt-4 flex justify-end">
                                { program.documents.prescription && displayBool? (
                                    <Button
                                        skin="gray"
                                        className="mr-2"
                                        onPress={() => window.open(DOMAIN + '/document/' + token + "/" + program.documents.prescription.id)}
                                    >
                                        Télécharger mon ordonnance
                                    </Button>
                                ) : null}
                                { role === 'Administrateur'? (
                                    <Button
                                        skin="gray"
                                        className="mr-2"
                                        onPress={() => passToCorrect(program.id)}
                                    >
                                        Corriger
                                    </Button>
                                ) : null}
                                <Button
                                    skin="gray"
                                    onPress={() => setDisplayDetails(true)}
                                >
                                    Afficher la fiche
                                </Button>
                                
                            </div>
                        </div>
                    </Box>
                    <span className="text-xs block mt-2">
                        Envoyée le {formatDate(program.submitted_at, true)}
                    </span>
                </div>
            );
            break;

        default:
            return (
                <div className="flex flex-col">
                    <Box>
                        <div className="p-4">
                            {program.nutritionist ? (
                                <p className="block mb-2">
                                    Nutritionniste :
                                    <span className="text-bold">
                                        {program.nutritionist.firstname}{" "}
                                        {program.nutritionist.lastname}
                                    </span>
                                </p>
                            ) : null}

                            {displayBool ? (
                                <Message type="success">
                                    Votre programme est disponible.
                                </Message>
                            ) : (
                                <Message type="success">
                                    Programme rédigé.
                                </Message>
                            )}  

                            

                            <div className="mt-4 flex justify-end">
                                {displayBool ? (
                                    <Button
                                        skin="default"
                                        className="mr-2"
                                        onPress={() => window.open(DOMAIN + '/document/' + token + "/" + program.documents.program.id)}
                                    >
                                        Télécharger mon programme
                                    </Button>
                                ) : null}
                                
                                { program.documents.prescription && displayBool ? (
                                    <Button
                                        skin="gray"
                                        className="mr-2"
                                        onPress={() => window.open(DOMAIN + '/document/' + token + "/" + program.documents.prescription.id)}
                                    >
                                        Télécharger mon ordonnance
                                    </Button>
                                ) : null}

                                { role === 'Administrateur' && isLast ? (
                                    <Button
                                        skin="gray"
                                        className="mr-2"
                                        onPress={() => passReview(program.id)}
                                    >
                                        Repasser le programme en validation
                                    </Button>
                                ) : null}

                                <Button
                                    skin="gray"
                                    onPress={() => setDisplayDetails(true)}
                                >
                                    Afficher la fiche
                                </Button>
                            </div>
                        </div>
                    </Box>
                    <span className="text-xs block mt-2">
                        Envoyée le {formatDate(program.submitted_at, true)}
                    </span>
                </div>
            );
            break;
    }
}

export default ProgramCard;
