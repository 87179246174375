import React, { useContext,} from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

import { PatientContext } from "../../context/PatientContext";
import { UserContext } from "../../context/UserContext";

function FormHealthProblems({ children, data, setData }) {
    const [ state ] = useContext(UserContext);
    const [ patientState ] = useContext(PatientContext);

    let fields = {
        diseases: {
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("insulin_dependent_diabetes"),
                value: 'insulin_dependent_diabetes'
            },{
                label: getFieldLabel("non_insulinodependant_diabetes"),
                value: 'non_insulinodependant_diabetes'
            },{
                label: getFieldLabel("cardiac"),
                value: 'cardiac'
            },{
                label: getFieldLabel("hypertension"),
                value: 'hypertension'
            },{
                label: getFieldLabel("cholesterol"),
                value: 'cholesterol'
            },{
                label: getFieldLabel("gout"),
                value: 'gout'
            },{
                label: getFieldLabel("kidney_failure"),
                value: 'kidney_failure'
            },{
                label: getFieldLabel("asthma"),
                value: 'asthma'
            },{
                label: getFieldLabel("sleep_apnea"),
                value: 'sleep_apnea'
            },{
                label: getFieldLabel("circulatory"),
                value: 'circulatory'
            },{
                label: getFieldLabel("sleep"),
                value: 'sleep'
            },{
                label: getFieldLabel("sleep_problem"),
                value: 'sleep_problem'
            },{
                label: getFieldLabel("nocturnal_awakening"),
                value: 'nocturnal_awakening'
            },{
                label: getFieldLabel("waking_up"),
                value: 'waking_up'
            },{
                label: getFieldLabel("after_meals"),
                value: 'after_meals'
            },{
                label: getFieldLabel("tired"),
                value: 'tired'
            }],
        }
    }

    if(state.role === 'Patient'){
        if(state.user.gender === 'femme' || state.user.gender === 'fille'){
            fields.diseases.choices.push(
                {
                    label: getFieldLabel("gynecological"),
                    value: 'gynecological'
                }
            );
        } else {
            fields.diseases.choices.push(
                {
                    label: getFieldLabel("prostatic_problem"),
                    value: 'prostatic_problem'
                }
            );
        }
    } else {
        if(patientState.gender === 'femme' || patientState.gender === 'fille'){
            fields.diseases.choices.push(
                {
                    label: getFieldLabel("gynecological"),
                    value: 'gynecological'
                }
            );
        } else {
            fields.diseases.choices.push(
                {
                    label: getFieldLabel("prostatic_problem"),
                    value: 'prostatic_problem'
                }
            );
        }
    }

    fields.diseases.choices.push(
        {
            label: getFieldLabel("infectious"),
            value: 'infectious'
        }
    );

    if(data.hasOwnProperty('diseases') && data.diseases.some(entry => entry === 'infectious')){
        fields = {
            ...fields,
            organs: {
                label: "Organe(s) sur lequel porte l’infection",
                type: "text",
                required: false,
                validations: [],
            },
        }
    }

    return (
        <Box className="mb-4">
            <FormTitle title={getFieldLabel('healthProblems')} icon="heart-pulse" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                />
            </div>
        </Box>
    )
}

export default FormHealthProblems;