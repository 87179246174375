import React from 'react';

function HeaderMenuItem({ children, focus }) {

    let style = "mr-4 py-1 pr-4 border-b-4 border-white hover:border-blue-600 block cursor-pointer";

    if(focus){
        style += " border-blue-600";
    }

    return (
        <li className={style}>
            {children}
        </li>        
    )
}

export default HeaderMenuItem;