import React, {useContext} from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../context/UserContext';
import { Button } from '../components';


function Disconnect({ children }) {
    const [state, setState] = useContext(UserContext);

    const disconnect = () =>{
        localStorage.clear();
        setState(state => ({loggedIn: false}))
    }

    return (
        <Link to="/login">
            <Button width="full" className="rounded-t-none rounded-b-none" onPress={() => disconnect()}>Déconnexion</Button>
        </Link>  
    )
}

export default Disconnect;