import React, { useState, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom"

import { Button, Editor, Popup } from '../../components';
import { Header, Content, Box } from '../../common';

import { PatientContext } from '../../context/PatientContext';

import { post } from "../../server/api";

function PrescriptionEdit() {
    let history = useHistory();

    const prescriptionRef = useRef();

    const [ patientState ] = useContext(PatientContext);

    const [content, setContent] = useState('');

    let {id} =  useParams();

    const setPrescription = async () => {
        prescriptionRef.current.toggle(false);
        
        const result = await post("/prescription/" + id, JSON.stringify({type: 'custom', content}), true);
        if(result.success){
            history.push('/nutritionist/monitoring-sheet/'+id);
        }
    }
    
    const civility = patientState.gender === 'femme' || patientState.gender === 'fille' ? 'Mme' : 'M';

    return (
        <>
            <Popup ref={prescriptionRef} title="Confirmation" closeButton="Annuler" className="flex flex-col">
                <p className="mb-4">Êtes vous sur de vouloir envoyer cette ordonnance ?</p>
                <Button className="mb-2" onPress={() => setPrescription()}>Confirmer l'envoi</Button>
            </Popup>
            <Header title={"Ordonnance pour "+civility+" "+patientState.firstname+" "+patientState.lastname}>
                <Button skin='back' className="mr-2" onPress={() => history.push('/nutritionist/monitoring-sheet/'+id)}>Retour</Button>
                <Button onPress={() => prescriptionRef.current.toggle(true)}>Envoyer</Button>
            </Header>
            <Content>
                <Box className={'p-4'}>
                    <Editor {...{content, setContent}}/>
                </Box>
            </Content>
        </>
    );
}

export default PrescriptionEdit;