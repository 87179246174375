import React from "react";
import { useLocation } from "react-router-dom";

import {
    FunctionalForm
} from "../../components";
import { LayoutCenter, Header, Content } from "../../common";

function InstitutCreate() {
    let location = useLocation();

    const headerTitle = "Nouvel Institut";

    const saveUrl = location.pathname;
    const redirectUrl = "/instituts";

    const fields = {
        name: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        address: {
            label: "Adresse",
            type: "textarea",
            required: true,
            validations: [{ rule: "required" }]
        },
        city: {
            label: "Ville",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        postcode: {
            label: "Code postal",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    const messages = {
        success: "Institut créé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Créer"
    }

    const defaultValues = {
        active: true,
        activities: ['cinema']
    }

    return (
        <>
            <Header title={headerTitle} />
            <Content>
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="3xl" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={defaultValues}
                        messages={messages}
                        />
                </LayoutCenter>
            </Content>
        </>
    );
}

export default InstitutCreate;
