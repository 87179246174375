import React from 'react';

import DefaultField from './DefaultField';

function EmailField(props) {
    return (
        <DefaultField type="email" { ... props} />
    )
}

export default EmailField;