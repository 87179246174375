import React, { useState } from "react";

import { Box, LoadingArea } from "../../common";
import { Button, FileInput, FormFields } from "../../components";
import { FormTitle } from '../';

import { post } from "../../server/api";
import getFieldLabel from "../../utilities/field-label";
import { DOMAIN } from "../../constants/config";

function FormEvaluation({ patientId, documents, setDocuments, mState, status }) {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(false);
    const token = localStorage.getItem("authToken");

    let url = "/monitoring-sheet/upload";
    if (patientId) {
        url += "/" + patientId;
    }

    const deleteDocument = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        const result = await post(
            "/monitoring-sheet/document/delete/" + documents.evaluation.id
        );

        if (result && result.success) {
            setDocuments({ evaluation: null });
        }

        setLoading(false);
    };

    const onChange = async (files) => {
        const body = new FormData();
        body.append("file", files[0]);
        body.append("type", "evaluation");
        body.append("password", password);
        setLoading(true);
        const result = await post(url, body, true);

        if (result && result.success) {
            setDocuments({ evaluation: result.data });
        }
        setLoading(false);
    };

    const renderEvaluation = () => {
        if (!documents.evaluation) {
            return null;
        }
        return (
            <div className="relative flex flex-col">
                <Button skin="link" className="ml-0 mr-auto" onPress={() => window.open(DOMAIN + "/document/" + token + "/" + documents.evaluation.id)}>
                    <strong>{documents.evaluation.name}</strong>
                </Button>
                {status !== 'submitted' && status !== 'pending' && status !== 'processing'? (
                    <Button
                        skin='back'
                        onPress={(e) => deleteDocument(e)}
                        className="mr-0 ml-auto mt-4"
                    >
                        Supprimer
                    </Button>
                ) : null}
                
            </div>
        );
    };

    const fields = {
        password: {
            label: "Mot de passe",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <div className="w-full p-3">
            <LoadingArea isLoading={loading}>
                <Box className={mState && mState === "incomplete" && !documents.evaluation ? "bg-red-600 text-white" : documents.evaluation ? "bg-green-600 text-white" : null}>
                    <FormTitle title={getFieldLabel('evaluation')} icon="paper" color={(mState && mState === "incomplete") || documents.evaluation ? "white" : "black"}/>
                    <div className="px-8 mb-4">
                        { !documents.evaluation ? (
                            <p className="italic mb-4">
                                - Si c'est votre première commande et que vous n'avez pas de bilan biologique complet transmettez nous ce formulaire en cliquant sur "envoyer".
                                <br/>
                                Une ordonnance vous sera transmise dans la rubrique "Mes programmes".
                                <br/>
                                - Si vous avez déjà votre bilan biologique complet, veuillez le rajouter.
                                <br/> 
                                <strong className="text-red-900">Attention !</strong> Si celui-ci comporte un mot de passe, merci de le renseigner <strong>avant d'ajouter le bilan.</strong>
                                <br/>
                                - Si ce n'est pas votre première commande et qu'une autre ordonnance vous a été transmise dans la rubrique "Mes programmes" lors de votre dernière commande, 
                                <br/>
                                veuillez ajouter le bilan correspondant pour que le nutritionniste puisse étudier votre dossier. 
                                <br/> 
                                <strong className="text-red-900">Attention !</strong> Si celui-ci comporte un mot de passe, merci de le renseigner <strong>avant d'ajouter le bilan.</strong>
                            </p>
                        ) : null }
                        { status !== 'submitted' && status !== 'pending' && status !== 'processing' && !documents.evaluation ? (
                            <FormFields 
                                fields={fields}
                                handleUpdate={(data) => setPassword(data['password'])}
                            />
                        ) : null}
                    </div>
                    <div className="px-8 pb-8">
                        {documents.evaluation ? (
                            renderEvaluation()
                        ) : status !== 'submitted' && status !== 'pending' && status !== 'processing' ? (
                            <FileInput
                                onChange={onChange}
                                accept="application/pdf"
                                multiple={false}
                                label="Cliquez ici pour choisir un PDF dans vos documents..."
                            />
                        ) : null}
                        
                    </div>
                </Box>
            </LoadingArea>
        </div>
    );
}

export default FormEvaluation;
