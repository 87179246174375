const isEmail = (v) => {
    return /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i.test(
        v
    );
}

const isPassword = (v) => {
        var counter = 0;
        const passwordMinLength = 8;
        const passwordMinCharacterSets = 2;

        if(v.length < passwordMinLength) {
            return false;
        }

        if (v.match(/\d+/)) {
            counter++;
        }

        if (v.match(/[a-z]+/)) {
            counter++;
        }

        if (v.match(/[A-Z]+/)) {
            counter++;
        }

        if (v.match(/[^a-zA-Z0-9]+/)) {
            counter++;
        }

        if (counter < passwordMinCharacterSets) {
            return false;
        }

        return true;
}

/*const isPhone = (v) => {
    return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(v);
}*/

const isEmptyNoTrim = (v) => {
    if (v && v !== "" && v.trim() !== "") {
        return true;
    }
    return false;
}

const getRuleMessage = (rule) => {
    switch (rule) {
        case 'required':
            return 'Ce champ est requis';
        case 'email': 
            return 'Le format de votre adresse email est incorrect';
        case 'phone': 
            return 'Le format de votre numéro de téléphone est incorrect';
        case 'password': 
            return 'Votre mot de passe doit contenir 8 caractères de 2 familles différentes : majuscule, minuscule, chiffre, caractère spécial';
        case 'confirm_password': 
            return 'La confirmation du mot de passe doit être identique au nouveau mot de passe';
        case 'mineur': 
            return 'Ce champ est requis';
        case 'mineur_check': 
            return 'Vous devez confirmer vos responsabilité concernant ce compte';
        case 'handle_width': 
            return 'La valeur ne doit pas dépasser 9 CM';
        default:
            return '';
    }
}

const isFieldValid = function(field, values, validations) {
    if(validations.hasOwnProperty(field)) {
        const rules = validations[field];
        const errors = [];
        rules.forEach((i) => {
            var message = i.hasOwnProperty('message') ? i.message : getRuleMessage(i.rule);
            var value = values.hasOwnProperty(field) ? values[field] : '';

            switch (i.rule) {
                case 'required':
                    if(!isEmptyNoTrim(value)) {
                        errors.push(message);
                    }                    
                    break;
                case 'email':
                    if(!isEmptyNoTrim(value) || !isEmail(value)) {
                        errors.push(message);
                    }                    
                    break;
                case 'phone':
                    if(!isEmptyNoTrim(value) /*|| !isPhone(value)*/) {
                        errors.push(message);
                    }                    
                    break;
                case 'password':              
                    if(!value || value === "") {
                        if(!rules.find((r) => r.rule === "required")) {
                               break;
                        }
                    }
                    if(!isPassword(value)) {
                        errors.push(message);
                    }
                    break;
                case 'confirm_password': 
                    if(values.new_password !== values.confirm_password) {
                        errors.push(message);
                    }                    
                    break;
                case 'mineur':
                    if((values.gender === 'fille' || values.gender === 'garcon') && !isEmptyNoTrim(value)) {
                        errors.push(message);
                    }  
                    break;
                case 'mineur_check':
                    if((values.gender === 'fille' || values.gender === 'garcon') && (!value.length || !values.certification.some(element => element === "certification"))){
                        errors.push(message);
                    }
                    break;
                case 'handle_width':
                    if(parseInt(value) > 9 ){
                        errors.push(message);
                    }
                    break;
                default:
                    break;
            }
        })

        if(errors.length > 0) {
            return errors;
        }
    }

    return true;
};

const getFieldError = (field, values, validations) => {
    const validity = isFieldValid(field, values, validations);
    var error = "";
    if (validity !== true) {
        error = Array.isArray(validity) ? validity.join(", ") : validity;
    }

    return error;
}


export default getFieldError;