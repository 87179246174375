import React from "react";

import { ProblemEdit } from "../../components";

function MetabolicProblemEdit() {

    const redirectUrl = "/metabolic-problems";

    const headerTitle = "Edition d'un problème metabolique";

    const messages = {
        success: "Problème metabolique mis à jour !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Mettre à jour",
    };

    return (
        <ProblemEdit {...{redirectUrl, headerTitle, messages}}/>
    );
}

export default MetabolicProblemEdit;
