import React, { useContext } from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

import { PatientContext } from "../../context/PatientContext";
import { UserContext } from "../../context/UserContext";

function FormWomen({ children, data, setData }) {
    const [ state ] = useContext(UserContext);
    const [ patientState ] = useContext(PatientContext);

    const fields = {
        childrenNumber: {
            label: getFieldLabel('childrenNumber'),
            type: "number",
            validations: []
        },
        women: {
            label: "",
            type: "multicheckbox",
            validations: [],
            choices:[],
        },
    }

    if(state.role === 'Patient'){
        if(state.user.gender === 'femme'){
            fields.women.choices.push(
                {
                    label: getFieldLabel("pregnant"),
                    value: 'pregnant'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("feeding_milk"),
                    value: 'feeding_milk'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("contraceptive"),
                    value: 'contraceptive'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("menopause"),
                    value: 'menopause'
                }
            );
        }
    } else {
        if(patientState.gender === 'femme'){
            fields.women.choices.push(
                {
                    label: getFieldLabel("pregnant"),
                    value: 'pregnant'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("feeding_milk"),
                    value: 'feeding_milk'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("contraceptive"),
                    value: 'contraceptive'
                }
            );
            fields.women.choices.push(
                {
                    label: getFieldLabel("menopause"),
                    value: 'menopause'
                }
            );
        }
    }

    return (
        <Box className="mb-4">
            <FormTitle title={getFieldLabel('motherhood')} icon="baby" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                />
            </div>
        </Box>
    )
}

export default FormWomen;