import React from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

function FormMedicalOther({ children, data, setData }) {
    const fields = {
        questions: {
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: 'Venez-vous de subir un choc émotionnel et/ou un stress ?',
                value: 'shock'
            },{
                label: 'Etes-vous actuellement en convalescence suite à un accident ou à une maladie ?',
                value: 'convalescence'
            },{
                label: 'Avez-vous déjà suivi un régime autre que celui que nous vous avons conseillé ?',
                value: 'diet'
            }],
        },
    }

    return (
        <Box className="mb-4">
            <FormTitle title="Autre" icon="heart-plus" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                />{" "}
            </div>
        </Box>
    )
}

export default FormMedicalOther;