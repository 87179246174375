export default function getFieldLabel(alias) {
    const labels = {
        measures: "Mesures",
        height: "Taille ( cm )",
        weight: "Poids ( kg )",
        handleWidth: "Largeur du poignet ( cm )",
        hipCircumference: "Tour de hanche ( cm )",
        waistSize: "Tour de taille ( cm )", 
        chestSize: "Tour de poitrine ( cm )", 
        activities: "Activités",
        job: "Métier",
        physicalActivity: "Activité physique extra professionnelle par semaine ( nombre d’heures )",
        allergies: "Allergies",
        allergy_drugs: "Médicaments",
        drugs: "Médicaments et compléments alimentaires",
        sun: "Soleil",
        food: "Alimentaires",
        others: "Autres",
        consumptions: "Consommations",
        allergies_consumptions: "Allergies et Consommations",
        alcohol: "Alcool",
        tobacco: "Tabac",
        evaluation: "Bilan biologique complet ou autre bilan",
        foodProfile: "Profil Alimentaire",
        carnivorous: "Carnivore",
        vegetarian: "Végétarien",
        vegan: "Végétalien",
        without_pork: "Sans porc",
        without_seafood: "Sans fruit de mer",
        alcohol_free: "Sans alcool",
        photo: "Photo",
        healthProblems: "Problèmes de santé",
        insulin_dependent_diabetes: "Diabète insulino-dépendant",
        non_insulinodependant_diabetes: "Diabète non insulino-dépendant",
        cardiac: "Pathologie cardiaque",
        hypertension: "Hypertension",
        cholesterol: "Cholestérol",
        gout: "Goutte",
        kidney_failure: "Insuffisances rénales",
        asthma: "Asthme",
        sleep_apnea: "Apnée du sommeil",
        gynecological: "Gynécologique",
        prostatic_problem: "Prostate",
        infectious: "Infection(s)",
        circulatory: "Insuffisance veineuse",
        sleep: "Insomnie",
        sleep_problem:"Problème endormissement",
        nocturnal_awakening:"Réveil nocturne",
        waking_up: "Fatigue au réveil",
        after_meals: "Fatigue après les repas",
        tired: "Fatigue toute la journée",
        familySituation: "Situation familiale",
        observations: "Observations éventuelles",
        single: "Célibataire",
        relationship: "En couple",
        prescriptions: "Ordonnances",
        shock: "Choc émotionnel / Stress",
        convalescence: "Convalescence suite à accident/maladie",
        diet: "Régime autre Chrono-Santé",
        childrenNumber: "Nombre d'enfants",
        pregnant: "Enceinte",
        feeding_milk: "Allaitement",
        contraceptive: "Contraceptif",
        menopause: "Ménopause",
        motherhood: "Maternité",
        organs: "Organes touchés par une infection",
        real: 'Mesures réelles',
        ideal: 'Mesures idéales',
        difference: 'Différences',
        foodsAllergies: 'Alliments',
        drugsAllergies: 'Médicaments',
        othersAllergies: 'Autres allergies',
        did: 'DID',
        dnid: 'DNID',
        water_retention: "Rétention d’eau",
    }

    if(labels.hasOwnProperty(alias)) {
        return labels[alias];
    }

    return alias;
}