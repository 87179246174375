import React from 'react';

function Column({ children }) {
    return (
        <div className="flex flex-col px-3 w-full">
            {children}
        </div>        
    )
}

export default Column;