import React, { useEffect, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from './components';

import {
    Sidebar,
    UserCard,
    UserCredits,
    Router,
    Nav,
    Disconnect,
    Screen,
} from "./common";

import { AdminNav, CoachNav, NutritionistNav, PatientNav } from "./user";

import { UserContext } from "./context/UserContext";
import { refreshToken, get } from "./server/api";
import getRole from "./utilities/roles";

function App() {
    const [state, setState] = useContext(UserContext);
    const [initialLoading, setInitialLoading] = useState(true);

    const savedRefreshToken = localStorage.getItem("refreshToken");

    useEffect(() => {
        async function fetchMyAPI() {
            let result = await refreshToken();

            if (!result.hasOwnProperty("error")) {
                localStorage.setItem("authToken", result.access_token);
                localStorage.setItem("refreshToken", result.refresh_token);
                localStorage.setItem("loggedIn", true);

                const profileResult = await get("/profile");

                if (profileResult.success) {
                    let user = profileResult.data;
                    localStorage.setItem("user", JSON.stringify(user));
                    let role = getRole(user.roles);
                    localStorage.setItem("role", role);
                    setState((state) => ({
                        ...state,
                        loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
                        user,
                        role,
                    }));
                } else {
                    localStorage.setItem("user", false);
                    localStorage.setItem("authToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("loggedIn", false);
                    setState((state) => ({
                        ...state,
                        loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
                        user: false,
                    }));
                }

                setInitialLoading(false);
            } else {
                localStorage.setItem("authToken", "");
                localStorage.setItem("refreshToken", "");
                localStorage.setItem("loggedIn", false);

                setInitialLoading(false);
            }
        }

        if (
            savedRefreshToken &&
            savedRefreshToken !== "" &&
            savedRefreshToken !== "null"
        ) {
            fetchMyAPI();
        } else {
            setInitialLoading(false);
        }
    }, []);

    if (initialLoading) {
        return (
            <div className="h-full flex justify-center items-center bg-gray-300">
                <Sidebar></Sidebar>
                <Screen></Screen>
            </div>
        );
    }

    const renderNav = (role) => {
        switch (role) {
            case "Administrateur":
                return <AdminNav user={state.user} />;

            case "Nutritionniste":
                return <NutritionistNav user={state.user} />;

            case "Coach":
                return <CoachNav user={state.user} />;

            case "Manager":
                return <CoachNav user={state.user} />;

            default:
                return <PatientNav user={state.user} />;
        }
    };

    return (
        <Router>
            {state.loggedIn ? (
                <Sidebar>
                    <div>
                        <UserCard />
                        {state.role === "Coach" || state.role === "Manager" ||state.role === "Patient" ? (
                            <UserCredits />
                        ) : null}
                        {renderNav(state.role)}
                    </div>
                    <div>
                        <Link to="/cgu">
                            <Button skin="link" width="full" className={'self-center'}>CONDITIONS GÉNÉRALES</Button>
                        </Link>  
                        <Disconnect />
                    </div>
                    
                </Sidebar>
            ) : null}
        </Router>
    );
}

export default App;
