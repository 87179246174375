import React from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

function FormAllergies({ children, data, setData }) {
    let fields = {
        allergies: {
            label: getFieldLabel("allergies"),
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("allergy_drugs"),
                value: 'allergy_drugs'
            },{
                label: getFieldLabel("sun"),
                value: 'sun'
            },{
                label: getFieldLabel("food"),
                value: 'food'
            },{
                label: getFieldLabel("others"),
                value: 'others'
            }],
        },
        
    }

    if(data.hasOwnProperty('allergies') && data.allergies.some(entry => entry === 'allergy_drugs')){
        fields = {
            ...fields,
            drugsAllergies: {
                label: "Medicament(s) au(x) quel(s) vous êtes alergique",
                type: "text",
                required: false,
                validations: [],
            },
        }
    }

    if(data.hasOwnProperty('allergies') && data.allergies.some(entry => entry === 'food')){
        fields = {
            ...fields,
            foodsAllergies: {
                label: "Aliment(s) au(x) quel(s) vous êtes alergique",
                type: "text",
                required: false,
                validations: [],
            },
        }
    }

    if(data.hasOwnProperty('allergies') && data.allergies.some(entry => entry === 'others')){
        fields = {
            ...fields,
            othersAllergies: {
                label: "Autre(s) élément(s) au(x) quel(s) vous êtes alergique",
                type: "text",
                required: false,
                validations: [],
            },
        }
    }


    fields = {
        ...fields,
        consumptions: {
            label: getFieldLabel("consumptions"),
            type: "multicheckbox",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("alcohol"),
                value: 'alcohol'
            },{
                label: getFieldLabel("tobacco"),
                value: 'tobacco'
            },],
        },
    }

    return (
        <Box className="mb-4">
            <FormTitle title="Allergies et Consommations" icon="cigar" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields 
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                />
            </div>
        </Box>
    )
}

export default FormAllergies;