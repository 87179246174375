import React, { useContext, useState, useEffect, useRef } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Tab, Button, FormFields, Popup, DateField } from "../components";
import { Header, Content, Box } from "../common";
import { post } from "../server/api";

import { UserContext } from "../context/UserContext";

import formatPrice from "../utilities/price";
import formatDate from "../utilities/date";

import { DOMAIN } from "../constants/config";

function Orders() {
    const history = useHistory();
    const headerTitle = "Historique des commandes";
    const token = localStorage.getItem("authToken");
    const downloadRef = useRef();

    const [ state ] = useContext(UserContext);
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    let { id, factureId } = useParams();

    let fetchUrl = "/order-history";

    if(search || id){
        fetchUrl += "?";
        if(search){
            fetchUrl += "search="+ search;
        }

        if(id){
            if(search){
                fetchUrl += "&";
            }
            fetchUrl += "institut_id="+ id;
        }
    }

    useEffect(() => {
        if(factureId){
            window.open(DOMAIN + "/order/" + token + "/" + factureId)
        }
    }, [token, factureId]);

    const formatStatus = (value) => {
        switch (value) {
            case "order_placed":
                return "En attente de confirmation";
            case "order_approved":
                return "Confirmée";

            default:
                return "";
        }
    };

    const handleAction = async(action, data) => {
        switch (action) {
            case 'invoice':
                if(data.order_documents[data.order_documents.length - 1]){
                    window.open(DOMAIN + "/order/" + token + "/" + data.order_documents[data.order_documents.length - 1].id);
                }
                break;
            case 'validate':
                const result = await post('/manual-confirmation/'+data.id);
                if(result.hasOwnProperty('success')){
                    return result;
                } else {
                    return { success : false }
                }
                break;
            default:
                break;
        }
    };

    const config = {
        headers: [
            {
                name: "reference",
                label: "Référence",
            },
            {
                name: "total_incl_tax",
                label: "Montant",
                render: formatPrice,
            },
            {
                name: "status",
                label: "Statut",
                render: formatStatus,
            },
            {
                name: "created_at",
                label: "Date",
                render: formatDate,
            },
        ],
        actions: [
            {
                label: "Facture",
                name: "invoice",
                active: true
            },
            {
                label: "Valider",
                name: "validate",
                active: true
            },
        ],
        handleAction: handleAction,
        error:
            "Une erreur est survenue lors de la récupération de la liste des commandes.",
        no_results: "Aucune commande trouvée",
        redirect: "",
        redirectButton: "",
        fetchUrl,
    };

    if(state.role === 'Administrateur' || (state.role === 'Manager' && id)){
        config.headers = [
            {
                name: "firstname",
                label: "Prénom",
            },
            {
                name: "lastname",
                label: "Nom",
            },
            ...config.headers,
        ];
    }

    const fields = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    const download = () => {
        window.open(DOMAIN+'/orders/'+token+'?start='+startDate.replace(/\//gi, '-')+'&end='+endDate.replace(/\//gi, '-'))
    }

    return (
        <>
            <Popup ref={downloadRef} title="Téléchargement des factures" closeButton="Annuler" className="flex flex-col justify-center">
                <span>Renseignez la période pour les factures à télécharger.</span>
                <DateField value={startDate} setValue={setStartDate}>
                    Date de début
                </DateField>
                <DateField value={endDate} setValue={setEndDate}>
                    Date de fin
                </DateField>
                <Button onPress={() => download()}>Confirmer</Button>
            </Popup>
            <Header title={headerTitle}>
                {state.role !== 'Administrateur' ?(
                    <Button onPress={() => history.push("/checkout")} skin="primary-outline">
                        Acheter des crédits
                    </Button>
                ):(
                    <Button onPress={() => downloadRef.current.toggle(true)} skin="primary-outline">
                        Télécharger les factures du mois en cours
                    </Button>
                )}
            </Header>
            <Content>
                <Box className="p-4 mb-2">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setSearch(data['search'])}
                    />
                </Box>
                <Tab {...{ config }} />
            </Content>
        </>
    );
}

export default Orders;
