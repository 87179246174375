import React from 'react';

function Header({ children, title, renderButtons }) {

    let style = "bg-white w-full  flex justify-between shadow";

    return (
        <div className={style}>
            <div className='flex flex-col '>
                {title && title !== "" ? <h1 className="text-3xl font-bold px-4 py-2">{title}</h1> : null}                

                {renderButtons ? renderButtons() : null}
            </div>
            <div className='flex w-1/2 justify-end px-4 py-2'>
                {children}
            </div>
        </div>        
    )
}

export default Header;