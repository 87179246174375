import React from "react";

import { UserCreate } from "../../components";

function CoachCreate() {
    const headerTitle = "Nouveau coach";
    const redirectUrl = "/coaches";

    const messages = {
        success: "Coach créé !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Créer"
    }

    const defaultValues = {
        active: true,
        activities: ['cinema']
    }

    return (
        <UserCreate {...{headerTitle, redirectUrl, messages, defaultValues}} role="coach"/>
    );
}

export default CoachCreate;
