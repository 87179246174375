import React, { useEffect } from "react";
import { useParams, Redirect } from 'react-router-dom'
import { DOMAIN } from "../constants/config";

function AccessDocument() {
    const {id} = useParams();
    const token = localStorage.getItem("authToken");

    useEffect(() => {
        window.open(DOMAIN + "/document/" + token + "/" + id)
    }, [token, id]);

    return (
        <Redirect to={"/dashboard"}/>
    )
}

export default AccessDocument;
