import React, { useContext } from "react";
import { Link, Redirect, useParams } from 'react-router-dom'

import { FunctionalForm, Button } from "../components";
import { UserContext } from "../context/UserContext";
import { LayoutCenter, Content, Box, LayoutBetween} from "../common";
import { get, getToken } from "../server/api";
import getRole from "../utilities/roles";
import logo from "../assets/images/LOGO-CHRONO-NUTRITION.png"

function Login() {

    const [state, setState] = useContext(UserContext);
    const {redirectPage, param} = useParams();

    const saveUrl = null;
    const redirectUrl = !redirectPage ?  "/dashboard" : param ? "/"+redirectPage+"/"+param : "/"+redirectPage;

    const DOMAIN = window.location.hostname;
    const display = DOMAIN === "la-chrono-nutrition.com";

    const fields = {
        username: {
            label: "Email",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        password: {
            label: "Mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "required" }]
        }
    }

    const messages = {
        success: 'Connexion réussie !',
        error: 'Vos identifiants sont incorrects',
        button: 'Connexion'
    }   

    const submit = async (values) => {
        
        const result = await getToken(values.username, values.password);

        if (result.hasOwnProperty("error")) {
            localStorage.clear();
            return { success: false };
        }

        localStorage.setItem("authToken", result.access_token);
        localStorage.setItem("refreshToken", result.refresh_token);
        localStorage.setItem("loggedIn", true);

        const profileResult = await get("/profile");

        if (profileResult.success) {
            let user = profileResult.data;
            localStorage.setItem("user", JSON.stringify(user));
            let role = getRole(user.roles);
            localStorage.setItem('role', role);
            setState((state) => ({
                ...state,
                loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
                user,
                role
            }));

            return { success: true };
        } else {
            localStorage.clear();
            setState((state) => ({
                ...state,
                loggedIn: false,
                user: false,
            }));

            return { success: false };
        }
    };

    if(state.loggedIn) {
        return <Redirect to={!redirectPage ?  "/dashboard" : param ? "/"+redirectPage+"/"+param : "/"+redirectPage}/>
    }

    return (
        <>
            <Content>
                {display ? (
                    <>
                        <LayoutBetween>
                            <Button width='auto' skin='back' onPress={() => window.location.assign("https://www.la-chrononutrition.com/index.php")}>{"< Retour au site"}</Button>
                        </LayoutBetween>
                        <LayoutCenter>
                            <img src={logo} alt="logo-chrono-nutrition"/>
                        </LayoutCenter>
                        <LayoutCenter>
                            <Box>
                                <div className="flex flex-col p-4">
                                    <span>Bonjour et Bienvenu !</span>
                                    <span>
                                        Pour entrer sur votre espace chrono si vous avez déjà un compte entrez votre email et votre mot de passe habituel. 
                                    </span>
                                    <span>
                                        Si vous avez oublié votre mot de passe cliquez sur Mot de passe oublié !
                                    </span>
                                    <span>
                                        Et si vous êtes nouveau, cliquez sur commandez une fiche conseil et suivez les indications !
                                    </span>
                                    <span>
                                        Nous vous remercions d'avoir choisi chrono-santé !
                                    </span>
                                </div>
                                
                            </Box>
                        </LayoutCenter>
                    </>
                ) : null}
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="md" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={null}
                        messages={messages}
                        handleSubmit={submit}
                    />
                </LayoutCenter>
                <LayoutCenter>
                    
                    <Box className="p-4">
                        <Link to="/password/forgot">
                            <Button skin="link" width='auto' className="mr-24">Mot de passe oublié ?</Button>
                        </Link>
                        {display ? (
                            <Link to="/register">
                                <Button skin="primary-outline" width='auto'>Commander une fiche conseils</Button>
                            </Link>
                        ) : null}
                    </Box>
                </LayoutCenter>
            </Content>
        </>
    );
}

export default Login;
