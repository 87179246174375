import React, {useEffect, useState, useRef} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { Header, Content, Box, Message, LoadingArea } from "../../common";
import { Button, Popup } from "../../components";
import { get } from "../../server/api";

function NoteView() {
    let location = useLocation();
    let history = useHistory();
    let removeRef = useRef();


    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const result = await get(location.pathname);
            if (result.success) {
                setContent(result.data.content);
                setTitle(result.data.title);
            } else {
                setErrorMsg('Une erreur c\'est produite lors du chargement de la note');
                console.log(result);
            }
            setLoading(false);
        };

        fetchData();
    }, [])

    const remove = async () => {
        removeRef.current.toggle(false);
        const result = await get(location.pathname+"/delete");

        if(result.success){
            history.push("/notes");
        } else {
            setErrorMsg('Une erreur c\'est produite lors de la suppression de la note');
            console.log(result);
        }
    }

    const headerTitle = title;
    return (
        <>
            <Popup ref={removeRef} title='Suppression de note' closeButton="Annuler" className="flex flex-col items-center">
                <span className="my-4">Etes vous sure vouloir supprimer cette note ?</span>
                <Button onPress={() => remove()}>Confirmer</Button>
            </Popup>
            <Header title={headerTitle}>
                <Button skin='back' className="mr-2" onPress={() => removeRef.current.toggle(true)}>Supprimer</Button>
                <Link to={location.pathname+"/edit"}>
                    <Button>Editer</Button>
                </Link>
            </Header>
            <Content>
                <LoadingArea isLoading={loading}>
                    {errorMsg ? <Message type="error">{errorMsg}</Message> : null}
                    <Box className='p-4' >
                        <div dangerouslySetInnerHTML={{ __html: content}}/>
                    </Box>
                </LoadingArea>
            </Content>
        </>
    );
}

export default NoteView;