import React, {useState, useEffect} from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Header, Content, Box, Row, Message, LoadingArea } from "../../common";
import { Button, FormFields, RightSidebar, Editor } from "../../components";
import { post, get } from "../../server/api";

function SheetCreate() {
    let location = useLocation();
    let history = useHistory();
    const url = location.pathname.replace('/edit', '');

    const [content, setContent] = useState('');
    const [ title, setTitle ] = useState({title: ''});
    const [ data, setData ] = useState({});
    const [loading, setLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");

    const headerTitle = "Edition de " + title.title;

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            
            const result = await get(url);
            if (result.success) {
                setContent(result.data.content);
                setTitle({title: result.data.title});

                let data = {
                    //...result.data.data, 
                    metabolicProblem: result.data.metabolic_problem,
                    morphotype: result.data.morphotype,
                    trend: result.data.trend,
                    subTrend: result.data.sub_trend,
                    height: result.data.height,
                };
                setData(data);
            } else {
                setErrorMsg('Une erreur c\'est produite lors du chargement de la fiche');
                console.log(result);
            }
            setLoading(false);
        };

        fetchData();
    }, [])


    const submit = async () => {
        let values = {
            ...title,
            //data,
            metabolic_problem: data['metabolicProblem'],
            morphotype: data['morphotype'],
            trend: data['trend'],
            sub_trend: data['subTrend'],
            height: data['height'],
            content: content
        }

        let result = await post(url, JSON.stringify(values), true);

        if(result.success){
            history.push(url);
        } else {
            console.log(result);
        }
    }

    const fields = {
        title: {
            label: "Titre de la fiche",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    return (
        <>
            <Header title={headerTitle}>
                <Button onPress={() => submit()}>Mettre à jour</Button>
            </Header>
            <Row padding={false}>
                <Content>
                    <LoadingArea isLoading={loading}>
                        {errorMsg ? <Message type="error">{errorMsg}</Message> : null}
                        <Box className={'p-4'}>
                            { !loading ? (
                                <FormFields 
                                    fields={fields}
                                    defaultValues={title}
                                    handleUpdate={(title) => setTitle(title)}
                                /> 
                            ) : null}
                            <Editor {...{content, setContent}} noteButton sheetButton/>
                        </Box>
                    </LoadingArea>
                </Content>
                { !loading ? (
                    <RightSidebar {...{setData, data}}/>
                ) : null}
            </Row>
        </>
    );
}

export default SheetCreate;