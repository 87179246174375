import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom'

import { FunctionalForm, Button } from "../components";
import { UserContext } from "../context/UserContext";
import { LayoutCenter, LayoutBetween, Content } from "../common";
import { get, post, getToken } from "../server/api";
import getRole from "../utilities/roles"
import { getCountries } from "../constants/countries";


function Register() {
    const [state, setState] = useContext(UserContext);
    const [gender, setGender] = useState('');

    const saveUrl = null;
    const redirectUrl = "/dashboard";
    let fields = {
        gender: {
            label: "Physionomie",
            type: "select",
            choices: [
                {label: "Femme", value: "femme"},
                {label: "Homme", value: "homme"},
                {label: "Fille (-18ans)", value: "fille"},
                {label: "Garçon (-18ans)", value: "garcon"},
            ],
            required: true,
            validations: [{ rule: "required" }]
        },
        firstname: {
            label: "Prénom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        lastname: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        birthdate: {
            label: "Date de naissance",
            type: "date",
            required: false,
            validations: [{ rule: "birthdate" }],
            placeholder: "01/12/1990"
        },
        email: {
            label: "Email",
            type: "email",
            required: true,
            validations: [{ rule: "required" }, { rule: "email" }]
        },
        phone: {
            label: "Téléphone",
            type: "text",
            required: false,
            validations: [{ rule: "phone" }]
        },
        address: {
            label: "Adresse",
            type: "textarea",
            required: true,
            validations: [{ rule: "required" }]
        },
        city: {
            label: "Ville",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        postcode: {
            label: "Code postal",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        country: {
            label: "Pays",
            type: "select",
            required: true,
            choices: getCountries()
        },
    }

    if(gender === 'fille' || gender === 'garcon'){
        fields = {
            ...fields,
            tutor_firstname:{
                label: "Prénom du tuteur légal (si mineur)",
                type: "text",
                required: true,
                validations: [{ rule: "mineur" }]
            },
            tutor_lastname:{
                label: "Nom du tuteur légal (si mineur)",
                type: "text",
                required: true,
                validations: [{ rule: "mineur" }]
            },
            certification: {
                label: "Responsabiltés (si mineur)",
                type: "multicheckbox",
                required: true,
                validations: [{ rule: "mineur_check" }],
                choices:[{
                    label: 'Je reconnais avoir plus de 18 ans et être le tuteur légal de la personne possédant ce compte. Une preuve de votre identité pourra vous être demandé lors de la commande d\'une fiche conseils',
                    value: 'certification'
                }],
            },
        }
    
    } else {
        fields = {
            ...fields,
            tutor_firstname:{
                label: "Prénom du tuteur légal (si mineur)",
                type: "text",
                required: false,
                validations: [{ rule: "mineur" }]
            },
            tutor_lastname:{
                label: "Nom du tuteur légal (si mineur)",
                type: "text",
                required: false,
                validations: [{ rule: "mineur" }]
            },
            certification: {
                label: "Responsabiltés (si mineur)",
                type: "multicheckbox",
                required: false,
                validations: [{ rule: "mineur_check" }],
                choices:[{
                    label: 'Je reconnais avoir plus de 18 ans et être le tuteur légal de la personne possédant ce compte. Une preuve de votre identité pourra vous être demandé lors de la commande d\'une fiche conseils',
                    value: 'certification'
                }],
            },
        }
    
    }

    fields = {
        ...fields,
        new_password: {
            label: "Mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "required" }, { rule: "password" }]
        },
        confirm_password: {
            label: "Confirmez le mot de passe",
            type: "password",
            required: true,
            validations: [{ rule: "required" }, { rule: "password" }, { rule: "confirm_password" }]
        }
    }

    
    const messages = {
        success: 'Inscription réussie, Bienvenue !',
        error: 'Erreur lors de la tentative d\'inscription',
        button: 'S\'inscrire'
    }   

    const submit = async (values) => {
        const registerResult = await post('/register', values);

        if(!registerResult.success){
            return { success: false, message: registerResult.hasOwnProperty('message') ? registerResult.message : messages.error };
        }

        
        const result = await getToken(values.email, values.new_password);

        if (result.hasOwnProperty("error")) {
            localStorage.clear();
            return { success: false };
        }

        localStorage.setItem("authToken", result.access_token);
        localStorage.setItem("refreshToken", result.refresh_token);
        localStorage.setItem("loggedIn", true);

        const profileResult = await get("/profile");

        if (profileResult.success) {
            let user = profileResult.data;
            localStorage.setItem("user", JSON.stringify(user));
            let role = getRole(user.roles);
            localStorage.setItem('role', role);
            setState((state) => ({
                ...state,
                loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
                user,
                role
            }));

            return { success: true };
        } else {
            localStorage.clear();
            setState((state) => ({
                ...state,
                loggedIn: false,
                user: false,
            }));

            return { success: false };
        }
    };

    return (
        <>
            <Content>
                <LayoutBetween>
                    <Link to="/login">
                        <Button width='auto' skin='back'>Retour</Button>
                    </Link>
                </LayoutBetween>
                <LayoutCenter>
                    <FunctionalForm 
                        width="full" 
                        maxWidth="md" 
                        saveUrl={saveUrl}
                        redirectUrl={redirectUrl}
                        fields={fields}
                        defaultValues={null}
                        messages={messages}
                        handleSubmit={submit}
                        getValues={(gender) => setGender(gender)}
                        cgu={(
                            <Link to="/cgu" target="_blank">
                                <Button skin="link" width="full" className={'self-center'}>CONDITIONS GÉNÉRALES</Button>
                            </Link> 
                        )}
                    /> 
                </LayoutCenter>
                
            </Content>
        </>
    );
}

export default Register;
