import React from 'react';

function Body({ children }) {
    return (
        <div className="w-full box-border p-4 overflow-x-auto">
            {children}
        </div>        
    )
}

export default Body;