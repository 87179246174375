import React from "react";
import { Link } from "react-router-dom";

import { Menu, MenuItem, MenuSeparator } from "../common";

function AdminNav() {

    return (
        <>
            <Menu>
                <MenuSeparator>Gestion des commandes</MenuSeparator>
                <Link to={"/order-history"}>
                    <MenuItem>Commandes</MenuItem>
                </Link>
            </Menu>
            <Menu>
                <MenuSeparator>Gestion des programmes</MenuSeparator>
                <Link to={"/monitoring-sheets"}>
                    <MenuItem>Demandes de programme</MenuItem>
                </Link>
                <Link to={"/sheets"}>
                    <MenuItem>Gestion des Morphotypes / Voir / Editer</MenuItem>
                </Link>
                <Link to={"/notes"}>
                    <MenuItem>Gestion des Notes</MenuItem>
                </Link>
                <Link to={"/metabolic-problems"}>
                    <MenuItem>Gestion des Problèmes métaboliques</MenuItem>
                </Link>
            </Menu>
            <Menu>
                <MenuSeparator>Gestion des utilisateurs et instituts</MenuSeparator>
                <Link to={"/instituts"}>
                    <MenuItem>Instituts</MenuItem>
                </Link>
                <Link to={"/nutritionists"}>
                    <MenuItem>Nutritionnistes</MenuItem>
                </Link>
                <Link to={"/coaches"}>
                    <MenuItem>Coachs</MenuItem>
                </Link>
                <Link to={"/patients"}>
                    <MenuItem>Patients</MenuItem>
                </Link>
            </Menu>
        </>
    );
}

export default AdminNav;
