import React from 'react';

function DefaultField({ children, name, type = "text", value, setValue, required, error, max, isInt, className }) {

    const setSecureValue = (newValue) => {
        if(isInt && value && newValue){
            newValue = Math.round(newValue);
            value = newValue;
        } 
        
        setValue(newValue)
    }

    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <input id={"select-" + name} name={name} type={type} max={max} min={0} className={ className ? className : "bg-gray-400 w-full py-2 px-4 rounded-lg"} defaultValue={value} value={value} onChange={(e) => setSecureValue(e.target.value)} step={!isInt ? "0.01" : "1"}/>  

            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default DefaultField;