import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { FormFields, Button } from "./";
import { Message, LoadingArea } from "../common";
import { post } from "../server/api";

function FunctionalForm({
    saveUrl,
    redirectUrl,
    fields,
    defaultValues,
    messages,
    width,
    maxWidth,
    handleSubmit,
    children,
    getValues,
    cgu
}) {
    const history = useHistory();

    const fieldsRef = useRef();

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(defaultValues ? defaultValues : {});

    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const handleUpdate = (values) => {
        setSuccessMsg("");
        setErrorMsg("");

        if(getValues){
            getValues(values.gender);
        }

        setValues(values);
    }

    const submit = async (e) => {
        e.preventDefault();

        if(fieldsRef.current.checkErrors()) {
            console.log('has Errors');
            return false;
        }

        setLoading(true);

        var result = null;
        if (typeof handleSubmit !== "undefined") {
            result = await handleSubmit(values);
        } else {
            result = await post(saveUrl, values);
        }

        setLoading(false);

        if (result.success) {
            setSuccessMsg(messages.success);

            if (redirectUrl) {
                setTimeout(function () {
                    history.push(redirectUrl);
                }, 800);

                return true;
            }
        } else {
            var message = messages.error;
            if (result.hasOwnProperty("message")) {
                message = result.message;
            }
            setErrorMsg(message);
        }
    };

    let styles = ["rounded-lg", "overflow-hidden"];
    if (maxWidth) {
        styles.push("max-w-" + maxWidth);
    }

    if (width) {
        styles.push("w-" + width);
    }

    return (
        <div className={styles.join(" ")}>
            <LoadingArea isLoading={loading}>
                {successMsg ? (
                    <Message type="success">{successMsg}</Message>
                ) : null}
                {errorMsg ? <Message type="error">{errorMsg}</Message> : null}
                <div className="box">
                    {children}
                    <form onSubmit={submit}> 
                        <FormFields ref={fieldsRef} {...{fields, defaultValues, handleUpdate}}/>
                        {cgu ? (
                           <div className="pt-8 flex justify-center">
                               {cgu}
                            </div> 
                        ):null}
                        <div className="pt-8 flex justify-center">
                            <Button isLoading={loading} type="submit" onPress={submit} width="64">
                                {messages.button}
                            </Button>
                        </div>
                    </form>
                </div>
            </LoadingArea>
        </div>
    );
}

export default FunctionalForm;
