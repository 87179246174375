import React from "react";
import { useParams } from 'react-router-dom';

import { MonitoringSheetForm} from '../../components';


function PatientMonitoringSheet() {
    const { id } = useParams();

    return (
        <MonitoringSheetForm
            loadUrl={"/monitoring-sheet/"+ id}
            saveUrl={"/monitoring-sheet/"+ id}
            submitUrl={"/monitoring-sheet/submit/"+ id}
            patientId={id}
        />
    );
}

export default PatientMonitoringSheet;