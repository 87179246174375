import React from "react";

const noop = () => {};

const FileInput = ({ value, onChange = noop, label, selectedLabel, ...rest }) => (
    <div>
        <label className="p-4 rounded border-dashed border-2 border-gray-400 w-full block cursor-pointer hover:border-gray-700">
            <i className="icon-upload"></i> {label}
            <input
                {...rest}
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {
                    onChange([...e.target.files]);
                }}
            />
        </label>
    </div>
);

export default FileInput;