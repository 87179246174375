import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { Header, Content, Box } from "../common";
import {
    Tab,
    DashboardActivities,
    PatientPrograms,
    Button,
    DashboardCredits,
} from "../components";

import formatDate from "../utilities/date";
import formatState from "../utilities/state-badge";
import formatStatus from "../utilities/status-badge";
import renderUser from "../utilities/render-user";

import { UserContext } from "../context/UserContext";
import { PatientContext } from "../context/PatientContext";

function Dashboard() {
    const [state] = useContext(UserContext);
    const [ patientState, setPatientState ] = useContext(PatientContext)
    const [ hasPrograms, setHasPrograms ] = useState()

    let output = [];

    let history = useHistory();

    if (["Administrateur"].includes(state.role)) {
        history.push("/monitoring-sheets");
    }

    const see = (action, element) => {
        setPatientState((state) => ({
            ...state,
            ...element.patient,
        }));
        history.push(action.replace(":id", element.patient.id));
    }

    const renderFirstName = (patient) => {
        return patient.firstname
    }

    const renderLastName = (patient) => {
        return patient.lastname
    }

    if (state.role === "Coach" || state.role === "Manager") {
        let config = {
            headers:[
                {
                    name: "patient",
                    label : "Prénom",
                    render: renderFirstName
                },
                {
                    name: "patient",
                    label : "Nom",
                    render: renderLastName
    
                },
                {
                    name: "gender",
                    label : "Physionomie",
    
                },
                {
                    name: "user",
                    label : "Nutritionniste",
                    render: renderUser,
    
                },
                {
                    name: "updated_at",
                    label : "Date de mise à jour",
                    render: formatDate,
                },
                {
                    name: "state",
                    label : "Statut du programme",
                    render: formatState,
    
                }
            ],
            actions: [
                {
                    label: "Voir",
                    name: "/patient/:id",
                    active: true
                },
            ],
            error: "Une erreur est survenue lors de la récupération de la liste des programmes.",
            no_results: "Auncun programme en attente", 
            redirect: "",
            redirectButton: "",
            fetchUrl: "/monitoring-sheets",
            handleAction: see,
        };
        
        return (
            <>
                <Header title="Tableau de bord">
                    <Button onPress={() => history.push("/profile")} width="md">
                        Modifier mes informations personnelles
                    </Button>
                </Header>
                <Content>
                    <div className="flex flex-row flex-wrap mb-6">
                        <div className="w-2/3 p-3">
                            <Box>
                                <div className="p-8">
                                    <h3 className="text-2xl">Mes clients</h3>
                                    <Tab {...{ config }} />
                                </div>
                            </Box>
                        </div>
                        <div className="w-1/3 p-3">
                            <DashboardActivities />
                            <div className="mt-4">
                                <DashboardCredits />
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }

    if (state.role === "Nutritionniste") {
        const goTo = (action, element) =>{ 
            setPatientState(element.patient);
            history.push('/nutritionist/monitoring-sheet/'+element.patient.id);
        }

        let config = {
            headers:[
                {
                    name: "patient",
                    label : "Prénom",
                    render: renderFirstName
                },
                {
                    name: "patient",
                    label : "Nom",
                    render: renderLastName
    
                },
                {
                    name: "coach",
                    label : "Coach",
                    render: renderUser,
    
                },
                {
                    name: "created_at",
                    label : "Date de la demande",
                    render: formatDate,
                },
                {
                    name: "updated_at",
                    label : "Date de mise à jour",
                    render: formatDate,
                },
                {
                    name: "status",
                    label : "Statut du programme",
                    render: formatStatus,
    
                }
            ],
            actions: [
                {
                    label: 'Traiter',
                    active: true
                }
            ],
            error: "Une erreur est survenue lors de la récupération de la liste des programmes.",
            no_results: "Auncune demande de programme en attente", 
            redirect: "",
            redirectButton: "",
            fetchUrl: '/monitoring-sheets',
            handleAction: goTo
        };
        
        return (
            <>
                <Header title="Tableau de bord">
                    <Button onPress={() => history.push("/profile")} width="md">
                        Modifier mes informations personnelles
                    </Button>
                </Header>
                <Content>
                    <div className="flex flex-row flex-wrap mb-6">
                        <div className="w-2/3 p-3">
                            <Box>
                                <div className="p-8">
                                    <h3 className="text-2xl">Patients</h3>
                                    <Tab {...{ config }} />
                                </div>
                            </Box>
                        </div>
                        <div className="w-1/3 p-3">
                            <DashboardActivities />
                        </div>
                    </div>
                </Content>
            </>
        );
    }

    return (
        <>
            <Header title="Mon espace">
                <Button onPress={() => history.push("/profile")} width="md">
                    Modifier mes informations personnelles
                </Button>
            </Header>
            <Content>
                <div className="mb-6 flex">
                    <div className="w-2/3 p-4">
                        <PatientPrograms id={state.user.id} hasPrograms={(flag) => setHasPrograms(flag)}/>
                        {hasPrograms ? null : (
                            <Box className="py-8 px-8 mt-4">
                                <p>
                                    Bonjour et bienvenu sur votre espace personnel. Vous venez de créer un compte qui vous permettra de commander votre fiche conseils personnalisée.
                                </p>
                                <p>
                                    Votre programme sera rédigé sur la base des informations que vous allez nous faire parvenir, alors soyezle plus précis possible.
                                </p>
                                <p>
                                    Pour commander une fiche conseils il vous suffit de cliquer sur "Commander une fiche conseils".
                                </p>
                                <p>
                                    Mais tout d’abord il vous faudra acheter des crédits qui vous permettrons de commander votre fiche.
                                </p>
                                <p>
                                    Vous pouvez les commander à l’unité ou en pack le prix étant fonction du nombre de fiche que vous achèterez.
                                </p>
                            </Box>
                        )}
                    </div>
                    <div className="w-1/3 p-4">
                        <DashboardActivities />
                        <div className="mt-4">
                            <DashboardCredits />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default Dashboard;
