import React, { useContext } from 'react';

import { FormFields, FormTitle} from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";
import { UserContext } from '../../context/UserContext';

function FormLifeOther({ children, data, setData }) {
    const [ state ] = useContext(UserContext);

    let fields = {
        familySituation: {
            label: getFieldLabel("familySituation"),
            type: "select",
            required: false,
            validations: [],
            choices:[{
                label: getFieldLabel("single"),
                value: 'single'
            },{
                label: getFieldLabel("relationship"),
                value: 'relationship'
            }],
        },
    }

    if(state.role !== 'Manager' && state.role !== 'Coach'){
        fields = {
            ...fields,
            observations: {
                label: getFieldLabel("observations"),
                type: "textarea",
                required: false,
                validations: []
            },
        }
    }

    return (
        <Box>
            <FormTitle title="Autres" icon="group-circle" color="black"/>
            <div className="px-8 pb-8 pt-t">
                <FormFields 
                    defaultValues={data} 
                    fields={fields}
                    handleUpdate={(data) => setData(data)}
                />
            </div>
        </Box>
    )
}

export default FormLifeOther;