import React from "react";
import { Link } from "react-router-dom";

import { Menu, MenuItem, MenuSeparator } from "../common";

function NutritionistNav({ user }) {

    return (
        <Menu>
            <MenuSeparator>Menu</MenuSeparator>
            <Link to={"/monitoring-sheets"}>
                <MenuItem>Demandes de programme / traiter</MenuItem>
            </Link>
            <Link to={"/sheets"}>
                <MenuItem>Gestion des Morphotypes / Voir / Editer</MenuItem>
            </Link>
            <Link to={"/notes"}>
                <MenuItem>Gestion des Notes</MenuItem>
            </Link>
            <Link to={"/patients"}>
                <MenuItem>Patients</MenuItem>
            </Link>
        </Menu>
    );
}

export default NutritionistNav;
