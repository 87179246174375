import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { FunctionalForm, Button, Popup } from "../../components";
import { LayoutCenter, Header, Content } from "../../common";
import { getCountries } from '../../constants/countries';
import formatDate from "../../utilities/date";

import { get, post } from "../../server/api";

function UserEdit({redirectUrl, headerTitle, messages, role}) {
    let { id } = useParams();
    let history = useHistory();
    const [ state ] = useContext(UserContext);

    const deleteRef = useRef();

    const saveUrl = '/user/' + id;

    const [initialLoading, setInitialLoading] = useState(true);
    const [instituts, setInstituts] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postcode: "",
        gender: "",
        birthdate: "",
        institut: null
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await get('/instituts', {all:true});
            if (result.success) {
                let institutChoices = [];
                result.items.forEach(institut => {
                    institutChoices.push({
                        value: institut.id, 
                        label: institut.name,
                    })
                });
                setInstituts(institutChoices);
            } else {
                console.log(result);
            }
        };

        if(state.role === 'Administrateur'){
            fetchData();
        }
    }, [saveUrl]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await get(saveUrl);

            if (result.success) {
                const {
                    lastname,
                    firstname,
                    email,
                    phone,
                    address,
                    city,
                    postcode,
                    country,
                    gender,
                    birthdate,
                    institut,
                } = result.data;

                let institutId = null;
                if(institut){
                    institutId = institut.id;
                }

                setDefaultValues({
                    lastname,
                    firstname,
                    email,
                    phone,
                    address,
                    city,
                    postcode,
                    country,
                    gender,
                    birthdate: formatDate(birthdate),
                    institut: institutId
                });

                setInitialLoading(false);
            }
        };

        if(state.role === 'Administrateur'){
            if(instituts.length){
                fetchData();
            }
        } else {
            fetchData();
        }
    }, [instituts]);

    let baseFields = {
        lastname: {
            label: "Nom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        firstname: {
            label: "Prénom",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
        email: {
            label: "Email",
            type: "email",
            required: true,
            validations: [{ rule: "required" }, { rule: "email" }]
        },
        phone: {
            label: "Téléphone",
            type: "text",
            required: false,
            validations: []
        },
    }

    let passwordFields = {
        new_password: {
            label: "Mot de passe",
            type: "password",
            required: false,
            validations: [{ rule: "password" }],
        },
        confirm_password: {
            label: "Confirmer le mot de passe",
            type: "password",
            required: false,
            validations: [
                { rule: "password" },
                { rule: "confirm_password" },
            ],
        },
    }

    switch (role) {
        case 'nutritionist':
            baseFields = { 
                ...baseFields, 
                //...passwordFields
            }
            break;
        
        case 'coach':
            baseFields = {
                ...baseFields,
                address: {
                    label: "Adresse",
                    type: "textarea",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                city: {
                    label: "Ville",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                postcode: {
                    label: "Code postal",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                country: {
                    label: "Pays",
                    type: "select",
                    required: true,
                    choices: getCountries()
                },
                //...passwordFields
            }

            if(state.role === 'Administrateur'){
                baseFields = {
                    institut: {
                        label: "Institut",
                        type: "select",
                        choices: instituts,
                        required: true,
                    },
                    ...baseFields,
                }
            }
            break;

        case 'patient':
            baseFields = {
                gender: {
                    label: "Physionomie",
                    type: "select",
                    choices: [
                        {label: "Femme", value: "femme"},
                        {label: "Homme", value: "homme"},
                        {label: "Fille (-18ans)", value: "fille"},
                        {label: "Garçon (-18ans)", value: "garcon"},
                    ],
                    required: true,
                    validations: [{ rule: "required" }]
                },
                birthdate: {
                    label: "Date de naissance",
                    type: "date",
                    required: false,
                    validations: [{ rule: "birthdate" }],
                    placeholder: "01/12/1990"
                },
                ...baseFields,
                address: {
                    label: "Adresse",
                    type: "textarea",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                city: {
                    label: "Ville",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                postcode: {
                    label: "Code postal",
                    type: "text",
                    required: true,
                    validations: [{ rule: "required" }]
                },
                country: {
                    label: "Pays",
                    type: "select",
                    required: true,
                    choices: getCountries()
                },
                //...passwordFields
            }
            break;
    
        default:
            baseFields = { 
                ...baseFields, 
                //...passwordFields
            }
            break;
    }

    const removeUser = async () => {
        const result = await get(saveUrl+'/delete');
        if(result.success){
            deleteRef.current.toggle(false)
            history.push('/monitoring-sheets');
        }
    }

    const handleSubmit = async (values) =>
    {
        if(defaultValues.institut){
            values = {
                ...values,
                institut: defaultValues.institut
            }
        }

        return await post(saveUrl, values);
    }

    return (
        <>
            <Popup ref={deleteRef} title="Suppression d'utilisateur" closeButton="Annuler" className="flex flex-col justify-center">
                <span>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</span>
                <Button onPress={() => removeUser()}>Confirmer</Button>
            </Popup>
            <Header title={headerTitle} >
                <Button skin='back' onPress={() => deleteRef.current.toggle(true)}>Supprimer</Button>
            </Header>
            <Content>
                <LayoutCenter>
                    {initialLoading ? (
                        <div>Chargement...</div>
                    ) : (
                        <FunctionalForm
                            width="full"
                            maxWidth="3xl"
                            saveUrl={saveUrl}
                            redirectUrl={redirectUrl}
                            fields={baseFields}
                            defaultValues={defaultValues}
                            messages={messages}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </LayoutCenter>
            </Content>
        </>
    );
}

export default UserEdit;
