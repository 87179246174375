import React from "react";
import { useLocation, Link } from "react-router-dom";

import { Tab, Button } from '../../components';
import { Header, Content} from "../../common";

import formatDate from "../../utilities/date";

function MetabolicProblemList() {
    const location = useLocation();
    let headerTitle = "Problèmes Métaboliques";

    const renderBadge = (value) => {
        let badges = value.split(',')
        let html = [];

        if(badges.length && badges[0]){
            badges.forEach(element => {
                html.push(
                    <span className="ml-2 py-1 px-2 rounded-full text-white text-xs font-bold bg-blue-400">{element}</span>
                )
            });
        } else {
            html.push(
                <span className="ml-2 py-1 px-2 rounded-full text-white text-xs font-bold bg-blue-400">Tous</span>
            )
        }
        
        return html;
    }

    let config = {
        headers:[
            {
                name: "name",
                label : "Problème métabolique",
            },
            {
                name: "alias",
                label : "Alias",
            },
            {
                name: "gender",
                label : "Affiché pour",
                render: renderBadge,
            },
            {
                name: "updated_at",
                label : "Date de mise à jour",
                render: formatDate,
            },
        ],
        actions: [
            {
                label: 'Editer',
                name: "/metabolic-problem/:id/edit",
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des problèmes métaboliques.",
        no_results: "Auncun problème métabolique", 
        redirect: "",
        redirectButton: "",
        fetchUrl: location.pathname,
    };

    return (
        <>
            <Header title={headerTitle}>
                <Link to="/metabolic-problem/create">
                    <Button>Ajouter un problème metabolique</Button>
                </Link>
            </Header>
            <Content>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default MetabolicProblemList;