import React from 'react';

import DefaultField from './DefaultField';

function NumberField(props) {
    return (
        <DefaultField type="number" { ... props} />
    )
}

export default NumberField;