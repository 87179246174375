import React from 'react';

function MenuItem({ onClick, children, icon }) {
    return (
        <li className="mb-1 py-1 px-4 text-white hover:text-gray-100 focus:text-gray-200 hover:bg-blue-600 cursor-pointer flex items-center">
            <i className={"icon-"+icon+" text-2xl text-white mr-4"}></i>{" "}
            {children}
        </li>        
    )
}

export default MenuItem;