import React, { useContext } from "react";

import { PatientContext } from "../../context/PatientContext";
import { UserContext } from "../../context/UserContext";
import { UserEdit } from "../../components";

function PatientEdit() {
    const [ patientState ] = useContext(PatientContext);
    const [ state ] = useContext(UserContext);
    const redirectUrl = "/patients";
    const civility = patientState.gender === 'femme' || patientState.gender === 'fille' ? 'Mme' : 'M';

    let headerTitle = "Edition d'un patient ( "+civility+" "+ patientState.firstname + " " + patientState.lastname + " )";

    let messages = {
        success: "Patient mis à jour !",
        error:
            "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Mettre à jour",
    };

    if(state.role === 'Coach' || state.role === 'Manager'){
        headerTitle = "Edition d'un client ( "+civility+" "+ patientState.firstname + " " + patientState.lastname + " )";

        messages = {
            ...messages,
            success: "Client mis à jour !",
        };
    }

    return (
        <UserEdit {...{redirectUrl, headerTitle, messages}} role="patient"/>
    );
}

export default PatientEdit;
