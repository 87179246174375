import React from 'react';

function Menu({ children }) {
    return (
        <ul className="pt-4 mb-4">
            {children}
        </ul>        
    )
}

export default Menu;