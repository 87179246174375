export default function getRole(roles){

    if(roles.some(element => element === 'ROLE_ADMIN')){
        return 'Administrateur';
    }
    
    if(roles.some(element => element === 'ROLE_SUPER_ADMIN')){
        return 'Administrateur';
    }

    if(roles.some(element => element === 'ROLE_INSTITUT_MANAGER')){
        return 'Manager';
    }

    if(roles.some(element => element === 'ROLE_NUTRITIONIST')){
        return 'Nutritionniste';
    }

    if(roles.some(element => element === 'ROLE_COACH')){
        return 'Coach';
    }
    
    if(roles.some(element => element === 'ROLE_ADMIN' || element === 'ROLE_SUPER_ADMIN')){
        return 'Administrateur';
    }

    return 'Patient';
}