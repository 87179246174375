import React, { useContext, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Tab, Popup, FormFields } from '../../components';
import { Header, Content, Box} from "../../common";
import { PatientContext } from '../../context/PatientContext';
import { UserContext } from '../../context/UserContext';
import { post } from '../../server/api';

import formatDate from "../../utilities/date";
import formatStatus from "../../utilities/status-badge";
import formatState from "../../utilities/state-badge";
import renderUser from "../../utilities/render-user";

function MonitoringSheetList() {
    const location = useLocation();
    const history = useHistory();
    let headerTitle = "Demandes de programme";

    const assignationRef = useRef();

    const [ patientState, setPatientState ] = useContext(PatientContext)
    const [ state ] = useContext(UserContext);
    const [ sort, setSort ] = useState(false);
    const [ search, setSearch ] = useState(false);

    const setFetchUrl = () => {
        let url = location.pathname;

        if(search.hasOwnProperty('search') && search['search']){
            url += '?';
            url += 'search='+search['search'];
        } 

        if(sort.hasOwnProperty('by') && sort['by']){
            if(search.hasOwnProperty('search') && search['search']){
                url += '&';
            } else {
                url += '?';
            }

            url += 'by='+sort['by'];

            if(sort.hasOwnProperty('order') && sort['order']){
                url += '&';
                url += 'order='+sort['order'];
            }
        }

        return url;
    }


    const renderFirstName = (patient) => {
        return patient.firstname
    }

    const renderLastName = (patient) => {
        return patient.lastname
    }

    const goTo = (action, element) =>{
        setPatientState(element.patient);
        if(element.hasOwnProperty('user') && element.user){
            history.push('/nutritionist/monitoring-sheet/'+element.patient.id);
        } else {
            assignationRef.current.toggle(true);
        }
    }

    const see = (action, element) => {
        setPatientState((state) => ({
            ...state,
            ...element.patient,
        }));
        history.push(action.replace(":id", element.patient.id));
    }

    const assign = async (action, element) =>{
        let params = {
            nutritionist: element.id,
        }

        const result = await post('/user/' + patientState.id + '/assign', params)
        
        if(result.success){
            assignationRef.current.toggle(false);
            history.push('/nutritionist/monitoring-sheet/'+ patientState.id);
        }
        
    }

    let config = {
        headers:[
            {
                name: "patient",
                label : "Prénom",
                render: renderFirstName
            },
            {
                name: "patient",
                label : "Nom",
                render: renderLastName

            },
            {
                name: "coach",
                label : "Coach",
                render: renderUser,

            },
            {
                name: "user",
                label : "Nutritionniste",
                render: renderUser,

            },
            {
                name: "submitted_at",
                label : "Date de la demande",
                render: formatDate,
                
            },
            {
                name: "updated_at",
                label : "Date de mise à jour",
                render: formatDate,
            },
            {
                name: "status",
                label : "Statut du programme",
                render: formatStatus,

            }
        ],
        actions: [
            {
                label: 'Traiter',
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des programmes.",
        no_results: "Auncune demande de programme en attente", 
        redirect: "",
        redirectButton: "",
        fetchUrl: setFetchUrl(),
        handleAction: goTo
    };

    if(state.role === 'Nutritionniste'){
        config = {
            headers:[
                {
                    name: "patient",
                    label : "Prénom",
                    render: renderFirstName
                },
                {
                    name: "patient",
                    label : "Nom",
                    render: renderLastName
    
                },
                {
                    name: "coach",
                    label : "Coach",
                    render: renderUser,
    
                },
                {
                    name: "submitted_at",
                    label : "Date de la demande",
                    render: formatDate,
                },
                {
                    name: "updated_at",
                    label : "Date de mise à jour",
                    render: formatDate,
                },
                {
                    name: "status",
                    label : "Statut du programme",
                    render: formatStatus,
    
                }
            ],
            actions: [
                {
                    label: 'Traiter',
                    active: true
                }
            ],
            error: "Une erreur est survenue lors de la récupération de la liste des programmes.",
            no_results: "Auncune demande de programme en attente", 
            redirect: "",
            redirectButton: "",
            fetchUrl: setFetchUrl(),
            handleAction: goTo
        };
    }

    if(state.role === 'Coach' || state.role === 'Manager'){
        headerTitle = "Programmes en attentes";

        config = {
            headers:[
                {
                    name: "patient",
                    label : "Prénom",
                    render: renderFirstName
                },
                {
                    name: "patient",
                    label : "Nom",
                    render: renderLastName
    
                },
                {
                    name: "user",
                    label : "Nutritionniste",
                    render: renderUser,
    
                },
                {
                    name: "updated_at",
                    label : "Date de mise à jour",
                    render: formatDate,
                },
                {
                    name: "state",
                    label : "Statut du programme",
                    render: formatState,
    
                }
            ],
            actions: [
                {
                    label: "Voir",
                    name: "/patient/:id",
                    active: true
                },
            ],
            error: "Une erreur est survenue lors de la récupération de la liste des programmes.",
            no_results: "Auncun programme en attente", 
            redirect: "",
            redirectButton: "",
            fetchUrl: location.pathname,
            handleAction: see,
        };
    }

    const configNutritionist = {
        headers:[
            {
                name: "firstname",
                label : "Prénom"
            },
            {
                name: "lastname",
                label : "Nom"
            },
            {
                name: "email",
                label : "Email"
            },
            {
                name: "phone",
                label : "Tél"
            },
        ],
        actions: [
            {
                label: "Assigner",
                active: true
            }            
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des nutritionnistes.",
        no_results: "Aucun nutritionniste trouvé", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/users?role=ROLE_NUTRITIONIST',
        handleAction: assign
    }

    let patient = {
        firstname: false,
        lastname: false
    }

    if(patientState){
        patient = patientState
    }

    const civility = patient.gender === 'femme' || patient.gender === 'fille' ? 'Mme' : 'M';

    const fields = {
        by: {
            label: "Trier par",
            type: "select",
            required: false,
            validations: [],
            choices: [
                {label:"Date de la demande" , value: "submitted_at"},
                {label:"Date de mise à jour" , value: "updated_at"}
            ],
        },
        order: {
            label: "Du",
            type: "select",
            required: false,
            validations: [],
            choices: [
                {label:"Plus récent au plus ancien" , value: "DESC"},
                {label:"Plus ancien au plus récent" , value: "ASC"}
            ],
        },
    }

    const searchField = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <>
            <Popup ref={assignationRef} title={"Assigner un nutritionniste à "+civility+" "+ patient.firstname  + " " + patient.lastname } closeButton="Annuler">
                <Tab config={configNutritionist}/>
            </Popup>
            <Header title={headerTitle} />
            <Content>
                {state.role === 'Nutritionniste' || state.role === 'Administrateur' ? (
                    <>
                        <Box className="p-4 mb-2">
                            <FormFields 
                                fields={searchField}
                                handleUpdate={(data) => setSearch(data)}
                            />
                        </Box>
                        <Box className="p-4 mb-2 flex items-center">
                            <FormFields 
                                fields={fields}
                                handleUpdate={(data) => setSort(data)}
                            />
                        </Box>
                    </>
                ) : null}
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default MonitoringSheetList;