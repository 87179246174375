import React from 'react';

function LoadingArea({ isLoading, children }) {

    let styles = ["loading-area relative overflow-auto"];

    if(isLoading) {
        styles.push("active");
    }

    return (
        <>
        <div className={styles.join(' ')}>
            <div className="progress-loader">
                <span className="text-white text-right">Chargement...</span></div>
            {children}
        </div>    
        </>
    )
}

export default LoadingArea;