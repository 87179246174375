import React from 'react';

function Row({ children, padding = true }) {
    let className = "w-full flex flex-row";

    if(padding){
        className +=" pt-4";
    }

    return (
        <div className={className}>
            {children}
        </div>        
    )
}

export default Row;