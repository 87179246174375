import React, {useState} from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Header, Content, Box, Row } from "../../common";
import { Button, FormFields, RightSidebar, Editor } from "../../components";
import { post } from "../../server/api";

function SheetCreate() {
    let location = useLocation();
    let history = useHistory();

    const headerTitle = "Créer une nouvelle fiche";

    const [content, setContent] = useState('')
    const [ title, setTitle ] = useState('');
    const [ data, setData ] = useState({});

    const submit = async () => {
        let values = {
            ...title,
            //data,
            metabolic_problem: data['metabolicProblem'],
            morphotype: data['morphotype'],
            trend: data['trend'],
            sub_trend: data['subTrend'],
            height: data['height'],
            content: content
        }

        let result = await post(location.pathname, JSON.stringify(values), true);

        if(result.success){
            history.push('/sheets');
        } else {
            console.log(result);
        }
    }

    const fields = {
        title: {
            label: "Titre de la fiche",
            type: "text",
            required: true,
            validations: [{ rule: "required" }]
        },
    }

    return (
        <>
            <Header title={headerTitle}>
                <Button onPress={() => submit()}>Sauvegarder</Button>
            </Header>
            <Row padding={false}>
                <Content>
                    <Box className={'p-4'}>
                        <FormFields 
                            fields={fields}
                            handleUpdate={(title) => setTitle(title)}
                        /> 
                        <Editor {...{content, setContent}} noteButton sheetButton/>
                    </Box>
                </Content>
                <RightSidebar {...{setData}}/>
            </Row>
        </>
    );
}

export default SheetCreate;