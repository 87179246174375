import React from 'react';

import { FormFields, FormTitle } from '../';
import { Box } from '../../common';
import getFieldLabel from "../../utilities/field-label";

function FormActivities({ children, data, setData }) {
    const fields = {
        job: {
            label: getFieldLabel("job"),
            type: "text",
            required: false,
            validations: []
        },
        physicalActivity: {
            label: getFieldLabel("physicalActivity"),
            type: "text",
            required: false,
            validations: []
        },
    }

    return (
        <Box>
            <FormTitle title="Activités" icon="running" color="black"/>
            <div className="px-8 pb-8 pt-2">
                <FormFields 
                    fields={fields}
                    defaultValues={data}
                    handleUpdate={(data) => setData(data)}
                /> 
            </div>
        </Box>
    )
}

export default FormActivities;