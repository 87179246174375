import React from "react";
import { Link } from "react-router-dom";

import { Tab, Button } from '../../components';
import { Header, Content} from "../../common";

function NutritionistList() {
    const headerTitle = 'Nutritionnistes';

    const renderPhone = (phone) => {
        let name = 'Aucun';

        if(phone !== 'null'){
            name = phone;
        }

        return name;
    }

    const config = {
        headers:[
            {
                name: "firstname",
                label : "Prénom"
            },
            {
                name: "lastname",
                label : "Nom"
            },
            {
                name: "email",
                label : "Email"
            },
            {
                name: "phone",
                label : "Tél",
                render: renderPhone
            },
        ],
        actions: [
            {
                label: "Editer",
                name: "/nutritionist/:id/edit",
                active: true
            }            
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des nutritionnistes.",
        no_results: "Aucun nutritionniste trouvé", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/users?role=ROLE_NUTRITIONIST'
    }

    return (
        <>
            <Header title={headerTitle}>
                <Link to="/nutritionist/create">
                    <Button>Ajouter un nutritionniste</Button>
                </Link>
            </Header>
            <Content>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default NutritionistList;