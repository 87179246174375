import React from 'react';
import { useHistory } from 'react-router-dom';

function Select({ children, name, choices, required, error, value, handleAction, setValue }) {
    let history = useHistory();

    function handleChange(eValue) {
        if (typeof handleAction !== "undefined") {
            handleAction(eValue);
            history.push(eValue);
            return true;
        }

        setValue(eValue);
    }

    return (
        <div className="mb-4 mr-2">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <select defaultValue={value} id={"select-" + name} name={name} className="bg-gray-400 w-full py-2 px-4 rounded-lg" onChange={event => handleChange(event.target.value)}>
                { !value ? (<option key={'default'} value="">Sélection</option> ):null}
                {choices && choices.length ? choices.map((option, index) => <option key={index} value={option.value}>{option.label}</option>) : null}
            </select>
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default Select;