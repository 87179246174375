import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import { Screen } from '../common';
import { UserContext } from '../context/UserContext';
import { PatientProvider } from "../context/PatientContext";

import {
    Login,
    Register,
    NotFound,
    Dashboard,
    Profile,
    Password,
    Orders,
    ProgramList,
    MonitoringSheet,
    Checkout,
    PatientList,
    PatientView,
    PatientAdminLogin,
    PatientCreate,
    PatientEdit,
    PatientMonitoringSheet,
    PatientProgram,
    CoachList,
    CoachView,
    CoachCreate,
    CoachEdit,
    InstitutList,
    InstitutCreate,
    InstitutEdit,
    PasswordReset,
    PasswordForgot,
    SheetCreate,
    SheetEdit,
    SheetList,
    SheetView,
    NoteCreate,
    NoteList,
    NoteView, 
    NoteEdit,
    MonitoringSheetList,
    MonitoringSheetForm,
    NutritionistCreate,
    NutritionistEdit,
    NutritionistList,
    PrescriptionEdit,
    MetabolicProblemCreate,
    MetabolicProblemEdit,
    MetabolicProblemList,
    AccessDocument,
    Cgu
} from "../screens";

function Router({ children }) {
    const [state] = useContext(UserContext);

    return (
        <BrowserRouter>
            <div className="w-full h-screen flex">
                { children }
                <PatientProvider>
                    <Screen>
                        <Switch>
                            <Route path="/login" component={Login} exact />
                            <Route path="/login/:redirectPage" component={Login} exact />
                            <Route path="/login/:redirectPage/:param" component={Login} exact />
                            <Route path="/register" component={Register} exact />
                            <Route path="/password/forgot" component={PasswordForgot} exact />
                            <Route path="/password/reset/:token" component={PasswordReset} exact />
                            <Route path="/cgu" component={Cgu} exact />
                            {!state.loggedIn && <Redirect to="/login" />}
                            <Route path="/dashboard" component={Dashboard} exact />
                            <Route path="/checkout" component={Checkout} exact />
                            <Route path="/profile" component={Profile} exact />
                            <Route path="/profile" component={Profile} exact />
                            <Route path="/password" component={Password} exact />
                            <Route path="/order-history" component={Orders} exact />
                            <Route path="/order-history/:factureId" component={Orders} exact />
                            <Route path="/monitoring-sheet" component={MonitoringSheet} exact />

                            <Route path="/programs" component={ProgramList} exact />
                            
                            <Route path="/patients" component={PatientList} exact />
                            <Route path="/patient/create" component={PatientCreate} exact />
                            <Route path="/patient/:id" component={PatientView} exact />
                            <Route path="/patient/:id/edit" component={PatientEdit} exact />
                            <Route path="/patient/:id/login" component={PatientAdminLogin} exact />
                            <Route path="/patient/:id/monitoring-sheet" component={PatientMonitoringSheet} exact />
                            <Route path="/patient/:id/program" component={PatientProgram} exact />

                            <Route path="/coaches" component={CoachList} exact />
                            <Route path="/coach/create" component={CoachCreate} exact />
                            <Route path="/coach/:id" component={CoachView} exact />
                            <Route path="/coach/:id/edit" component={CoachEdit} exact />
                            
                            <Route path="/instituts" component={InstitutList} exact />
                            <Route path="/institut/create" component={InstitutCreate} exact />
                            <Route path="/institut/:id/edit" component={InstitutEdit} exact />
                            <Route path="/institut/:id/order-history" component={Orders} exact />
                            <Route path="/institut/:id/patients" component={PatientList} exact />
                            <Route path="/sheets" component={SheetList} exact />
                            <Route path="/sheets/:page" component={SheetList} exact />
                            <Route path="/sheet/create" component={SheetCreate} exact />
                            <Route path="/sheet/:id" component={SheetView} exact />
                            <Route path="/sheet/:id/edit" component={SheetEdit} exact />

                            <Route path="/notes" component={NoteList} exact />
                            <Route path="/note/create" component={NoteCreate} exact />
                            <Route path="/note/:id" component={NoteView} exact />
                            <Route path="/note/:id/edit" component={NoteEdit} exact />

                            <Route path="/nutritionist/monitoring-sheet/:patientId" component={MonitoringSheetForm} exact />
                            <Route path="/monitoring-sheets" component={MonitoringSheetList} exact />
                           
                            <Route path="/nutritionists" component={NutritionistList} exact />
                            <Route path="/nutritionist/create" component={NutritionistCreate} exact />
                            <Route path="/nutritionist/:id/edit" component={NutritionistEdit} exact />

                            <Route path="/prescription/:id" component={PrescriptionEdit} exact />

                            <Route path="/metabolic-problems" component={MetabolicProblemList} exact />
                            <Route path="/metabolic-problem/create" component={MetabolicProblemCreate} exact />
                            <Route path="/metabolic-problem/:id/edit" component={MetabolicProblemEdit} exact />

                            <Route path="/access-document/:id" component={AccessDocument} exact />

                            <Route component={NotFound} />
                        </Switch>
                    </Screen>
                </PatientProvider>
            </div>  
        </BrowserRouter>        
    )
}

export default Router;