import React from 'react';

function HeaderMenu({ children }) {
    return (
        <ul className="ml-10 flex">
            {children}
        </ul>        
    )
}

export default HeaderMenu;