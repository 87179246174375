import React from "react";

import { UserEdit } from "../../components";

function NutritionistEdit() {

    const redirectUrl = "/nutritionists";

    const headerTitle = "Edition d'un nutritionniste";

    const messages = {
        success: "Nutritionniste mis à jour !",
        error: "Une erreur est survenue lors de la sauvegarde des informations.",
        button: "Mettre à jour",
    };

    return (
        <UserEdit {...{redirectUrl, headerTitle, messages}} role="nutritionist"/>
    );
}

export default NutritionistEdit;
