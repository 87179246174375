import React, {useState} from 'react';

function ImageUpload({ children, name, type = 'file', value, setValue, required, error, placeholder }) {

    if(!value){
        value = {url: ''}
    }

    let [ file, setFile ] = useState();
    let [ url, setUrl ] = useState(value.url);

    const onChangeHandler = (e) => {
        e.preventDefault()

        let reader = new FileReader();
        let newFile = e.target.files[0];

        reader.onloadend = () => {
            setFile(newFile);
            setUrl(reader.result);
            setValue({file: newFile, url: reader.result});
        }

        reader.readAsDataURL(newFile)
    }

    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <div className="w-64 h-67 border-gray-900">
                { url ? (
                    <img src={url} alt='preview'/>
                ) : null}
            </div>
            <input id={"select-" + name} name={name} type={type} placeholder={placeholder} className="bg-gray-400 w-full py-2 px-4 rounded-lg" onChange={(e) => onChangeHandler(e)}/>  
            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    )
}

export default ImageUpload;