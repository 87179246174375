import React, { useState } from 'react';


function DateField({ children, name, type = 'text', value, setValue, required, error, placeholder }) {

    const [v, updateValue] = useState(value);


    function onValueChange(e) {
        var newValue = e.target.value;

        if(!newValue) {
            return false;
        }

        if(e.keyCode !== 111 && e.keyCode !== 8) {
            if (newValue.match(/^\d{2}$/) !== null) {
                newValue = newValue + '/';
            } 
            else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                newValue = newValue + '/';
            }         

            normalChange(newValue);
        } else {
            if(e.keyCode === 111){
                newValue = newValue.substring(0, newValue.length - 1);
            }            

            normalChange(newValue);
        }
    }

    const normalChange = (value) => {
        setValue(value);
        updateValue(value);
    }

    return (
        <div className="mb-4">
            <label htmlFor={"select-" + name} className="mb-1 block font-medium">{children} {required ? <sup className="text-red-600">*</sup> : null}</label>
            <input 
                pattern="^([0][1-9]|[1-2][0-9]|30|31)\/([0][1-9]|10|11|12)\/(19[0-9][0-9]|20[0-1][0-9]|2020)"
                placeholder="01/12/1970"
                maxLength="10"
                id={"select-" + name} name={name} type={type} 
                className="bg-gray-400 w-full py-2 px-4 rounded-lg" 
                value={v}
                onChange={(e) => normalChange(e.target.value, e)}
                onKeyUp={onValueChange}
            />  

            <p className={error ? "mt-1 text-xs text-red-600" : "invisible"}>{error}</p>
        </div>
    );
}

export default DateField;