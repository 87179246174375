import React from "react";
import { Link } from "react-router-dom";

import { Menu, MenuItem, MenuSeparator, MenuItemIndicator } from "../common";

import getStateColor from '../utilities/state-color';

function PatientNav({ user }) {    
    const renderProgramsIndicator = (state) => {
        let color = getStateColor(state);

        return color ? <MenuItemIndicator color={color} /> : null;
    }

    return (
        <Menu>
            <MenuSeparator>Menu</MenuSeparator>
            <Link to={"/programs"}>
                <MenuItem>Mes programmes {renderProgramsIndicator(user.lastProgramState)}</MenuItem>
            </Link>
            <Link to={"/monitoring-sheet"}>
                <MenuItem>Commander une fiche conseils</MenuItem>
            </Link>
            <Link to={"/order-history"}>
                <MenuItem>Historique de mes commandes</MenuItem>
            </Link>
        </Menu>
    );

}

export default PatientNav;
