import React, {useState} from "react";
import { Link } from "react-router-dom";

import { Tab, Button, FormFields, FormatContent } from '../../components';
import { Header, Content, Box} from "../../common";
import formatDate from "../../utilities/date";

function NoteList() {
    const [search, setSearch] = useState('');

    const headerTitle = "Notes";

    const renderContent = (content) => {
        return <FormatContent {...{content}} />
    }

    const config = {
        headers:[
            {
                name: "title",
                label : "Titre"
            },
            {
                name: "short_content",
                label : "Contenu",
                render: renderContent
            },
            {
                name: "updated_at",
                label : "Date de mise à jour",
                render: formatDate
            },
        ],
        actions:[
            {
                label: "Voir",
                name: "/note/:id",
                active: true
            }
        ],
        error: "Une erreur est survenue lors de la récupération de la liste des notes.",
        no_results: "Vous n'avez pas encore de notes", 
        redirect: "",
        redirectButton: "",
        fetchUrl: '/notes?search=' + search
    };

    const fields = {
        search: {
            label: "Rechercher",
            type: "text",
            required: false,
            validations: [],
        },
    }

    return (
        <>
            <Header title={headerTitle}>
                <Link to="/note/create">
                    <Button>Créer une nouvelle note</Button>
                </Link>
            </Header>
            <Content>
                <Box className="p-4 mb-2">
                    <FormFields 
                        fields={fields}
                        handleUpdate={(data) => setSearch(data['search'])}
                    />
                </Box>
                <Tab {...{config}}/>
            </Content>
        </>
    );
}

export default NoteList;