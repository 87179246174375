import React, { useContext, useState } from "react";
import { LayoutCenter, Content, Box, Header } from "../common";
import { Button, NumberField, PaypalButton } from "../components";
import { useHistory, Link } from "react-router-dom";

import formatPrice from "../utilities/price";
import getUnitPrice from "../utilities/product-price";

import { UserContext } from "../context/UserContext";

const UNIT_NAME = "Crédit Chronosanté";

function Checkout() {
    const [unitQty, setUnitQty] = useState(1);
    const [paypalVisible, setPaypalVisible] = useState(false);
    const [cart, setCart] = useState(null);
    const history = useHistory();

    const [state] = useContext(UserContext);
    const { user } = state;

    const unitPrice = getUnitPrice(user);

    const buyUnit = () => {
        const reference_id = user.id + "-" + unitQty + "-" + Date.now();
        setCart({
            qty: unitQty,
            unitPrice: unitPrice,
            totalPrice: unitQty * unitPrice,
            name: UNIT_NAME,
            reference: "credit_unit",
            reference_id: reference_id,
            creditTotal: unitQty,
        });
        setPaypalVisible(true);
    };

    return (
        <>
            <Header title="Unités">
                <Button
                    onPress={() => history.push("/order-history")}
                    skin="primary-outline"
                >
                    Historique de mes commandes
                </Button>
            </Header>

            <Content>
                <LayoutCenter>
                    {paypalVisible ? (
                        <PaypalButton cart={cart} />
                    ) : (
                        <>
                            <Box className="w-64 m-4">
                                <div className="py-8 px-8 flex flex-col h-full">
                                    <div className="mb-auto">
                                        <strong className="text-2xl text-center">
                                            A l'unité
                                        </strong>

                                        <p className="mb-4">
                                            {formatPrice(unitPrice)}/crédit
                                        </p>
                                        <NumberField
                                            value={unitQty}
                                            setValue={setUnitQty}
                                            isInt={true}
                                        >
                                            Quantité :
                                        </NumberField>
                                    </div>
                                    <div className="mt-auto">
                                        <p className="mb-4 text-center ">
                                            Total :{" "}
                                            {formatPrice(unitQty * unitPrice)}
                                        </p>

                                        <Button onPress={buyUnit}>
                                            Commander {unitQty} crédit(s)
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </>
                    )}
                </LayoutCenter>
            </Content>
        </>
    );
}

export default Checkout;
